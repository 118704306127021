import React, { useState, useRef } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ZoomControl,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import arrow from "../../images/business/arrow.svg";
import direction from "../../images/business/direction.svg";
import back from "../../images/business/nextblue.svg";
// import back from "../../images/next-left.png";

//  Animated panning
function SetViewOnClick({ animateRef }) {
  const map = useMapEvent("click", (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    });
  });

  return null;
}

function GetIcon(_iconSize) {
  return L.icon({
    iconUrl: require("../../images/pin location.png"),
    iconSize: [_iconSize],
  });
}

const BizMapModalMobile = (props) => {
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  //   for map
  const animateRef = useRef(true);
  const [show, setShow] = useState(false);
  const [markCick, setmarkCick] = useState(false);
  const [popData, setPopData] = useState({});

  // for image map the make modal open
  const mapRef = useRef(null);
  const map = mapRef.current;

  const mapRef2 = useRef(null);
  const map2 = mapRef2.current;

  if (map != null) {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }

  if (map2 != null) {
    setTimeout(function () {
      map2.invalidateSize();
    }, 10);
  }

  function openMaps(latitude, longitude) {
    // console.log("11");
    // console.log(latitude);
    // console.log(longitude);

    // If not iOS, assume it's Android
    var url = "geo:" + latitude + "," + longitude;

    // console.log("55");

    window.open(url);
  }
  return (
    <>
      {props.Add_map ? (
        <a
          className="business-information-text"
          onClick={() => {
            if (props.lat !== 0 && props.lng !== 0) {
              toggleShow();
            }
          }}
        >
          {props.Address}
          <img className="business-information-img" src={arrow} alt="" />
        </a>
      ) : (
        <div
          onClick={() => {
            if (props.lat !== 0 && props.lng !== 0) {
              toggleShow();
            }
          }}
        >
          <MapContainer
            ref={mapRef}
            className="ifram-bus"
            center={
              props.lat &&
              props.lat !== 0 &&
              props.lat != "" &&
              props.lng &&
              props.lng !== 0 &&
              props.lng != ""
                ? [props.lat, props.lng]
                : [49.01095, -96.615234]
            }
            zoom={16}
            scrollWheelZoom={true}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              eventHandlers={{
                click: () => {
                  // console.log("marker clicked");
                  // console.log(props.lat);
                },
              }}
              position={
                props.lat &&
                props.lat !== 0 &&
                props.lat != "" &&
                props.lng &&
                props.lng !== 0 &&
                props.lng != ""
                  ? [props.lat, props.lng]
                  : [49.01095, -96.615234]
              }
              icon={GetIcon(37)}
            ></Marker>
          </MapContainer>
        </div>
      )}
      <MDBModal
        show={basicModal}
        setShow={setBasicModal}
        tabIndex="-1"
        dir="rtl"
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader className="biz-map-modal-header">
              <span></span>
              <span>{props.Title}</span>
              <MDBBtn
                className="btn-close mx-0 biz-map-modal-btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
              {/* {/* <img
                onClick={toggleShow}
                className="biz-map-modal-btn-close mx-0"
                data={back}
                alt=""
              /> */}
              {/* <object 
                onClick={toggleShow}
                className="biz-map-modal-btn-close mx-0"
                data={back}
                alt=""
                type="image/svg+xml"
              ></object> */}
            </MDBModalHeader>
            <MDBModalBody className="p-0">
              <MDBBtn
                className="biz-map-modal-direction"
                onClick={() => {
                  openMaps(props.lat, props.lng);
                }}
              >
                <object
                  className="biz-map-modal-btn-dir"
                  data={direction}
                  alt=""
                  type="image/svg+xml"
                ></object>
                Direction
              </MDBBtn>
              {/* <MDBBtn className="biz-map-modal-direction">
                {" "}
                <a
                  href={`https://waze.com/ul?ll=${props.lat},${props.lng}&navigate=yes`}
                  target="_blank"
                >
                  Open in Waze
                </a>{" "}
              </MDBBtn> */}

              {/* map */}
              <MapContainer
                ref={mapRef2}
                center={
                  props.lat &&
                  props.lat !== 0 &&
                  props.lat != "" &&
                  props.lng &&
                  props.lng !== 0 &&
                  props.lng != ""
                    ? [props.lat, props.lng]
                    : [49.01095, -96.615234]
                }
                zoom={16}
                scrollWheelZoom={true}
                zoomControl={false}
                className="biz-map-modal-leaflet"
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  eventHandlers={{
                    click: () => {
                      // console.log("marker clicked");
                      //   setShow(true);
                      //   setmarkCick(true);
                      //   setPopData(item);
                    },
                  }}
                  position={
                    props.lat &&
                    props.lat !== 0 &&
                    props.lat != "" &&
                    props.lng &&
                    props.lng !== 0 &&
                    props.lng != ""
                      ? [props.lat, props.lng]
                      : [49.01095, -96.615234]
                  }
                  icon={GetIcon(37)}
                ></Marker>
                {/* Zome Control */}
                <div className="d-none">
                  <ZoomControl position="bottomright" />
                </div>
                {/* Animated panning */}
                <SetViewOnClick animateRef={animateRef} />
              </MapContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default BizMapModalMobile;
