import React, { useCallback, useMemo, useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ZoomControl,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import MapPopupCard from "../map-popup-card/MapPopupCard";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import "./biz-map-modal.css";
// import DraggableMap from "../draggable-map/DraggableMap";
// import Testmap from "./Testmap";
import arrow from "../../images/business/arrow.svg";

//  Animated panning
function SetViewOnClick({ animateRef }) {
  const map = useMapEvent("click", (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    });
  });

  return null;
}

function GetIcon(_iconSize) {
  return L.icon({
    iconUrl: require("../../images/pin location.png"),
    iconSize: [_iconSize],
  });
}

const BizMapModal = (props) => {
  const [locationData, setLocationData] = useState({
    Lat: "",
    Lng: "",
  });

  const [saveAdd, setSaveAdd] = useState(false);

  const [isSave, setIsSave] = useState(false);
  const saveHandler = () => {
    toggleShow();
  };

  const toggleShow = () => {
    setCentredModal(!centredModal);
    // props.history.push({
    //   pathname: "/",
    // });
  };
  const [centredModal, setCentredModal] = useState(false);

  //   for map
  const animateRef = useRef(true);
  const [show, setShow] = useState(false);
  const [markCick, setmarkCick] = useState(false);
  const [popData, setPopData] = useState({});

  // for image map the make modal open
  const mapRef = useRef(null);
  const map = mapRef.current;

  const mapRef2 = useRef(null);
  const map2 = mapRef2.current;

  if (map != null) {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }

  if (map2 != null) {
    setTimeout(function () {
      map2.invalidateSize();
    }, 10);
  }

  return (
    <>
      {props.Add_map ? (
        <a
          className="business-information-text"
          onClick={() => {
            if (props.lat !== 0 && props.lng !== 0) {
              toggleShow();
            }
          }}
        >
          {props.Address}
          <img className="business-information-img" src={arrow} alt="" />
        </a>
      ) : (
        <div
          onClick={() => {
            if (props.lat !== 0 && props.lng !== 0) {
              toggleShow();
            }
          }}
        >
          <MapContainer
            ref={mapRef}
            className="ifram-bus"
            center={
              props.lat &&
              props.lat !== 0 &&
              props.lat != "" &&
              props.lng &&
              props.lng !== 0 &&
              props.lng != ""
                ? [props.lat, props.lng]
                : [49.01095, -96.615234]
            }
            zoom={16}
            scrollWheelZoom={true}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              eventHandlers={{
                click: () => {
                  // console.log("marker clicked");
                  // console.log(props.lat);
                },
              }}
              position={
                props.lat &&
                props.lat !== 0 &&
                props.lat != "" &&
                props.lng &&
                props.lng !== 0 &&
                props.lng != ""
                  ? [props.lat, props.lng]
                  : [49.01095, -96.615234]
              }
              icon={GetIcon(37)}
            ></Marker>
          </MapContainer>
        </div>
      )}

      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog
          centered
          className="justify-content-center biz-map-modal-dialog"
        >
          <MDBModalContent className=" biz-map-modal-div">
            <div className="reletive_profile">
              <MDBBtn
                className="btn-close biz-map-modal-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </div>
            {/* map */}
            <MapContainer
              ref={mapRef2}
              center={
                props.lat &&
                props.lat !== 0 &&
                props.lat != "" &&
                props.lng &&
                props.lng !== 0 &&
                props.lng != ""
                  ? [props.lat, props.lng]
                  : [49.01095, -96.615234]
              }
              zoom={16}
              scrollWheelZoom={true}
              zoomControl={false}
              className="biz-map-modal-leaflet"
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                eventHandlers={{
                  click: () => {
                    // console.log("marker clicked");
                    // console.log(props.lat);
                    //   setShow(true);
                    //   setmarkCick(true);
                    //   setPopData(item);
                  },
                }}
                position={
                  props.lat &&
                  props.lat !== 0 &&
                  props.lat != "" &&
                  props.lng &&
                  props.lng !== 0 &&
                  props.lng != ""
                    ? [props.lat, props.lng]
                    : [49.01095, -96.615234]
                }
                icon={GetIcon(37)}
              ></Marker>
              <div className="d-none">
                <ZoomControl position="bottomright" />
              </div>
              <SetViewOnClick animateRef={animateRef} />
            </MapContainer>

            {/* <Testmap /> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default BizMapModal;

// import React from "react";
// import L from "leaflet";
// import {
//   MapContainer,
//   TileLayer,
//   useMap,
//   Marker,
//   Popup,
//   ZoomControl,
//   useMapEvent,
// } from "react-leaflet";
// import "./biz-map-modal.css";

// function GetIcon(_iconSize) {
//   return L.icon({
//     iconUrl: require("../../images/pin location.png"),
//     iconSize: [_iconSize],
//   });
// }

// function BizMapModal() {
//   const position = [51.505, -0.09];

//   return (
//     <>
//       awcfacas
//       <MapContainer
//         center={position}
//         zoom={13}
//         scrollWheelZoom={true}
//         zoomControl={false}
//         className="map-hh"
//       >
//         <TileLayer
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         />
//         <Marker position={position}>
//           <Popup>
//             A pretty CSS3 popup. <br /> Easily customizable.
//           </Popup>
//         </Marker>

//         <Marker
//           eventHandlers={{
//             click: () => {
//               console.log("marker clicked");
//               //   setShow(true);
//               //   setmarkCick(true);
//               //   setPopData(item);
//             },
//           }}
//           position={[49.01095, -96.615234]}
//           icon={GetIcon(37)}
//         ></Marker>
//         <div className="d-none">
//           <ZoomControl position="bottomright" />
//         </div>
//       </MapContainer>
//     </>
//   );
// }

// export default BizMapModal;
