import React, { useState, useEffect, useContext } from "react";
import { MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import axios from "axios";
import { DomainContext, LangIdContext } from "../../../App";
import TextField from "@mui/material/TextField";
// config
import configData from "../../../config/config.json";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import "../indexprofile.css";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";
import AlertSuccess from "../../../components/Dashbord/AlertSuccess/AlertSuccess";
import { validate } from "../../login/validate";
//
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Changepass = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [disable, setDisable] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [apiError, setApiError] = useState([]);

  const [data, setData] = useState({
    MId: "",
    OldPassword: "",
    NewPassword: "",
    RepeatNewPassword: "",
  });

  const inputChangeHandler = (event) => {
    setPassErr(false);
    setApiError([]);
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setErrors(validate(data, "change-pass-dashboard"));
  }, [data, touched]);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    // LangId: langIdState,
    LangId: getCookie("_gapp_LId"),
    Page: "change-password",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, []);

  const headerPost = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setPassErr(false);
    setApiError([]);

    if (JSON.parse(localStorage.getItem("MemberDetail")).setPassword) {

      if (!Object.keys(errors).length) {
        if (data.NewPassword != data.RepeatNewPassword) {
          setPassErr(true);
        } else {
          setDisable(true);

          const dataPost = {
            password: data.OldPassword,
            newPassword: data.NewPassword,
          };

          axios
            .post(
              configData.API_DOMAIN + "/api/v1/Members/update-password",
              dataPost,
              {
                headers: headerPost,
              }
            )
            .then((response) => {
              setDisable(false);

              if (response.data.isSuccess) {
                alertHandeler();
              } else {
                setApiError(response.data.message);
              }
            })
            .catch((error) => {
              // alert(error);
            });
        }
      } else {
        setTouched({
          OldPassword: true,
          NewPassword: true,
          RepeatNewPassword: true,
        });
      }
    } else {

      if (
        (errors.NewPassword == undefined &&
          errors.RepeatNewPassword == undefined) ||
        (errors.NewPassword == "" && errors.RepeatNewPassword == "")
      ) {
        if (data.NewPassword != data.RepeatNewPassword) {
          setPassErr(true);
        } else {
          setDisable(true);

          const dataPost = {
            password: data.OldPassword,
            newPassword: data.NewPassword,
          };

          axios
            .post(
              configData.API_DOMAIN + "/api/v1/Members/update-password",
              dataPost,
              {
                headers: headerPost,
              }
            )
            .then((response) => {
              setDisable(false);

              if (response.data.isSuccess) {
                alertHandeler();
              } else {
                setApiError(response.data.message);
              }
            })
            .catch((error) => {
              // alert(error);
            });
        }
      } else {
        setTouched({
          NewPassword: true,
          RepeatNewPassword: true,
        });
      }
    }
  };

  // for password btn icon
  const [showPassword3, setShowPassword3] = React.useState(false);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  // for password btn icon
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <Header_mobile />
      <div>
        <div className="margin-0 d-flex">
          <div className="border-left-dashbord d-none d-md-block">
            <Sidbar />
          </div>
          <div className="bg-main-dashbord">
            <Main lencomments={2}>
              <h5 className="header-main">Change Password</h5>
              <div className="centerr mt-3 mt-md-0">
                {JSON.parse(localStorage.getItem("MemberDetail"))
                  .setPassword ? (
                  <>
                    <div className="position-relative custom-text-field-dash">
                      <TextField
                        type={showPassword3 ? "text" : "password"}
                        inputProps={{ maxLength: 25 }}
                        name="OldPassword"
                        value={data.OldPassword}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        className={
                          errors.OldPassword && touched.OldPassword
                            ? "label-field-less7 custom-text-field custom-text-field-dash mb-0 dash-text-field custom-text-field-border-error"
                            : "label-field-less7 custom-text-field custom-text-field-dash mb-0 dash-text-field "
                        }
                      />
                      <IconButton
                        className="show-pass-btn-change-pass-dash"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword3}
                        onMouseDown={handleMouseDownPassword3}
                        edge="start"
                      >
                        {showPassword3 ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </div>
                    {errors.OldPassword && touched.OldPassword && (
                      <span className="input-error change-pash-dahs-error">
                        {errors.OldPassword}
                      </span>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <div className="mt15">
                  <div className="position-relative custom-text-field-dash">
                    <TextField
                      type={showPassword2 ? "text" : "password"}
                      inputProps={{ maxLength: 25 }}
                      name="NewPassword"
                      value={data.NewPassword}
                      onChange={inputChangeHandler}
                      id="outlined-basic"
                      label="New Password"
                      variant="outlined"
                      className={
                        errors.NewPassword && touched.NewPassword
                          ? " custom-text-field-dash label-field-less7 custom-text-field  mb-0 dash-text-field custom-text-field-border-error"
                          : " custom-text-field-dash label-field-less7 custom-text-field  mb-0 dash-text-field "
                      }
                    />
                    <IconButton
                      className="show-pass-btn-change-pass-dash"
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                      edge="start"
                    >
                      {showPassword2 ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </div>
                  {errors.NewPassword && touched.NewPassword && (
                    <span className="input-error change-pash-dahs-error">
                      {errors.NewPassword}
                    </span>
                  )}
                </div>
                <div className="mt15">
                  <div className="position-relative custom-text-field-dash">
                    <TextField
                      type={showPassword ? "text" : "password"}
                      inputProps={{ maxLength: 25 }}
                      name="RepeatNewPassword"
                      value={data.RepeatNewPassword}
                      onChange={inputChangeHandler}
                      id="outlined-basic"
                      label="New Password again"
                      variant="outlined"
                      className={
                        errors.RepeatNewPassword && touched.RepeatNewPassword
                          ? "label-field-less7 custom-text-field custom-text-field-dash mb-0 dash-text-field custom-text-field-border-error"
                          : "label-field-less7 custom-text-field custom-text-field-dash mb-0 dash-text-field "
                      }
                    />
                    <IconButton
                      className="show-pass-btn-change-pass-dash"
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="start"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </div>
                  {errors.RepeatNewPassword && touched.RepeatNewPassword && (
                    <span className="input-error change-pash-dahs-error">
                      {errors.RepeatNewPassword}
                    </span>
                  )}
                </div>
                {passErr && (
                  <div className="centerr mt15 input-error-dash text-center">
                    Password does not match.
                  </div>
                )}
                <div className=" mt15 input-error-dash  change-pash-dahs-error text-start">
                  {apiError.map((item) => item)}
                </div>
                <div className="row_saveButton">
                  <button
                    disabled={disable}
                    onClick={submitHandler}
                    className="saveButton"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </Main>
          </div>
        </div>
      </div>
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={"New password saved successfully."}
      />
    </>
  );
};

export default Changepass;
