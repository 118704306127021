import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DomainContext, LangIdContext } from "../../App";
import { MDBContainer } from "mdb-react-ui-kit";
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
import MetaTagsInfoPage from "../../components/meta-tags-seo/MetaTagsInfoPage";
import NotFound from "../NotFound/NotFound";
// config
import configData from "../../config/config.json";

const Privacy = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  const [pageContent, setPageContent] = React.useState("");

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  useEffect(() => {
    if (langIdState !== "") {
      //api header
      const header = {
        ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
        LangId: langIdState,
        FooterId: props.match.params.id,
      };
      // 1 page content
      axios
      .get(
        configData.API_DOMAIN +
          `/api/v1/Contents?footerId=${props.match.params.id}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data.metaTag);
          setPageContent(response.data.data.itemContent);
        } else {
          setPageContent("0");
        }
      })
      .catch((error) => {
        // alert(error);
      });
      // 2 header-other
      axios
        .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setheaderData(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              headerbiz: true,
            }));
          }
        })
        .catch((error) => {
          // alert(error);
        });
      // 3 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [langIdState]);

  return (
    <>
      {pageContent === "0" ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {MTagsCome ? <MetaTagsInfoPage data={MetaData} /> : ""}

          <div className="d-none d-md-block">
            <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
          </div>
          <div className="d-block d-md-none">
            <div className="fixed-header">
              <Headermobile data={headerData} />
            </div>
            <div className="box-under-header-res"></div>
          </div>
          <div className="gish-page-back">
            <MDBContainer>
              <div dangerouslySetInnerHTML={{ __html: pageContent }} />
            </MDBContainer>
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      )}
    </>
  );
};

export default Privacy;
