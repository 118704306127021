import * as React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import "./rate-icon.css";
import emptyGish from "../../images/home-body/40 line px.png";
import fullGish from "../../images/home-body/40 px.png";

const RateIcon = (props) => {
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconEmpty": {
      color: "#33e0a0",
    },
    "& .MuiRating-iconFilled": {
      color: "#33e0a0",
    },
    "& .MuiRating-iconHover": {
      color: "#33e0a0",
    },
  });

  return (
    <Stack
      component={"span"}
      spacing={2}
      className={
        props.isRec
          ? "rate-pos"
          : props.isBusiness
          ? "rate-pos-business"
          : props.isComment1
          ? "rate-pos-comment1"
          : props.isComment2
          ? "rate-pos-comment2"
          : "rate-pos-s"
      }
      dir="ltr"
    >
      <StyledRating
        name="customized-color"
        defaultValue={props.cardrate}
        getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
        precision={0.25}
        // icon={<i className="fas fa-circle" fontSize="inherit" ></i>}
        // emptyIcon={<i className="far fa-circle"></i>}
        // {props.isRec? "rate-img":  (props.isAdd?   "rate-img-add": "rate-img-s")}
        icon={
          <img
            className={
              props.isBusiness
                ? "rate-business"
                : props.isComment2
                ? "rate-comment2"
                : props.isComment1
                ? "rate-comment1"
                : "rate-img"
            }
            src={fullGish}
            alt="twitter"
          />
        }
        emptyIcon={
          <img
            className={
              props.isBusiness
                ? "rate-business"
                : props.isComment2
                ? "rate-comment2"
                : props.isComment1
                ? "rate-comment1"
                : "rate-img"
            }
            src={emptyGish}
            alt="twitter"
          />
        }
        readOnly={true}
        size="small"
      />
    </Stack>
  );
};

export default RateIcon;
