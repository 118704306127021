import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// components
import Header from "../components/header/Header";
import Headermobile from "../components/header-mobile/Headermobile";
import Listmobile from "../components/list-mobile/Listmobile";
import ScrollCategory from "../components/scroll-category/ScrollCategory";
import Application from "../components/application/Application";
import Footer from "../components/footer/Footer";
import CardCarousel from "../components/card-carousel/CardCarousel";
import CurrencyCard from "../components/currency-card/CurrencyCard";
import OverlayCard from "../components/overlay-card/OverlayCard";
import Rec2Card from "../components/rec2-card/Rec2Card";
import RecCard from "../components/rec-card/RecCard";
import MoarefiAlert from "../components/moarefi-alert/MoarefiAlert";
import MetaTagsIndexPgae from "../components/meta-tags-seo/MetaTagsIndexPage";
import { DomainContext, LangIdContext } from "../App";
// config
import configData from "../config/config.json";
// icons
// import arz from "../images/home-body/قیمت ارز.png";
// import arzDigital from "../images/home-body/ارز دیجیتال.png";
// import newkasbokar from "../images/home-body/تازه‌ترین های بیزنس.png";
// import blog from "../images/home-body/blog.png";
// import kasbokar from "../images/home-body/کسب و کار های اطراف.png";
// import tabliq from "../images/home-body/تبلیغات.png";
import presentation from "../images/home-body/presentation.svg";

const HomePage = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [latestbiz, setLatestbiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
      type: "",
    },
  });
  const [nearbybiz, setNearbybiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });
  const [bizrandomTit, setBizrandomTit] = useState({});
  const [bizrandomcat, setBizrandomcat] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
      type: "",
    },
  });
  const [categories, setCategories] = useState([
    { title: "", icon: "" },
    { title: "", icon: "" },
    { title: "", icon: "" },
    { title: "", icon: "" },
    { title: "", icon: "" },
  ]);
  const [currencies, setCurrencies] = useState({
    currencies: [
      // { id: "", Symbol: "", price: "", Icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
      { symbol: "", price: "", icon: "" },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });
  const [cryptos, setCryptos] = useState({
    data: {
      currencies: [
        // { id: "", Symbol: "", price: "", Icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
        { symbol: "", price: "", icon: "" },
      ],
      sectionTitle: {
        icon: "",
        title: "",
        url: "",
      },
    },
    isSuccess: true,
  });
  const [blogposts, setBlogposts] = useState([
    { filePath: "", titr: "", lead: "", newsId: "" },
    { filePath: "", titr: "", lead: "", newsId: "" },
    { filePath: "", titr: "", lead: "", newsId: "" },
    { filePath: "", titr: "", lead: "", newsId: "" },
  ]);
  const [paidbiz, setPaidbiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
      type: "",
    },
  });

  const [dataCome, setDataCome] = useState(false);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [data, setData] = useState({
    headerLogo: {},
    categoriesForSearch: [
      {
        cId: "",
        title: "",
        shortTitle: "",
        icon: "",
      },
    ],
    coverPhoto: "",
    categoriesOnHeader: [
      {
        cId: 1,
      },
      {
        cId: 2,
      },
      {
        cId: 3,
      },
      {
        cId: 4,
      },
      {
        cId: 5,
      },
    ],
    categoriesOnHeaderShort: [{}, {}, {}, {}, {}, {}, {}, {}],
  });

  const [ApiDataCome, setApiDataCome] = useState({
    header: false,
    footer: false,
    nearbybiz: false,
    latestbiz: false,
    paidbiz: false,
    bizrandomcat: false,
    categories: false,
    blogposts: false,
    currencies: false,
    cryptos: false,
  });

  const [footerData, setFooterData] = useState({});

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    // LangId: ApiDataCome.footer ? footerData.LanguageList[0].LId : "1",
    LangId: langIdState,
  };

  useEffect(() => {
    if (langIdState !== "") {
      setDataCome(false);

      //1 Meta Tags Api
      axios
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/index", {
          headers: header,
        })
        .then((response) => {
          setMTagsCome(true);
          setMetaData(response.data.data);
        })
        .catch((error) => {
          // alert(error);
        });

      //2 header
      axios
        .get(configData.API_DOMAIN + "/api/v1/Headers", {
          headers: header,
        })
        .then((response) => {
          setData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, header: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 3 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      //4 nearbybiz
      axios
        .get(configData.API_DOMAIN + "/api/v1/Businesses/most-viewed", {
          headers: header,
        })
        .then((response) => {
          if ((response.data.msg = "done")) {
            setNearbybiz(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              nearbybiz: true,
            }));
          }
        })
        .catch((error) => {
          // alert(error);
        });

      // 5 latestbiz
      axios
        .get(configData.API_DOMAIN + "/api/v1/Businesses/latest", {
          headers: header,
        })
        .then((response) => {
          setLatestbiz(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            latestbiz: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 6 paidbiz
      axios
        .get(configData.API_DOMAIN + "/api/v1/Businesses/most-rated", {
          headers: header,
        })
        .then((response) => {
          setPaidbiz(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, paidbiz: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 7 bizrandomcat
      axios
        .get(configData.API_DOMAIN + "/api/v1/Businesses/random", {
          headers: header,
        })
        .then((response) => {
          // if ((response.msg = "done")) {
          setBizrandomcat(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            bizrandomcat: true,
          }));
          // }
        })
        .catch((error) => {
          // alert(error);
        });

      // 8 categories
      axios
        .get(configData.API_DOMAIN + "/api/v1/Categories/parent", {
          headers: header,
        })
        .then((response) => {
          setCategories(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            categories: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 9 blogposts
      // axios
      //   .get(domain + "/api/blogposts", { headers: header })
      //   .then((response) => {
      //     setBlogposts(response.data);
      //     setApiDataCome((ApiDataCome) => ({
      //       ...ApiDataCome,
      //       blogposts: true,
      //     }));
      //   })
      //   .catch((error) => {
      //     // alert(error);
      //   });

      // 10 currencies
      axios
        .get(configData.API_DOMAIN + "/api/v1/Currencies", {
          headers: header,
        })
        .then((response) => {
          setCurrencies(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            currencies: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });
      // 11 cryptos
      axios
        .get(configData.API_DOMAIN + "/api/v1/Currencies/cryptos", {
          headers: header,
        })
        .then((response) => {
          setCryptos(response.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, cryptos: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [langIdState]);

  return (
    <>
      {MTagsCome ? <MetaTagsIndexPgae data={MetaData} /> : ""}
      <MoarefiAlert />

      {/* laptop header */}
      <div className="d-none d-md-block">
        <Header data={data} dataCome={ApiDataCome.header} />
      </div>

      {/* header mobile */}
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={data} />
        </div>
        <div className="box-under-header-res"></div>

        <Listmobile
          data={data.categoriesOnHeaderShort}
          dataCome={ApiDataCome.header}
        />
        <div className="centerr">
          <a
            href={`${configData.BIZ_URL}/Login`}
            target="_blank"
            className="sabt-btn-a  mb-sabt-mobile sabt-mobile-btn hov-shadow-btn-dark shadow-off"
          >
            For Business
          </a>
        </div>
      </div>

      {/* کارت های کسب‌وکارهای اطراف */}
      <div className="container">
        <div className="mb-65 mb-xs-31">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-md-3">
            <div className="d-flex align-items-end ">
              <object
                data={nearbybiz.sectionTitle.icon}
                alt={nearbybiz.sectionTitle.title}
                className="tit-img1 tit-img"
                type={nearbybiz.sectionTitle.type}
              ></object>
              {/* <img className="tit-img1 tit-img" src={kasbokar} alt="..." /> */}

              <a href={nearbybiz.sectionTitle.url} className="color-tit">
                <p className="color-tit my-0 fs-xs-14">
                  {nearbybiz.sectionTitle.title}
                </p>
              </a>
              {/* <Link to="/result" className="color-tit">
              <p className="color-tit my-0 fs-xs-14">Near by Business</p>
              </Link> */}
            </div>
            {/* <Link to="/result" className="hover-5ae6a2">
              <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2 ">
                more
              </p>
            </Link> */}
          </div>

          <div className="index-rec-cards">
            {nearbybiz.businesses.map((item) => (
              <div
                className="col-12 col-md-4 rec-md rec-center mt-sm-0"
                key={item.bId}
              >
                <RecCard
                  title={item.title}
                  desc1={item.catTitle}
                  desc2={item.location}
                  rate={item.rate}
                  img={item.photoPath}
                  url={item.url}
                  flag={item.flg}
                  datacome={ApiDataCome.nearbybiz}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* اسلایدر کارت تازه‌ترین کسب‌وکارها */}
      <div className="container pe-sm-0 p-xs-0">
        <div className="mb-40 mb-xs-12">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-0 px-2 px-md-0">
            <div className="d-flex align-items-end ">
              <object
                data={latestbiz.sectionTitle.icon}
                alt={latestbiz.sectionTitle.title}
                className="tit-img"
                type={nearbybiz.sectionTitle.type}
              ></object>
              <a href={latestbiz.sectionTitle.url} className="color-tit">
                <p className="my-0 fs-xs-14 color-tit">
                  {latestbiz.sectionTitle.title}
                </p>
              </a>

              {/* <img className="tit-img" src={newkasbokar} alt="..." />
              <Link to="/result" className="color-tit">
              <p className="my-0 fs-xs-14 color-tit">The Latest News </p>
              </Link> */}
            </div>
          </div>
          {/* <CardCarousel withRate={true} /> */}
          <div className="d-none d-md-block">
            <CardCarousel
              data={latestbiz.businesses}
              withRate={true}
              datacome={ApiDataCome.latestbiz}
            />
          </div>
          <div className="d-block d-md-none">
            <ScrollCategory
              data={latestbiz.businesses}
              isCarousel={true}
              withRate={true}
              datacome={ApiDataCome.latestbiz}
            />
          </div>
        </div>
      </div>

      <div className="container">
        {/*معرفی به گیشنیز*/}
        <div className="text-align-centerr d-none d-md-block">
          <div className="moarefi d-flex justify-content-around  align-items-center">
            <div className="align-self-center text-center ">
              Know any Farsi-speaking businesses? <br />
              <span>Introduce them to Gishniz!</span>
            </div>
            <img
              className="moarefi-icon"
              src={presentation}
              alt="moarefi icon"
            />

            <Link
              className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
              to="/introduce-business"
            >
              Click here{" "}
            </Link>

            {/* </div> */}
          </div>
        </div>
        {/*comment for now thre is not blog */}
        {/* بلاگ گیشنیز */}
        {/* <div className="mb-65 mb-xs-16 mb-sm-3 ">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-md-3">
            <div className="d-flex align-items-end ">
              <img className="tit-img" src={blog} alt="..." />
              <a
                className="color-tit"
                href={configData.BLOG_URL}
                target="_blank"
              >
                <p className="my-0 fs-xs-14  color-tit">Blog Gishniz</p>
              </a>
            </div>
          </div>
          <div className="row mb-md-5">
            <div className="col-12 col-xl-7 col-md-6 mb-4 mb-md-0  pe-md-2 ps-md-12 px-auto px-0 px-sm-12 ">
              <OverlayCard
                img={blogposts[0].FilePath}
                title={blogposts[0].Titr}
                desc={blogposts[0].Lead}
                url={blogposts[0].PageURL}
              />
            </div>
            <div className="col-12 col-xl-5 col-md-6 ps-md-2 px-auto  ">
              <div className="mb-10px mb-xs-14 rec-center">
                <Rec2Card
                  id={blogposts[1].NewID}
                  img={blogposts[1].FilePath}
                  title={blogposts[1].Titr}
                  desc={blogposts[1].Lead}
                  url={blogposts[1].PageURL}
                />
              </div>
              <div className="mb-10px mb-xs-14 rec-center">
                <Rec2Card
                  id={blogposts[2].NewID}
                  img={blogposts[2].FilePath}
                  title={blogposts[2].Titr}
                  desc={blogposts[2].Lead}
                  url={blogposts[2].PageURL}
                />
              </div>
              <div className=" rec-center">
                <Rec2Card
                  id={blogposts[3].NewID}
                  img={blogposts[3].FilePath}
                  title={blogposts[3].Titr}
                  desc={blogposts[3].Lead}
                  url={blogposts[3].PageURL}
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* معرفی به گیشنیز سایز موبایل*/}
        <div className="text-align-centerr d-block d-md-none">
          <div className="moarefi d-flex justify-content-between align-items-center">
            <div className=" align-self-center text-white alert-m-text">
              Know any Farsi-speaking businesses?
              <br />
              <span>Introduce them to Gishniz!</span>
            </div>

            <Link
              className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
              to="/introduce-business"
            >
              Click here{" "}
            </Link>
          </div>
        </div>
      </div>

      {/* قیمت ارز */}
      <div className="mb-65 mb-xs-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 pe-md-2 mb-xs-16">
              <div className="d-flex align-items-end justify-content-between titbox-index-res mb-sm-3 mb-xs-10">
                <div className="d-flex align-items-end ">
                  <object
                    data={currencies.sectionTitle.icon}
                    alt={currencies.sectionTitle.title}
                    className="tit-img"
                    type={nearbybiz.sectionTitle.type}
                  ></object>
                  <a className="color-tit" href={currencies.sectionTitle.url}>
                    <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                      {currencies.sectionTitle.title}
                    </p>
                  </a>
                </div>
              </div>
              <div className="centerr">
                {currencies.currencies.map((item) => (
                  <CurrencyCard
                    currencies={true}
                    key={item.id}
                    // isRed={false}
                    title={item.symbol}
                    price={item.price}
                    icon={item.icon}
                    datacome={ApiDataCome.categories}
                  />
                ))}
              </div>
            </div>
            {cryptos.isSuccess &&
              cryptos.data.currencies &&
              cryptos.data.currencies.length > 0 && (
                <div className="col-12 col-md-6 ps-md-2">
                  <div className="d-flex align-items-end justify-content-between titbox-index-res mb-sm-3 mt-md-0 mt-sm-3 mb-xs-10">
                    <div className="d-flex align-items-end ">
                      <object
                        data={cryptos.data.sectionTitle.icon}
                        alt={cryptos.data.sectionTitle.title}
                        type={nearbybiz.sectionTitle.type}
                        className="tit-img"
                      ></object>
                      <a
                        className="color-tit"
                        href={cryptos.data.sectionTitle.url}
                      >
                        <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                          {cryptos.data.sectionTitle.title}
                        </p>
                      </a>
                      {/* <img className="tit-img" src={arzDigital} alt="..." />
                  <a className="color-tit" href="#!">
                  <p className="my-0 fs-xs-14 color-tit">Digital Currency</p>
                  </a>/ */}
                    </div>
                  </div>
                  <div className="centerr">
                    {cryptos.data.currencies.map((item) => (
                      <CurrencyCard
                        currencies={false}
                        key={item.id}
                        // isRed={true}
                        title={item.symbol}
                        price={item.price}
                        icon={item.icon}
                        datacome={ApiDataCome.cryptos}
                      />
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="container">
        {/* کارت های تبلیغات */}
        <div className="mb-65 mb-xs-28">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-xs-10 mb-md-3">
            <div className="d-flex align-items-end ">
              <object
                data={paidbiz.sectionTitle.icon}
                alt={paidbiz.sectionTitle.title}
                type={nearbybiz.sectionTitle.type}
                className="tit-img"
              ></object>
              <a href={paidbiz.sectionTitle.url} className="color-tit">
                <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  {paidbiz.sectionTitle.title}
                </p>
              </a>
              {/* <img className="tit-img" src={tabliq} alt="..." />
              <Link to="/result" className="color-tit">
              <p className="my-0 fs-xs-14 color-tit">Advertisment</p>
              /</Link> */}
            </div>
            {/* <Link to="/result" className="hover-5ae6a2">
              <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2 ">
                more
              </p>
            </Link> */}
          </div>
          <div
            className={
              paidbiz.businesses.length > 2
                ? "index-rec-cards"
                : "index-rec-cards index-rec-cards2"
            }
          >
            {paidbiz.businesses.map((item) => (
              <div className="col-12 col-md-4 rec-md rec-center" key={item.bId}>
                <RecCard
                  title={item.title}
                  desc1={item.catTitle}
                  desc2={item.location}
                  rate={item.rate}
                  img={item.photoPath}
                  url={item.url}
                  flag={item.flg}
                  datacome={ApiDataCome.paidbiz}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* اسلایدر کارت رندوم */}
      <div className="container pe-sm-0 p-xs-0">
        <div className="mb-65 mb-xs-20">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-0 px-2">
            <div className="d-flex align-items-end ">
              <object
                data={bizrandomcat.sectionTitle.icon}
                alt={bizrandomcat.sectionTitle.title}
                className="tit-img"
                // type={bizrandomcat.SectionTitle.Type}
                type="image/png"
              ></object>
              <a href={bizrandomcat.sectionTitle.url} className="color-tit">
                <p className="my-0 fw-800 fs-18 fs-xs-14 color-101010 color-tit">
                  {bizrandomcat.sectionTitle.title}
                </p>
              </a>
              {/* <img className="tit-img" src={bizrandomTit.SectionTitle.Icon} alt="..." /> */}
              {/* <Link to="/result" className="color-tit"> */}
              {/* <p className="my-0 fs-xs-14 color-tit">{bizrandomTit.SectionTitle.Title}</p> */}
              {/* </Link> */}
            </div>
          </div>
          <div className="d-none d-md-block">
            <CardCarousel
              data={bizrandomcat.businesses}
              withRate={true}
              datacome={ApiDataCome.bizrandomcat}
            />
          </div>
          <div className="d-block d-md-none">
            <ScrollCategory
              data={bizrandomcat.businesses}
              isCarousel={true}
              withRate={true}
              datacome={ApiDataCome.bizrandomcat}
            />
          </div>
        </div>
      </div>

      {/*دسته‌بندی‌ */}
      <div className="container p-xs-0">
        <div className="mb-65 mb-xs-60">
          <ScrollCategory
            data2={categories}
            isCarousel={false}
            withRate={true}
            datacome={ApiDataCome.categories}
          />
        </div>
      </div>

      {/* دانلود اپلیکیشن گیشنیز*/}
      <div className="container">
        <div className="mb-45 mb-xs-40">
          <Application />
        </div>
      </div>

      {/* footer */}
      {/* <Footer /> */}
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default HomePage;
