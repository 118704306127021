import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DomainContext, LangIdContext } from "../../App";
// components
import MetaTagsEndUserPage from "../../components/meta-tags-seo/MetaTagsEndUserPage";
import Footer from "../../components/footer/Footer";
// config
import configData from "../../config/config.json";

const IntroduceBusinessConfirm = (props) => {
  // const { state } = props.location;
  const state = JSON.parse(localStorage.getItem("business-introduction-data"));

  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    // header: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };
  useEffect(() => {
     // 1 metaTags
     axios
     .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
       headers: {
         ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
         LangId: langIdState,
         Page: "introduce-business",
       },
     })
     .then((response) => {
       if (response.data.isSuccess) {
         setMTagsCome(true);
         setMetaData(response.data.data);
       }
     })
     .catch((error) => {
       // alert(error);
     });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  const clickHandler = () => {
    // console.log("state");
    // console.log(state);
    // if user is log in
    if (getCookie("MemId") !== "") {
      props.history.push({
        pathname: "/introduce-business/introduce-business-thanks",
        // state: state, // your data array of objects
      });
    } else {
      props.history.push({
        pathname: "/login",
        state: "from-introduce", // back url
      });
    }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} noindex={true}/> : ""}

      <div className="container centerr">
        <div className="ib-confirm-box">
          <div className="ib-confirm-box2">
            <p className="ib-confirm-tit">
              Is the information entered correct?
            </p>
            <div>
              <div className="text-start">
                <p className="ib-title">Business Name In English</p>
                <span className="ib-txt " dir="ltr">
                  {/* Tehronchi */}
                  {state.engName}
                </span>
              </div>
              {state.perName && (
                <div className="text-start">
                  <p className="ib-title">Business Name In Persian</p>
                  <span className="ib-txt ib-name-in-persian" dir="ltr">
                    {/* طهرونچی */}
                    {state.perName}
                  </span>
                </div>
              )}
              <div className="text-start">
                <p className="ib-title">Country</p>
                <span className="ib-txt " dir="ltr">
                  {state.countryy}
                  {/* ایران */}
                </span>
              </div>
              <div className="text-start">
                <p className="ib-title">Provice</p>
                <span className="ib-txt " dir="ltr">
                  {/* تهران */}
                  {state.provincee}
                </span>
              </div>
              <div className="text-start">
                <p className="ib-title">City</p>
                <span className="ib-txt " dir="ltr">
                  {/* تهران */}
                  {state.cityy}
                </span>
              </div>

              {state.addresss && (
                <div className="text-start">
                  <p className="ib-title">Address</p>
                  <span className="ib-txt " dir="ltr">
                    {/* طهرونچی */}
                    {state.addresss}
                  </span>
                </div>
              )}

              {Object.keys(state.webOrInsta).map((c) => {
                return (
                  <div className="text-start " key={c}>
                    <p className="ib-title">Website or Social Media</p>
                    <span className="ib-txt">{state.webOrInsta[c]}</span>
                  </div>
                );
                // console.log(state.webOrInsta[c]);
              })}
              {/* </p> */}
              {/* <div className="text-start">
            <p className="mb-0">وبسایت</p>
            <p classpanName="mb-0" dir="ltr">
              Tehronchi.com
            </p>
          </div> */}
            </div>

            <div className="d-flex justify-content-between ">
              {/* <MDBBtn className="ib-sabt-btn1 hov-shadow-btn shadow-off"> */}

              <Link
                to={{
                  pathname: "/introduce-business",
                  state: state,
                  // hash:"1"
                }}
                className="ib-sabt-a1 hov-shadow-btn shadow-off"
              >
                Back
              </Link>
              {/* </MDBBtn> */}
              {/* <MDBBtn className="ib-sabt-btn2 hov-shadow-btn shadow-off"> */}
              <Link
                // to={{
                //   // pathname: "/login",
                //   pathname: "/introduce-business/introduce-business-thanks",
                //   state: state,
                // }}
                onClick={clickHandler}
                className="ib-sabt-a2 hov-shadow-btn shadow-off"
              >
                Accept
              </Link>
              {/* </MDBBtn> */}
            </div>
          </div>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default IntroduceBusinessConfirm;
