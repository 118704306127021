import "./headereng.css";
import React, { useEffect, useState, useContext } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Skeleton from "@mui/material/Skeleton";
// import "./Header.css";
import Box from "@mui/material/Box";
import { DomainContext, LangIdContext, CookieContext } from "../../App";
// componenst
import Inputs from "./Inputs";
// images
import imageLoginwhite from "../../images/login img/user.svg";
import imageLogout from "../../images/login img/log-out gray.svg";
import nextLogin from "../../images/login img/play (1).svg";
import account from "../../images/login img/account.svg";
import nextBiz from "../../images/next-blue.svg";
import regBiz from "../../images/header/business register.svg";
import loginBiz from "../../images/header/login business.svg";
// config
import configData from "../../config/config.json";

const Header = (props) => {
  // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const [showmenu, setshowmenu] = useState(false);
  const [showmenuBiz, setshowmenuBiz] = useState(false);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  // delete all cookies logggggg outttt
  function deleteAllCookies() {
    localStorage.clear();

    // var cookies = document.cookie.split(";");

    // for (var i = 0; i < cookies.length; i++) {
    //   var cookie = cookies[i];
    //   var eqPos = cookie.indexOf("=");
    //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // }
    document.cookie = "MemId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    setCookieState("");

    setshowmenu(!showmenu);
  }

  const handleShow = () => {
    setshowmenu((prev) => !prev);
  };

  const handleShowAway = () => {
    setshowmenu(false);
  };

  const handleShowBiz = () => {
    setshowmenuBiz((prevState) => !prevState);
  };

  const handleShowAwayBiz = () => {
    setshowmenuBiz(false);
  };

  // useEffect(() => {
  //   for (let i = 0; i < props.; i++) {
  //     ObjectRow()
  // }
  // }, []);
  const [childCat1, setChildCat1] = useState([]);
  const [childCat2, setChildCat2] = useState([]);

  // let childCat1;
  // let childCat2;

  const set2Array1 = (item) => {
    let count = 0;
    if (item.length % 2 == 0) {
      // is even length
      // console.log("zzzzzzzzzzzzzoj");
      // console.log(item.length / 2);

      count = item.length / 2;
    } else {
      // is odd length
      count = parseInt(item.length / 2) + 1;
    }
    return item.slice(0, count);
  };
  const set2Array2 = (item) => {
    let count = 0;
    if (item.length % 2 == 0) {
      // is even length
      count = item.length / 2;
    } else {
      // is odd length
      count = parseInt(item.length / 2) + 1;
    }
    return item.slice(count, item.length);
  };

  const lanHandler = (event) => {
    // if (getCookie("_gapp_Locale") !== "") {
    window.location.href = `https://gishniz.link${window.location.pathname}`;
    // window.location.href = `https://gishniz.app${window.location.pathname}`;
    // }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  return (
    <>
      <nav className="header-nav">
        <MDBContainer fluid className="pt-34 px-lg-5">
          <MDBRow className="px-2 mx-0 align-items-center">
            <div className="col-8 p-0">
              <div className="container d-flex align-items-center">
                <Link to="/">
                  <img
                    src={props.data.headerLogo.link}
                    alt={props.data.headerLogo.title}
                    title={props.data.headerLogo.title}
                    height="32px"
                    // className="mt-2"
                  />
                </Link>
                <MDBBtn className="chamge-lan-btn" onClick={lanHandler}>
                  فا
                </MDBBtn>
                <p className="mt-10 mb-0 fs-18 color-434343 ms-3">
                  Discover Farsi-speaking businesses nearby!
                </p>
              </div>
            </div>
            <div className="col-4 p-0 d-flex justify-content-end  align-items-center">
              {/*comment for now thre is not blog */}
              {/* <div className="width-53px">
                <a
                  href={configData.BLOG_URL}
                  target="_blank"
                  className=" fs-18 color-5ae6a2 width-53px me-4 d-flex align-items-center"
                >
                  <p className="sabt-hov mb-0 ">Blog</p>
                </a>
              </div> */}

              <ClickAwayListener onClickAway={handleShowAwayBiz}>
                <Box className="position-relative">
                  <>
                    <div
                      className={
                        showmenuBiz
                          ? "select_login_head_biz  mx-4 px-3"
                          : "select_login_head_biz  mx-4 px-3"
                      }
                      onClick={handleShowBiz}
                    >
                      <a className="color-5ae6a2 fs-18 align-items-center  ">
                        <p className="mb-0 pe-2">Gishniz for Businesses</p>
                      </a>

                      <object
                        onClick={handleShowBiz}
                        className={
                          showmenuBiz
                            ? "next-biz nextLogin-rotate-biz"
                            : "next-biz nextLogin-rotateback-biz"
                        }
                        data={nextBiz}
                        alt=""
                        type="image/svg+xml"
                      ></object>
                    </div>

                    {showmenuBiz && (
                      <List
                        onMouseOver={() => setshowmenuBiz(true)}
                        onMouseOut={() => setshowmenuBiz(false)}
                        className="box_show_info_userlogin_biz"
                        component="nav"
                        aria-label="mailbox folders"
                      >
                        <a
                          // href={`${configData.BIZ_URL}/choose-owner`}
                          href={`${configData.BIZ_URL}/signup`}
                          target="_blank"
                        >
                          <ListItem button>
                            <object
                              data={regBiz}
                              className="box_show_info_biz_img"
                              alt="regBiz"
                              type="image/svg+xml"
                            ></object>
                            Add a business
                          </ListItem>
                        </a>
                        <Divider />
                        <a href={`${configData.BIZ_URL}/login`} target="_blank">
                          <ListItem button>
                            <object
                              data={loginBiz}
                              className="box_show_info_biz_img"
                              alt="regBiz"
                              type="image/svg+xml"
                            ></object>
                            Log in
                          </ListItem>
                        </a>
                      </List>
                    )}
                  </>
                </Box>
              </ClickAwayListener>

              <ClickAwayListener onClickAway={handleShowAway}>
                <Box className="position-relative">
                  {getCookie("MemId") &&
                  JSON.parse(localStorage.getItem("MemberDetail")) ? (
                    <>
                      <div
                        className={
                          showmenu
                            ? "select_login_sucess select_login_sucess_color "
                            : "select_login_sucess select_login_sucess2"
                        }
                        // onMouseOver={() => setshowmenu(true)}
                        // onMouseOut={() => setshowmenu(false)}

                        onClick={handleShow}
                        // onMouseDown ={() => setshowmenu(false)}
                      >
                        {/* <img
                          className="imageLogin_success_header"
                          src={imageLoginwhite}
                          alt=""
                        /> */}
                        <object
                          onClick={handleShow}
                          className="imageLogin_success_header"
                          data={imageLoginwhite}
                          alt=""
                          type="image/svg+xml"
                        ></object>
                        <object
                          onClick={handleShow}
                          className={
                            showmenu
                              ? "nextLogin nextLogin-rotate"
                              : "nextLogin nextLogin-rotateback"
                          }
                          data={nextLogin}
                          alt=""
                          type="image/svg+xml"
                        ></object>

                        {/* <img
                          className={
                            showmenu
                              ? "nextLogin nextLogin-rotate"
                              : "nextLogin nextLogin-rotateback"
                          }
                          src={nextLogin}
                          
                          type="image/svg+xml"
                          alt=""
                        /> */}
                      </div>
                      {showmenu && (
                        <List
                          onMouseOver={() => setshowmenu(true)}
                          onMouseOut={() => setshowmenu(false)}
                          className="box_show_info_userlogin"
                          component="nav"
                          aria-label="mailbox folders"
                        >
                          <ListItem button>
                            <img
                              className="img20x20"
                              // src={imageLogin}
                              src={
                                JSON.parse(localStorage.getItem("MemberDetail"))
                                  .photo
                              }
                              alt="icon"
                            />
                            {
                              JSON.parse(localStorage.getItem("MemberDetail"))
                                .firstname
                            }{" "}
                            {
                              JSON.parse(localStorage.getItem("MemberDetail"))
                                .lastname
                            }
                          </ListItem>
                          <Divider />
                          <Link to={"/members/profile"}>
                            <ListItem button>
                              <img className="img12x12" src={account} alt="" />
                              Profile
                            </ListItem>
                          </Link>
                          <Divider />
                          <ListItem onClick={deleteAllCookies} button>
                            <img
                              className="img20x20"
                              src={imageLogout}
                              alt=""
                            />
                            Log out
                          </ListItem>
                        </List>
                      )}
                    </>
                  ) : (
                    <>
                      {/* // <select className="select_login_sucess">
                      //   <option></option>
                      //   <option>{getCookie("userName")}</option>
                      //   <option>مشاهده پروفایل</option>
                      //   <option>خروج</option>
                      // </select> */}
                      <Link className=" bg-head-btn" to="/login">
                        Sign in
                      </Link>
                    </>
                  )}
                </Box>
              </ClickAwayListener>
              {/* </MDBBtn> */}
            </div>
          </MDBRow>
        </MDBContainer>
      </nav>
      <div
        className="mb-30 head-bg d-none d-md-block"
        style={{ backgroundImage: `url(${props.data.coverPhoto})` }}
      >
        <div className="header-color">
          <MDBContainer className="">
            <MDBRow>
              <div className="mb-65 mt-191">
                <Inputs
                  data={props.data.categoriesForSearch}
                  data2={props.data.categoriesOnFocus}
                />
              </div>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
      <div className="mb-5">
        <MDBListGroup horizontal className="justify-content-center px-0 mt-15">
          {props.data.categoriesOnHeader.map((item) =>
            props.dataCome ? (
              <MDBListGroupItem
                className="text-align-center py-0"
                key={item.cId}
              >
                <div className="me-md-1 me-2 header-icons-hov business-dropdown mb-0">
                  {/* <img
                      className="business-card-icon3  business-dropbtn"
                      src={item.Icon}
                      alt="..."
                    /> */}
                  <Link
                    className="a-list fs-15"
                    to={item.url === "javscript:void(0);" ? void 0 : item.url}
                  >
                    <img
                      className="img-list mb-1"
                      src={item.icon}
                      alt={item.shortTitle}
                    />
                    <br />
                    {item.shortTitle}
                  </Link>

                  <div className="business-dropdown-content header-dropdown-content3">
                    <div className="box-subcat-header">
                      <div className="d-inline-block mx-0">
                        {/* {console.log(set2Array1(item.children))}
                            {console.log(set2Array2(item.children))}
                            {console.log(parseInt(item.children.length/2))} */}
                        {set2Array1(item.children).map((item2) => (
                          <div className=" px-0">
                            <a
                              href={item2.url}
                              className="mb-1 px-2 a-header-subcat"
                            >
                              {/* comment until icons of sub categories get ready in back end *** */}
                              {/* <img
                                src={item2.Icon}
                                alt={item2.Title}
                                className="img-header-subcat"
                              /> */}

                              {item.shortTitle === "More" && (
                                <img
                                  src={item2.icon}
                                  alt={item2.title}
                                  className="img-header-subcat"
                                />
                              )}
                              {item2.title}
                            </a>
                          </div>
                        ))}
                      </div>
                      <div className="d-inline-block mx-0">
                        {set2Array2(item.children).map((item2) => (
                          <div className=" px-0">
                            <a
                              href={item2.url}
                              className="mb-1 px-2 a-header-subcat"
                            >
                              {/* comment until icons of sub categories get ready in back end *** */}
                              {/* <img
                                src={item2.Icon}
                                alt={item2.Title}
                                className="img-header-subcat"
                              /> */}

                              {item.shortTitle === "More" && (
                                <img
                                  src={item2.icon}
                                  alt={item2.title}
                                  className="img-header-subcat"
                                />
                              )}
                              {item2.title}
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </MDBListGroupItem>
            ) : (
              <MDBListGroupItem className="text-align-center py-0">
                <Link className="a-list fs-15">
                  <Skeleton
                    variant="circular"
                    className=""
                    width={50}
                    height={50}
                    animation="wave"
                  />
                  {/* <br /> */}
                  <Skeleton
                    className="mx-0 mt-3"
                    height={12}
                    animation="wave"
                  />
                </Link>
              </MDBListGroupItem>
            )
          )}

          {/* <MDBListGroupItem className="text-align-center py-0">
              <Link className="a-list fs-15" to="/">
                <img className="img-list mb-1" src={more_i} alt="more" />
                <br />
                بیشتر
              </Link>
            </MDBListGroupItem> */}

          {/* <MDBListGroupItem className="text-align-center py-0">
              <Link className="a-list fs-15" to="/result">
                <img
                  className="img-list mb-1"
                  src={kharid}
                  alt="مرکز خرید و فروشگاه"
                />
                <br />
                خرید{" "}
              </Link>
            </MDBListGroupItem>*/}
        </MDBListGroup>
      </div>
    </>
  );
};

export default Header;
