import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import TextField from "@mui/material/TextField";
// import rtlPlugin from "stylis-plugin-rtl";
// import { prefixer } from "stylis";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";
import "./RTLAccordian.css";

import { DomainContext, LangIdContext } from "../../App";

// const theme = createTheme({
//   direction: "rtl", // Both here and <body dir="rtl">
// });
// // Create rtl cache
// const cacheRtl = createCache({
//   key: "muirtl",
//   stylisPlugins: [prefixer, rtlPlugin],
// });
// styles
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: `10px`,
  borderBottom: `1px solid white`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: "44px",
  height: "44px",
  padding: "0 18px 0 22px",
  borderTop: "1px solid #8ef0c0",
  backgroundColor: "#fff",
  flexDirection: "row",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #8ef0c0",
  margin: "0 29px",
  padding: "10px 0",
}));

//   component
const RTLAccordian = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const {
    paymentsFilter,
    setPaymentsFilter,
    amenitiesFilter,
    setAmenitiesFilter,
  } = props;

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel, open) => (event, newExpanded) => {
    if (open) {
      setExpanded(newExpanded ? panel : false);
    }
  };
  const [category, setCategory] = useState([]);
  const [option, setOption] = useState([]);
  const [peyment, setpeyment] = useState([]);

  useEffect(() => {
    if (props.dataComeCategory) {
      setCategory(props.category);
    }
    if (props.dataComeOption) {
      setOption(props.option);
    }
    if (props.dataComePeyment) {
      setpeyment(props.peyment);
    }
    // console.log("ssssssssssss");
    // console.log(props);
  }, [props.dataComeCategory, props.dataComeOption, props.dataComePeyment]);

  const handlePaymentChange = (event, id) => {
    if (event.target.checked) {
      // console.log("piid" + id);
      if (!paymentsFilter.includes(id)) {
        setPaymentsFilter((paymentsFilter) => [...paymentsFilter, id]);
      }
    } else {
      setPaymentsFilter(paymentsFilter.filter((item) => item !== id));
    }
  };

  const handleAmenitiesChange = (event, id) => {
    if (event.target.checked) {
      if (!paymentsFilter.includes(id)) {
        setAmenitiesFilter((amenitiesFilter) => [...amenitiesFilter, id]);
      }
    } else {
      setAmenitiesFilter(amenitiesFilter.filter((item) => item !== id));
    }
    // console.log("amenitiesFilter" + amenitiesFilter);
  };

  return (
    <div>
      {console.log("opt>>>" + option.length)}
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1", option.length > 0)}
        >
          <AccordionSummary
            aria-controls="panel1d-rtl-content"
            id="panel1d-rtl-header"
          >
            <Typography>Services</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* {emkanat.map((item) => (
                    <div key={item.id} className="result-filter-item">
                      <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
                        <input type="checkbox" />
                        <span className="checkmark-s2 back-white"></span>
                        <span>{item.title}</span>
                      </label>
                    </div>
                  ))} */}
              {option.length ? (
                option.map((item) => (
                  <div key={item.COTId} className="result-filter-item">
                    <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          handleAmenitiesChange(event, item.COTId)
                        }
                      />
                      <span className="checkmark-s2 back-white"></span>
                      <span>{item.Title}</span>
                    </label>
                  </div>
                ))
              ) : (
                <p></p>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2", peyment.length > 0)}
        >
          <AccordionSummary
            aria-controls="panel2d-rtl-content"
            id="panel2d-rtl-header"
          >
            <Typography>Payment method</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {peyment.length ? (
                peyment.map((item) => (
                  <div key={item.PTId} className="result-filter-item">
                    <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          handlePaymentChange(event, item.PTId)
                        }
                      />
                      <span className="checkmark-s2 back-white"></span>
                      <span>{item.Title}</span>
                    </label>
                  </div>
                ))
              ) : (
                <p></p>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3", category.length > 0)}
        >
          <AccordionSummary
            aria-controls="panel3d-rtl-content"
            id="panel3d-rtl-header"
          >
            <Typography>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {category.length ? (
                category.map((item) => (
                  <div
                    key={item.CId}
                    className="result-filter-item result-filter-item-res"
                  >
                    <Link
                      to={item.Url}
                      className="color-242b2e"
                      onClick={() => {
                        if (props.setCloseModal) {
                          props.setCloseModal(false);
                        }
                      }}
                    >
                      <span>{item.Title}</span>
                    </Link>
                    {/* <label className="container-s2 m-0 me-0 mb-0 d-inline-flex">
                          <input type="checkbox" />
                          <span className="checkmark-s2 back-white"></span>
                          <span>{item.Title}</span>
                        </label> */}
                  </div>
                ))
              ) : (
                <p></p>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default RTLAccordian;
