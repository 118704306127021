import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";

import "./header-drawer.css";
import menuIcon from "../../images/header/menu.svg";
import regBiz from "../../images/header/business register.svg";
import loginBiz from "../../images/header/login business.svg";
import logo from "../../images/home-body/logo gishniz.png";
import logo2 from "../../images/home-body/logogishniz.svg";
import closeImg from "../../images/header/close.svg";
// config
import configData from "../../config/config.json";

// styles
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: `10px`,
  //   borderBottom: `1px solid white`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: "44px",
  height: "44px",
  padding: "0 23px 0 16px",
  //   borderTop: "1px solid #8ef0c0",
  backgroundColor: "#fff",
  flexDirection: "row",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #8ef0c0",
  margin: "0 29px",
  padding: "10px 0",
}));

export default function TemporaryDrawer() {
  // drawer
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  //   drawer

  // accordian
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel, open) => (event, newExpanded) => {
    if (open) {
      setExpanded(newExpanded ? panel : false);
    }
  };
  // accordian

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            onClick={toggleDrawer(anchor, true)}
            className="p-0 w-fit mb-n0 ms-2 d-flex"
          >
            <object
              data={menuIcon}
              className="drawer-menu-icon"
              alt="menu"
              type="image/svg+xml"
              onClick={toggleDrawer(anchor, true)}
            ></object>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="header-drawer z1300"
          >
            <div className="drawer-body">
              <p> </p>

              <Link className="" to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
              <IconButton
                className="p-0 w-auto"
                onClick={toggleDrawer(anchor, false)}
              >
                <img
                  className="header-close-img m-0"
                  src={closeImg}
                  alt="close icon"
                />
              </IconButton>
            </div>
            <hr className="m-0" />

            <div>
              <div>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1", true)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-rtl-content"
                    id="panel1d-rtl-header"
                  >
                    <Typography>Gishniz for Businesses</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <a
                        // href={`${configData.BIZ_URL}/choose-owner`}
                        href={`${configData.BIZ_URL}/signup`}
                        target="_blank"
                        className="result-filter-item fs-12 color-005589"
                      >
                        <ListItem button>
                          <object
                            data={regBiz}
                            className="box_show_info_biz_img"
                            alt="regBiz"
                            type="image/svg+xml"
                          ></object>
                          Add a business
                        </ListItem>
                      </a>
                      <a
                        href={`${configData.BIZ_URL}/login`}
                        className="result-filter-item fs-12 color-005589"
                        target="_blank"
                      >
                        <ListItem button>
                          <object
                            data={loginBiz}
                            className="box_show_info_biz_img"
                            alt="regBiz"
                            type="image/svg+xml"
                          ></object>
                          Log in
                        </ListItem>
                      </a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr className="m-0" />
            {/*comment for now thre is not blog */}
            {/* <div className="header-drawer-item">
              <a
                href={configData.BLOG_URL}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                Blog{" "}
              </a>
            </div>
            <hr className="m-0" /> */}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
