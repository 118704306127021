import React, { useState, useEffect, useContext } from "react";
import { MDBContainer, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import { useHistory } from "react-router-dom";
//
import { LangIdContext, CookieContext } from "../App";
import { validate } from "./login/validate";
// components
import MetaTagsEndUserPage from "../components/meta-tags-seo/MetaTagsEndUserPage";
import Footer from "../components/footer/Footer";
import Header2 from "../components/header/Header2";
import Headermobile from "../components/header-mobile/Headermobile";
import Cardscommets from "../components/comments/Cardscommets";
import RateIcon2 from "../components/rate-icon/RateIcon2";
import AlertSuccess from "../components/Dashbord/AlertSuccess/AlertSuccess";
import "../styles/css/business-write-comment-page.css";
// config
import configData from "../config/config.json";

const BusinessWriteComment = (props) => {
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  // // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const history = useHistory();

  // data
  const [data, setData] = useState({
    onvan: "",
    nazar: "",
    ReviewId: 0,
    Rate: 0,
    status: 1,
  });
  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});
  {
    /* فعلا امتیاز اجباری نیست */
  }
  // const [rateError, setRateError] = useState(false);
  const [bodyError, setBodyError] = useState(false);

  const [com, setCom] = useState([
    // {
    //   Fullname: "Azar",
    //   Rate: "4",
    //   Agree: 3,
    //   CommentDate: "write on 23 july 2022, in, vancouver",
    //   length: 267,
    //   CommentBody:
    //     "It's a nice, sturdy little stand for a headset. The metal is actually stronger than I expected it to be, and there's rubber where it sits on the desk and where the headband of the headset rests on the stand, which is nice and keeps things where they're supposed to be. Easy to assemble and looks nice.",
    //   PhotoPath:
    //     "https://blog.gishniz.link/content/2023/03/27/IMG638155234401617903.jpg",
    // },
    // {
    //   Fullname: "Azar",
    //   Rate: "4",
    //   Agree: 0,
    //   CommentDate: "write on 23 july 2022, in, vancouver",
    //   length: 267,
    //   CommentBody:
    //     "It's a nice, sturdy little stand for a headset. The metal is actually stronger than I expected it to be, and there's rubber where it sits on the desk.",
    //   PhotoPath:
    //     "https://blog.gishniz.link/content/2023/03/27/IMG638155234401617903.jpg",
    // },
    // {
    //   Fullname: "Azar",
    //   Rate: "4",
    //   Agree: 0,
    //   CommentDate: "write on 23 july 2022, in, vancouver",
    //   length: 267,
    //   CommentBody:
    //     "It's a nice, sturdy little stand for a headset. The metal is actually stronger than I expected it to be, and there's rubber where it sits on the desk.",
    //   PhotoPath:
    //     "https://blog.gishniz.link/content/2023/03/27/IMG638155234401617903.jpg",
    // },
    // {
    //   Fullname: "Azarr",
    //   Rate: "2",
    //   Agree: 3,
    //   CommentDate: "write on 23 july 2022, in, vancouver",
    //   length: 267,
    //   CommentBody:
    //     "It's a nice, sturdy little stand for a headset. The metal is actually stronger than I expected it to be, and there's rubber where it sits on the desk.",
    //   PhotoPath:
    //     "https://blog.gishniz.link/content/2023/03/27/IMG638155234401617903.jpg",
    // },
  ]);
  const [bizTitle, setBizTitle] = useState("");

  const [disable, setDisable] = useState(false);
  const [apiError, setApiError] = useState([]);

  // validate
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  const inputChangeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setErrors(validate(data, "writeComment"));
  }, [data, touched]);

  {
    /* فعلا امتیاز اجباری نیست */
  }
  // useEffect(() => {
  //   if (data.Rate !== 0) {
  //     setRateError(false);
  //   }
  // }, [data.Rate]);

  const submitHandler = (event) => {
    event.preventDefault();
    setApiError([]);
    setBodyError(false);

    if (
      (data.nazar === "" && data.onvan !== "") ||
      (data.nazar !== "" && data.onvan === "")
    ) {
      setBodyError(true);
    } else {
      if (data.Rate !== 0 || data.nazar !== "" || data.onvan !== "") {
        setDisable(false);

        // if (!Object.keys(errors).length) {

        setDisable(true);

        {
          /* فعلا امتیاز اجباری نیست */
        }
        // setRateError(false);

        // success
        const dataPost = {
          ReviewId: data.ReviewId,
          bId: props.match.params.id,
          rate: data.Rate,
          title: data.onvan,
          body: data.nazar,
        };

        axios
          .post(configData.API_DOMAIN + "/api/v1/Businesses/review", dataPost, {
            headers: header,
          })
          .then((response) => {
            setDisable(false);
            if (response.data.isSuccess) {
              alertHandeler();
              setTimeout(() => {
                history.push(`/biz/${parseInt(props.match.params.id)}`);
              }, 3000);
            } else {
              // isSuccess = false
              setApiError(response.data.message);
            }
          })
          .catch((error) => {
            // alert(error);
          });
        // } else {
        //   setTouched({
        //     // onvan: true,
        //     // nazar: true,
        //   });
        // }
      } else {
        {
          /* فعلا امتیاز اجباری نیست */
        }
        // setRateError(true);
      }
    }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
    BizId: parseInt(props.match.params.id),
    MemId: getCookie("MemId"),
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };
  useEffect(() => {
    axios
      .get(
        configData.API_DOMAIN +
          `/api/v1/Businesses/${parseInt(props.match.params.id)}/member-review`,
        {
          headers: header,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          if (
            response.data.data.ReviewId != 0 &&
            response.data.data.ReviewId != null
          ) {
            // props.history.push({
            // pathname: `/biz/${props.match.params.id}`,
            // });

            var info = response.data.data;
            var onvan = info.commentTitle == null ? "" : info.commentTitle;
            var nazar = info.commentBody == null ? "" : info.commentBody;
            setData({
              onvan: onvan,
              nazar: nazar,
              ReviewId: info.ReviewId,
              Rate: info.rate,
              status: info.status,
            });
            setBizTitle(info.bizTitle);
          } else {
            setBizTitle(response.data.data.bizTitle);
          }
        }
      })
      .catch((error) => {
        // alert(error);
      });

    axios
      .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
        headers: {
          ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
          LangId: langIdState,
          Page: "write-review",
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data);
        }
      })
      .catch((error) => {
        // alert(error);
      });
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });

    axios
      .get(
        configData.API_DOMAIN +
          `/api/v1/Businesses/${props.match.params.id}/reviews`,
        {
          headers: header,
        }
      )
      .then((response) => {
        let comments = response.data.data.slice(0, 3);
        setCom(comments);
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  useEffect(() => {
    if (cookieState == "") {
      props.history.push({
        pathname: `/login?`,
        pathname: `/biz/${props.match.params.id}`,
      });
    }
  });

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} noindex={true} /> : ""}

      <div className="d-md-block d-none">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-md-none d-block">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res"></div>
      </div>
      <MDBContainer>
        <MDBRow>
          <div className="col-12 col-md-8  ">
            <div className="writecomment-div">
              <div className="writecomment-title1">
                <h5 className="">Write a review about {bizTitle}</h5>
                {data.status == 0 && (
                  <p>
                    This review is awaiting approval by the Admin to be
                    displayed on the website.
                  </p>
                )}
                {data.status == 2 && (
                  <p>
                    This view has been edited and it is awaiting approval by the
                    Admin to be displayed on the website.
                  </p>
                )}
              </div>
              <hr className="custom-hr2" />
              <div className="writecomment-rate ">
                <h5 className="writecomment-text1 d-none d-md-block">Rate</h5>
                <RateIcon2 data={data} setData={setData} />
                <h5 className="writecomment-text1 d-md-none">Rate</h5>
              </div>
              {/* فعلا امتیاز اجباری نیست */}
              {/* {rateError && (
                <span className="input-error mt-1 ml-md-30">
                  Please rate the business.
                </span>
              )} */}

              {/* <p className="writecomment-text2">دیدگاه خود را شرح دهید!</p> */}
              <label
                htmlFor="writecomment-title"
                className="writecomment-input1-label pt-5 pt-md-4"
              >
                Title this review
              </label>

              <input
                className={
                  errors.onvan && touched.onvan
                    ? "writecomment-input1 rb-error"
                    : "writecomment-input1 "
                }
                type="text"
                // name="writecomment-title"
                // className="writecomment-input1"
                name="onvan"
                value={data.onvan}
                onChange={inputChangeHandler}
              />
              <div className="position-relative">
                {errors.onvan && touched.onvan && (
                  <span className="input-error input-error-con me-1 input-error-wc">
                    {errors.onvan}
                  </span>
                )}
              </div>

              <label
                htmlFor="writecomment-text"
                className="writecomment-input1-label"
              >
                Write your review
              </label>

              <textarea
                className={
                  errors.nazar && touched.nazar
                    ? "writecomment-input2 rb-error"
                    : "writecomment-input2 "
                }
                type="text"
                name="nazar"
                value={data.nazar}
                onChange={inputChangeHandler}
                onBlur={() => {
                  // console.log("here");
                }}
                id=""
              ></textarea>
              <div className="position-relative">
                {errors.nazar && touched.nazar && (
                  <span className="input-error input-error-con me-1 input-error-wc2">
                    {errors.nazar}
                  </span>
                )}
                {apiError.map((item) => (
                  <span className="input-error input-error-con me-1 input-error-wc2">
                    {item}
                  </span>
                ))}
                {bodyError && (
                  <span className="input-error input-error-con me-1 input-error-wc2">
                    Please complete title and review fields.
                  </span>
                )}
              </div>

              <div className="centerr">
                {data.ReviewId !== 0 && data.ReviewId !== null ? (
                  <MDBBtn
                    className="writecomment-sabt hov-shadow-btn"
                    disabled={disable}
                    onClick={submitHandler}
                  >
                    Edit Review
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    className="writecomment-sabt hov-shadow-btn"
                    disabled={disable}
                    onClick={submitHandler}
                  >
                    Save Review
                  </MDBBtn>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 d-md-block d-none ps-1">
            {com.length > 0 && (
              <div className="writecomment-div2">
                <h4 className="writecomment-title2">
                  Latest comments about {bizTitle}
                </h4>
                {com.map((item) => (
                  <Cardscommets
                    key={item.id}
                    rate={item.rate}
                    likes={item.agree}
                    name={item.fullname}
                    date={item.commentDate}
                    length={267}
                    title={item.commentTitle}
                    text={item.commentBody}
                    img={item.photoPath}
                  />
                ))}
              </div>
            )}
          </div>
        </MDBRow>
      </MDBContainer>
      {/* footer */}
      <Footer data={footerData} dataCome={ApiDataCome.footer} />

      <AlertSuccess
        showAlert={showAlert}
        text={"Your information has been successfully saved."}
      />
    </>
  );
};

export default BusinessWriteComment;
