import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import "./loading-modal.css";
import searchIcon from "../../images/result/search.png";
import logo_icon from "../../images/logo 50.50.svg";

const LoadingModal = (props) => {
  const [staticModal, setStaticModal] = useState(true);

  const toggleShow = () => setStaticModal(!staticModal);

  return (
    <>
      {/* <MDBBtn onClick={toggleShow}>Launch static backdrop modal</MDBBtn> */}

      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
        id="loading-modal"
      >
        <MDBModalDialog centered>
          <MDBModalContent className="position-relative">
            <MDBModalBody className="loading-modal-body">
              <div className="loader-bg"></div>

              <svg
                class="spinner"
                width="40px"
                height="40px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  class="path"
                  fill="none"
                  strokeWidth="6"
                  strokeLinecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                ></circle>
              </svg>
            </MDBModalBody>
            <MDBModalFooter className="loading-modal-footer">
              Please wait, operation in progress...
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default LoadingModal;
