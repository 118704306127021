import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./NotFound.css";

// images
import main from "../../images/NotFound/Artboard 1.svg";
import right from "../../images/NotFound/Artboard 2.svg";
import left from "../../images/NotFound/Artboard 3.svg";

const ErrorPage = () => {
  return (
    <>
     <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <h3 className="header_not_found mb-md-3">
              We could not find this page !
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" md="7" lg="5" order="md-2">
            <div className="mt-30notfound">
              <img src={main} alt="" className="img_notfound" />
              <img className="img_right_notfound" src={right} alt="" />
              <img className="img_left_notfound" src={left} alt="" />
            </div>
          </MDBCol>
          <MDBCol size="12" md="5" lg="7" order="md-1">
            {/* <h3 className="header_not_found2">برو به:</h3> */}

            <ul className="list_notfound">
              <div className="d-flex d-md-block justify-content-center">
                <li>
                  <a href={"/"} className="button_not_found hov-shadow-btn">
                    Home
                  </a>
                </li>
                {/* <li>
                  <a href={"/"} className="button_not_found hov-shadow-btn">
                    Blog
                  </a>
                </li> */}
              </div>
              <div className="d-flex d-md-block justify-content-center">
                <li>
                  <a href={"/"} className="button_not_found hov-shadow-btn">
                    Attoreny
                  </a>
                </li>
                <li>
                  <a href={"/"} className="button_not_found hov-shadow-btn">
                    Real state
                  </a>
                </li>
              </div>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ErrorPage;
