import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./Headermobile.css";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Modal from "./Modal";
import imageLogin from "../../images/login img/user gray.png";
// import imageLoginwhite from "../../images/login img/user.png";
import imageLogout from "../../images/login img/log-out gray.png";
// import nextLogin from "../../images/login img/play (1).png";
import account from "../../images/login img/account.png";
import HeaderDrawer from "./HeaderDrawer";

import imageLoginwhite from "../../images/login img/user.svg";
import nextLogin from "../../images/login img/play (1).svg";

import { DomainContext, LangIdContext, CookieContext } from "../../App";

const Headermobile = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const [data, setData] = React.useState({
    headerLogo: { link: "", title: "" },
    categoriesForSearch: [
      {
        cId: "",
        title: "",
        shortTitle: "",
        icon: "",
      },
    ],
    coverPhoto: "",
    categoriesOnHeader: [{}, {}, {}, {}],
    // CId: "", Icon: "", ShortTitle: "", Title: ""
    categoriesOnHeaderShort: [{}, {}, {}, {}, {}, {}, {}],
    categoriesOnFocus: [],
  });

  const [logo, setLogo] = useState({ link: "", title: "" });

  useEffect(() => {
    setLogo(props.data.headerLogo);
  }, [props.data]);

  const [showmenu, setshowmenu] = useState(false);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // delete all cookies logggggg outttt
  function deleteAllCookies() {
    localStorage.clear();
    document.cookie = "MemId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    setCookieState("");

    setshowmenu(!showmenu);
  }

  const handleShow = () => {
    setshowmenu((prev) => !prev);
  };

  const handleShowAway = () => {
    setshowmenu(false);
  };

  const lanHandler = (event) => {
    if (window.location.pathname == "/search") {
      window.location.href = `https://gishniz.link${
        window.location.pathname + window.location.search
      }`;
      // window.location.href = `https://gishniz.app${window.location.pathname + window.location.search}`;
    } else {
      // if (getCookie("_gapp_Locale") == "") {
      window.location.href = `https://gishniz.link${window.location.pathname}`;
      // window.location.href = `https://gishniz.app${window.location.pathname}`;
      // }
    }
  };
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      <div className="Rectangle-1">
        {/* <div className="req-header"></div> */}
        <MDBContainer fluid>
          <MDBRow className="pt-18 align-items-center">
            <MDBCol size="4" sm="4" className="d-flex">
              {/* <MDBBtn className="btn-blog shadow-off hov-shadow-btn"> */}
              {/* <a
                href={configData.BLOG_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-blog shadow-off hov-shadow-btn "
              >
                Blog
              </a> */}
              {/* </MDBBtn> */}
              <HeaderDrawer />
              <MDBBtn className="chamge-lan-btn" onClick={lanHandler}>
                فا
              </MDBBtn>
            </MDBCol>
            <MDBCol size="4" sm="4" className="centerr">
              <Link to="/">
                <img src={logo.link} alt={logo.title} className="logo" />
              </Link>
            </MDBCol>
            <MDBCol size="4" sm="4" className="rightt">
              {/* <MDBBtn className="btn-login shadow-off hov-shadow-btn"> */}

              <ClickAwayListener onClickAway={handleShowAway}>
                <Box>
                  {getCookie("MemId") &&
                  JSON.parse(localStorage.getItem("MemberDetail")) ? (
                    <>
                      <div
                        className={showmenu ? "green_login" : " green_login"}
                        onClick={handleShow}
                      >
                        {/* <img
                          className="imageLogin_success_header_mobile"
                          src={imageLoginwhite}
                          alt=""
                        />

                        <img
                          className={
                            showmenu
                              ? "nextLogin_mobile nextLogin-rotate"
                              : "nextLogin_mobile nextLogin-rotateback"
                          }
                          src={nextLogin}
                          alt=""
                        /> */}
                        <object
                          className="imageLogin_success_header_mobile"
                          data={imageLoginwhite}
                          alt=""
                          type="image/svg+xml"
                          onClick={handleShow}
                        ></object>
                        <object
                          className={
                            showmenu
                              ? "nextLogin_mobile nextLogin-rotate"
                              : "nextLogin_mobile nextLogin-rotateback"
                          }
                          data={nextLogin}
                          alt=""
                          type="image/svg+xml"
                          onClick={handleShow}
                        ></object>
                      </div>
                      {showmenu && (
                        <List
                          onMouseOver={() => setshowmenu(true)}
                          onMouseOut={() => setshowmenu(false)}
                          className="box_show_info_userlogin fix_header_mobile_login"
                          component="nav"
                          aria-label="mailbox folders"
                        >
                          <ListItem button>
                            <img
                              className="img20x20"
                              // src={imageLogin}
                              src={
                                JSON.parse(localStorage.getItem("MemberDetail"))
                                  .photo
                              }
                              alt="icon"
                            />
                            {
                              JSON.parse(localStorage.getItem("MemberDetail"))
                                .firstname
                            }{" "}
                            {
                              JSON.parse(localStorage.getItem("MemberDetail"))
                                .lastname
                            }
                          </ListItem>
                          <Divider />
                          <ListItem button>
                            <Link to={"/members/profile"}>
                              <img className="img12x12" src={account} alt="" />
                              Profile
                            </Link>
                          </ListItem>
                          <Divider />
                          <ListItem onClick={deleteAllCookies} button>
                            <img
                              className="img20x20"
                              src={imageLogout}
                              alt=""
                            />
                            Log out
                          </ListItem>
                        </List>
                      )}
                    </>
                  ) : (
                    <Link
                      to="/login"
                      target=""
                      rel="noopener noreferrer"
                      className="btn-login shadow-off hov-shadow-btn"
                    >
                      Sign in
                    </Link>
                  )}
                </Box>
              </ClickAwayListener>
              {/* </MDBBtn> */}
            </MDBCol>
          </MDBRow>

          <MDBRow className="mx-175 position-relative">
            <Modal data={data} data2={props.data.categoriesOnFocus} />
          </MDBRow>
        </MDBContainer>
      </div>
      {/* <hr className="headermobile-hr"/> */}
    </>
  );
};

export default Headermobile;
