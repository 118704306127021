import React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "3.5px 4.75px",
    color: "#969494",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#33e0a0",
        opacity: 1,
        border: "solid 1px #33e0a0",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#969494",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#969494",
    border: " 2px solid #969494",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SwitchFilter = (props) => {
  const { setVal } = props;

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    // console.log(checked);
    setVal(event.target.checked);
  };
  return (
    <FormGroup>
      <FormControlLabel
        className="mx-0"
        checked={checked}
        onChange={handleChange}
        control={<IOSSwitch sx={{ m: 1 }} />}
      />
    </FormGroup>
  );
};

export default SwitchFilter;
