// import React, { useState } from "react";
// import Alert from "@mui/material/Alert";
// import {
//   MDBCard,
//   MDBCardTitle,
//   MDBCardText,
//   MDBCardBody,
//   MDBCardImage,
//   MDBRow,
//   MDBCol,
//   MDBBtn,
//   MDBModal,
//   MDBModalDialog,
//   MDBModalContent,
// } from "mdb-react-ui-kit";
// import { Link } from "react-router-dom";
// import Main from "../Main/Main";
// import RateIcon from "../rate-icon/RateIcon";
// import Delete from "../../../images/register-business/delete.svg";
import edit from "../../../images/end user/edit 0.svg";
// import picComments from "../../../images/end user/comments.svg";
// import xx from "../../../images/end user/postbox.svg";

// const ReqCard = (props) => {
//   const { dataCom } = props;
//   const [data, setdata] = useState([
//     // {
//     //   id: 1,
//     //   title: "رستوران گیشنیز",
//     //   desc1: "شما به این کسب‌وکار امتیاز 5 داده‌اید",
//     //   time: "نوشته شده در ونکوور در تاریخ 23 جولای 2022",
//     //   text: "It's a nice, sturdy little stand for a headset. The metal isactually stronger than I expected it to be, and there's rubbe where it sits on the desk and where the headband of the headset rests on the stand, which is",
//     // },
//     // {
//     //   id: 2,
//     //   title: "رستوران گیشنیز",
//     //   desc1: "شما به این کسب‌وکار امتیاز 5 داده‌اید",
//     //   time: "نوشته شده در ونکوور در تاریخ 23 جولای 2022",
//     //   text: "It's a nice, sturdy little stand for a headset. The metal isactually stronger than I expected it to be, and there's rubbe where it sits on the desk and where the headband of the headset rests on the stand, which is",
//     // },
//     // {
//     //   id: 3,
//     //   title: "رستوران گیشنیز",
//     //   desc1: "شما به این کسب‌وکار امتیاز 5 داده‌اید",
//     //   time: "نوشته شده در ونکوور در تاریخ 23 جولای 2022",
//     //   text: "It's a nice, sturdy little stand for a headset. The metal isactually stronger than I expected it to be, and there's rubbe where it sits on the desk and where the headband of the headset rests on the stand, which is",
//     // },
//     // {
//     //   id: 4,
//     //   title: "رستوران گیشنیز",
//     //   desc1: "شما به این کسب‌وکار امتیاز 5 داده‌اید",
//     //   time: "نوشته شده در ونکوور در تاریخ 23 جولای 2022",
//     //   text: "It's a nice, sturdy little stand for a headset. The metal isactually stronger than I expected it to be, and there's rubbe where it sits on the desk and where the headband of the headset rests on the stand, which is",
//     // },
//   ]);
//   const [centredModalDelete, setCentredModalDelete] = useState(false);
//   const toggleShowDelete = () => setCentredModalDelete(!centredModalDelete);
//   const [idDelete2, setIdDelete2] = useState(null);
//   const [showAlert, setshowAlert] = useState(false);

//   const setIDforDelete2 = (idDelete2) => {
//     setIdDelete2(idDelete2);
//   };
//   const deleteHandeler2 = () => {
//     setdata(dataCom.filter((item) => item.Id !== idDelete2));
//   };

//   const alertHandeler2 = () => {
//     setshowAlert(true);
//     setTimeout(() => {
//       setshowAlert(false);
//     }, 5000);
//   };
//   return (
//     <>
//       <div className="costum_option_center">
//         {dataCom.length > 0 ? (
//           dataCom.map((item) => (
//             <>
//               <MDBCard
//                 className="color-101010 px-auto card-border body-comment-profile d-block"
//                 style={{ maxWidth: "" }}
//                 key={item.Id}
//               >
//                 <MDBRow className="g-0  ">
//                   <MDBCol className="col-12 col-md-4 col-md-4 col-lg-3">
//                     <MDBCardImage
//                       className="img-comment-profile"
//                       src={item.PhotoPath}
//                       alt="...."
//                       fluid
//                     />
//                     <div className="mask dash-img-mask"></div>
//                   </MDBCol>
//                   <MDBCol className="col-12 col-md-8 col-md-8 col-lg-9">
//                     <MDBCardBody className="me-md-2 mt-2 pt-md-3 pt-1 p-0 px-4 px-md-0">
//                       <MDBCardTitle className="fw-bold fs-15 mb-1 fs-xs-15 f14 ">
//                         <MDBRow>
//                           <MDBCol className="col-md-6 col-12 ">
//                             <span className="first-title-profile fw-bold d-md-block title-option-res">
//                               <span className="d-none d-md-inline">
//                                 دیدگاه شما برای
//                               </span>{" "}
//                               {item.CommentTitle}
//                             </span>
//                             <div className="text-start mb-1 d-md-none first-title-profile-res-div">
//                               <span className="first-title-profile fw-bold first-title-profile2 fs-16-pro">
//                                 عالی
//                               </span>
//                               <RateIcon
//                                 className="ml20"
//                                 cardrate={parseFloat(item.Rate)}
//                                 isRec={1}
//                                 isAdd={0}
//                                 dir="ltr "
//                               />
//                             </div>
//                           </MDBCol>
//                           <MDBCol className="col-md-6 col-12 text-md-start text-center">
//                             <span className="first-title-profile fw-bold">
//                               {item.desc1}
//                             </span>
//                           </MDBCol>
//                         </MDBRow>
//                       </MDBCardTitle>
//                       <MDBCardText className=" mb-md-2 mb-0 fs-14 fs-xs-14 f12 d-none d-md-block">
//                         <MDBRow className="mt-2">
//                           <MDBCol className="col-md-6 col-0"></MDBCol>
//                           <MDBCol className="col-md-6 col-12 text-start">
//                             <span className="first-title-profile fw-bold first-title-profile2 fs-16-pro">
//                               عالی
//                             </span>
//                             <RateIcon
//                               className="ml20"
//                               cardrate={parseFloat(item.Rate)}
//                               isRec={1}
//                               isAdd={0}
//                               dir="ltr "
//                             />
//                           </MDBCol>
//                         </MDBRow>
//                       </MDBCardText>
//                       <MDBCardText className="pt-md-1 pt-2 fs-13 fs-xs-13 color-101010ab f12">
//                         <div>
//                           <p className="first-title-profile ">
//                             {item.Fullname}
//                           </p>
//                           <small className="time-title-profile d-none d-md-block">
//                             {item.CommentDate}
//                           </small>
//                         </div>
//                         <MDBRow className="align-items-end">
//                           <MDBCol className="col-md-6 col-10 p-0">
//                             <small className="time-title-profile d-block d-md-none ms-0 pe-2 me-1">
//                               {item.CommentDate}
//                             </small>
//                           </MDBCol>
//                           <MDBCol className="col-md-6 col-2 text-start">
//                             <span className="proofile_tooltip proofile_tooltip2">
//                               <img
//                                 src={Delete}
//                                 alt="Delete"
//                                 className="img_delete mr10 ms-0  ms-md-4"
//                                 onClick={() => {
//                                   toggleShowDelete();
//                                   setIDforDelete2(item.Id);
//                                 }}
//                               />
//                               <span className="tooltiptext_option f12">
//                                 حذف نظر
//                               </span>
//                             </span>

//                             {/* <span className="proofile_tooltip">
//                                   <img
//                                     src={edit}
//                                     alt="edit"
//                                     className="img_edit"
//                                   />
//                                   <span className="tooltiptext_edite f12">
//                                     ویرایش نظر
//                                   </span>
//                                 </span> */}
//                           </MDBCol>
//                         </MDBRow>
//                       </MDBCardText>
//                       <MDBCardText className=""></MDBCardText>
//                     </MDBCardBody>
//                   </MDBCol>
//                 </MDBRow>
//               </MDBCard>
//             </>
//           ))
//         ) : (
//           <>
//             <div className="d-flex justify-content-center align-items-center mt-150">
//               <img
//                 src={picComments}
//                 alt="no comments"
//                 className="img_no_comments"
//               />
//             </div>
//             <div className="d-flex justify-content-center align-items-center">
//               <p className="text_no_image">هنوز دیدگاهی ثبت نکردین</p>
//             </div>
//           </>
//         )}
//       </div>
//       {/* alert delete */}
//       {showAlert ? (
//         <div className={"succsess_delete"}>
//           <Alert
//             severity="success"
//             icon={false}
//             className={"alert_succsess_delete"}
//           >
//             <p className="text_alert_delete">
//               این دیدگاه از لیست دیدگاه‌‌هایتان حذف شد.
//             </p>
//           </Alert>
//         </div>
//       ) : (
//         ""
//       )}

//       {/* modal delete */}
//       <MDBModal
//         tabIndex="-1"
//         show={centredModalDelete}
//         setShow={setCentredModalDelete}
//         id={idDelete2}
//       >
//         <MDBModalDialog centered className="justify-content-center">
//           <MDBModalContent className="costum_modal">
//             <div className="reletive_profile">
//               <MDBBtn
//                 className="btn-close close_modal"
//                 color="none"
//                 onClick={toggleShowDelete}
//               ></MDBBtn>
//             </div>
//             <div>
//               <p className="text_modal px-3">
//                 آیا می‌خواهید این دیدگاه از لیست دیدگاه‌‌هایتان پاک شود؟
// Do you want this comment to be deleted from your comments?
//               </p>
//             </div>
//             <div className="a_s_c">
//               <button
//                 onClick={() => {
//                   toggleShowDelete();
//                   deleteHandeler2();
//                   alertHandeler2();
//                 }}
//                 className="button_modal"
//               >
//                 حذف شود
//               </button>
//             </div>
//           </MDBModalContent>
//         </MDBModalDialog>
//       </MDBModal>
//     </>
//   );
// };

// export default ReqCard;

import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
} from "mdb-react-ui-kit";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import "./comments.css";
import RateIcon from "../../Dashbord/rate-icon/RateIcon";
import DeleteImg from "../../../images/register-business/delete.svg";
import picComments from "../../../images/end user/comments.svg";

const CommentBusiness = (props) => {
  const { dataCom, comes } = props;
  const [centredModalDelete, setCentredModalDelete] = useState(false);
  const toggleShowDelete = () => setCentredModalDelete(!centredModalDelete);
  const [idDelete2, setIdDelete2] = useState(null);
  const [showAlert, setshowAlert] = useState(false);

  const setIDforDelete2 = (idDelete2) => {
    setIdDelete2(idDelete2);
  };
  const deleteHandeler2 = () => {
    setdata(dataCom.filter((item) => item.Id !== idDelete2));
  };

  const alertHandeler2 = () => {
    setshowAlert(true);
    setTimeout(() => {
      setshowAlert(false);
    }, 5000);
  };

  const [data, setdata] = useState([]);
  // states for modal
  const [centredModal, setCentredModal] = useState(false);
  const toggleShow = () => setCentredModal(!centredModal);
  const [idDelete, setIdDelete] = useState(null);
  const [modalData, setModalData] = useState({});

  const setIDforDelete = (idDelete, item) => {
    setIdDelete(idDelete);
    setModalData(item);
  };

  const deleteHandeler = () => {
    setdata(data.filter((item) => item.id !== idDelete));
  };

  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      setshowAlert(false);
    }, 5000);
  };

  useEffect(() => {
    setdata(dataCom);
  }, [dataCom]);

  return (
    <>
      <div className="costum_option_center t--0">
        {dataCom && dataCom.length > 0 ? (
          dataCom.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                toggleShow();
                setIDforDelete(item.id, item);
              }}
            >
              <MDBCard
                className="color-101010 px-auto card-border body-comment-business d-block"
                style={{ maxWidth: "" }}
              >
                <div className="g-0 d-block d-md-flex">
                  <div className="text-align-last-center">
                    {comes ? (
                      <MDBCardImage
                        className="img-comment-business"
                        src={item.photoPath}
                        alt="...."
                        fluid
                      />
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        className=" img-comment-business"
                        animation="wave"
                      />
                    )}

                    {/* <div className="mask dash-img-mask"></div> */}
                  </div>
                  <div className="d-contents">
                    <MDBCardBody className="mx-md-2 mt-2 pt-md-0 pt-1 p-0 px-4 px-md-0">
                      <MDBCardTitle className="fw-bold fs-15 mb-1 fs-xs-15 f14 ">
                        <MDBRow>
                          <MDBCol className="col-md-6 col-12 ">
                            {/* title  */}
                            {/* <span className="first-title-profile fw-bold d-md-block title-option-res color-warning">
                              {item.CommentTitle}
                            </span> */}
                            {/* <div className="text-start mb-1 d-md-none">
                              {comes ? (
                                <>
                                  <span className="first-title-profile fw-bold first-title-profile2 fs-16-pro">
                                    {item.RateTitle}
                                  </span>
                                  <RateIcon
                                    className="ml20"
                                    cardrate={parseFloat(item.Rate)}
                                    isRec={1}
                                    isAdd={0}
                                    dir="ltr "
                                  />
                                </>
                              ) : (
                                <>
                                  <Skeleton
                                    className=""
                                    width={60}
                                    height={30}
                                    animation="wave"
                                  />

                                  <Skeleton
                                    className=""
                                    width={95}
                                    height={30}
                                    animation="wave"
                                  />
                                </>
                              )}
                            </div> */}
                          </MDBCol>
                        </MDBRow>
                      </MDBCardTitle>
                      <MDBCardText className=" mb-md-2 mb-0 fs-14 fs-xs-14 f12  d-md-block">
                        <MDBRow className="mt-2">
                          {comes && (
                            <p className=" mb-2 fw-bold first-title-profile">
                              {item.commentTitle}
                            </p>
                          )}
                          <MDBCol className="col-md-6 col-12 text-end d-flex align-items-center">
                            {comes ? (
                              <>
                                <span className="first-title-profile fw-bold first-title-profile2 fs-16-pro me-2">
                                  {item.rateTitle}
                                </span>
                                <RateIcon
                                  className="ml20"
                                  cardrate={parseFloat(item.rate)}
                                  isRec={1}
                                  isAdd={0}
                                  dir="ltr "
                                />
                              </>
                            ) : (
                              <>
                                <Skeleton
                                  className="me-1"
                                  width={50}
                                  height={30}
                                  animation="wave"
                                />
                                <Skeleton
                                  className=""
                                  width={80}
                                  height={30}
                                  animation="wave"
                                />
                              </>
                            )}
                          </MDBCol>
                        </MDBRow>
                      </MDBCardText>
                      <MDBCardText className="pt-md-1 pt-2 fs-13 fs-xs-13 color-101010ab f12">
                        <div className=" first-title-profile">
                          {comes ? (
                            <p className=" mb-1 ">{item.commentBody}</p>
                          ) : (
                            <>
                              <Skeleton
                                className="d-block w-100"
                                variant="text"
                                sx={{ fontSize: "0.6rem" }}
                                animation="wave"
                              />
                              <Skeleton
                                className="d-block w-100 mb-2"
                                variant="text"
                                sx={{ fontSize: "0.6rem" }}
                                animation="wave"
                              />
                            </>
                          )}
                        </div>
                        {/* <MDBRow className="align-items-end"> */}
                        <MDBRow className="">
                          <MDBCol className="col-12">
                            {comes ? (
                              <>
                                <small className="time-title-profile  d-md-block mb-0">
                                  {/* {item.author} */}
                                  Written by {item.fullname} on{" "}
                                  {item.commentDate}
                                </small>
                                {item.status == 0 && (
                                  <small className="d-block mt-2 fs-12">
                                    This review is awaiting approval by the
                                    Admin to be displayed on the website.
                                  </small>
                                )}
                                {item.status == 2 && (
                                  <small className="d-block mt-2 fs-12">
                                    This view has been edited and it is awaiting
                                    approval by the Admin to be displayed on the
                                    website.
                                  </small>
                                )}
                              </>
                            ) : (
                              <>
                                {/* <Skeleton
                                className="d-block w-100 mb-2"
                                variant="text"
                                sx={{ fontSize: "0.6rem" }}
                                animation="wave"
                              /> */}
                              </>
                            )}
                          </MDBCol>
                          {/* ویرایش دیدگاه فعلا کامنت بشه */}
                          {/* <MDBCol className="col-md-3 col-1 text-end h-192">
                            {comes ? (
                              <span className="proofile_tooltip proofile_tooltip2">
                                <a
                                  onClick={() => {
                                    // toggleShow();
                                    // setIDforDelete(item.Id, item);
                                    // setCentredModal(false);
                                    // toggleShowDelete();
                                    // setIDforDelete2(item.Id);
                                  }}
                                >
                                  <img
                                    src={edit}
                                    alt="..."
                                    className="img_edit_com mr10 ms-0  ms-md-4 "
                                  />
                                </a>
                                <span className="tooltiptext_option f12">
                                  Edit comment
                                </span>
                              </span>
                            ) : (
                              // <span className="proofile_tooltip ms-3">
                              <Skeleton
                                variant="circular"
                                width={22}
                                height={22}
                                animation="wave"
                                className=" me-md-4 float-md-end"
                              >
                                <Avatar animation="wave" />
                              </Skeleton>
                              // </span>
                            )}
                          </MDBCol> */}
                        </MDBRow>
                      </MDBCardText>
                      <MDBCardText className=""></MDBCardText>
                    </MDBCardBody>
                  </div>
                </div>
              </MDBCard>
            </div>
          ))
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center mt-150">
              <img
                src={picComments}
                alt="no comments"
                className="img_no_comments "
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p className="text_no_image mb-2">
                You have not any registered a comment yet
              </p>
            </div>
          </>
        )}
        {/* modal delete */}

        {/* <MDBModal
          tabIndex="-1"
          show={centredModal}
          setShow={setCentredModal}
          id={idDelete}
        >
          <div className="d-flex align-items-center whole-modal ">
            <div className="side-modal d-md-block d-none"></div>
            <MDBModalDialog
              centered
              className="justify-content-center body-comment-business-dialog"
            >
              <MDBModalContent className="costum_modal-comment-business ">
                <div className="reletive_profile">
                  <MDBBtn
                    className="btn-close close_modal"
                    color="none"
                    onClick={toggleShow}
                  ></MDBBtn>
                </div>
              
                <MDBRow className="g-0">
                  <MDBCol className="col-12 col-md-1 col-md-1 col-lg-1 text-align-last-center">
                    <MDBCardImage
                      className="img-comment-business"
                      src={modalData.PhotoPath}
                      alt="...."
                      fluid
                    />
                    <div className="mask dash-img-mask"></div>
                  </MDBCol>
                  <MDBCol className="col-12 col-md-8 col-md-11 col-lg-11 ">
                    <MDBCardBody className="me-md-2 mt-2 pt-md-0 pt-1 p-0 px-4 px-md-0">
                      <MDBCardTitle className="fw-bold fs-15 mb-1 fs-xs-15 f14 ">
                        <MDBRow>
                          <MDBCol className="col-md-6 col-12 ">
                            <span className="first-title-profile fw-bold d-md-block title-option-res color-danger ">
                              {modalData.CommentTitle}
                            </span>
                            <div className="text-start mb-1 d-md-none">
                              <span className="first-title-profile fw-bold first-title-profile2 fs-16-pro">
                              {modalData.RateTitle}
                                عالی
                              </span>
                              <RateIcon
                                className="ml20"
                                cardrate={parseFloat(modalData.Rate)}
                                isRec={1}
                                isAdd={0}
                                dir="ltr "
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardTitle>
                      <MDBCardText className=" mb-md-2 mb-0 fs-14 fs-xs-14 f12 d-none d-md-block">
                        <MDBRow className="mt-2">
                          <MDBCol className="col-md-6 col-12 text-end d-flex align-items-center">
                            <span className="first-title-profile fw-bold first-title-profile2 fs-16-pro">
                              عالی
                            </span>
                            <RateIcon
                              className="ml20"
                              cardrate={parseFloat(modalData.Rate)}
                              isRec={1}
                              isAdd={0}
                              dir="ltr "
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCardText>
                      <MDBCardText className="pt-md-1 pt-2 fs-13 fs-xs-13 color-101010ab f12">
                        <div className="d-flex first-title-profile">
                          <p
                            className=" mb-1
                          "
                          >
                            {modalData.CommentBody}
                          </p>
                        </div>
                        <MDBRow className="align-items-end">
                          <MDBCol className="col-md-6 col-10">
                            <small className="time-title-profile  d-md-block mb-0">
                              {modalData.Fullname}
                              {modalData.CommentDate}
                            </small>
                          </MDBCol>
                          <MDBCol className="col-md-6 col-2 text-start h-192">
                            <span className="proofile_tooltip proofile_tooltip2">
                              <a
                                onClick={() => {
                                  // toggleShow();
                                  // setIDforDelete(modalData.Id, modalData);
                                  // setCentredModal(false);
                                  // toggleShowDelete();


                                  // setIDforDelete2(modalData.Id, modalData);
                                  // setCentredModalDelete(false);

                                  toggleShowDelete();
                                  setIDforDelete2(modalData.Id);

                                }}
                                // href="https://new.gishniz.link/business#comments"
                              >
                                <img
                                  src={DeleteImg}
                                  alt="..."
                                  className="img_delete mr10 ms-0 ms-md-2 "
                                />
                              </a>
                              <span className="tooltiptext_option f12">
                                حذف نظر
                              </span>
                            </span>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardText>
                      <MDBCardText className=""></MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBModalContent>
            </MDBModalDialog>
          </div>
        </MDBModal> */}

        {/* modal delete */}
        <MDBModal
          tabIndex="-1"
          show={centredModalDelete}
          setShow={setCentredModalDelete}
          id={idDelete2}
        >
          <MDBModalDialog centered className="justify-content-center">
            <MDBModalContent className="costum_modal">
              <div className="reletive_profile">
                <MDBBtn
                  className="btn-close close_modal"
                  color="none"
                  onClick={toggleShowDelete}
                ></MDBBtn>
              </div>
              <div>
                <p className="text_modal px-3">
                  {/* آیا می‌خواهید این دیدگاه از لیست دیدگاه‌‌هایتان پاک شود؟ */}
                  Do you want this comment to be deleted from your comments?
                </p>
              </div>
              <div className="a_s_c">
                <button
                  onClick={() => {
                    toggleShowDelete();
                    deleteHandeler2();
                    alertHandeler2();
                    toggleShow();
                  }}
                  className="button_modal"
                >
                  Delete
                </button>
              </div>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* alert delete */}
        {showAlert ? (
          <div className={"succsess_delete"}>
            <Alert
              severity="success"
              icon={false}
              className={"alert_succsess_delete"}
            >
              <p className="text_alert_delete">
                این دیدگاه از لیست دیدگاه‌‌هایتان حذف شد.
              </p>
            </Alert>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default CommentBusiness;
