import React, { useState, useEffect, useContext } from "react";
import { MDBRow } from "mdb-react-ui-kit";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
//
import { DomainContext, LangIdContext } from "../../../App";
// config
import configData from "../../../config/config.json";
//components
import MetaTagsEndUserPage from "../../../components/meta-tags-seo/MetaTagsEndUserPage";
import Sidbar from "../../../components/Dashbord/sidbar/Sidbar";
import Main from "../../../components/Dashbord/Main/Main";
import Header_mobile from "../../../components/Dashbord/Header_mobile/Header_mobile";
import AlertSuccess from "../../../components/Dashbord/AlertSuccess/AlertSuccess";
// style
import "../indexprofile.css";
// img
import iconn from "../../../images/next-left.png"; //////////////////////

const Profilefirst = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [apiCome, setApiCome] = useState(false);

  const [disable, setDisable] = useState(false);
  const [apiError, setApiError] = useState([]);

  const [gender, setgender] = useState("");
  const [gendersList, setGendersList] = useState([]);
  const [gValue, setGValue] = useState(null);
  const [gInputValue, setGInputValue] = useState("");
  const [countryList, setCountryList] = useState([]);

  const [cValue, setCValue] = useState(null);
  const [cInputValue, setCInputValue] = useState("");
  const [profileDetail, setProfileDetail] = useState({
    MId: "",
    FirstName: "",
    LastName: "",
    UserName: "",
    Email: "",
    Gender: 0,
    DefaultCountry: 0,
  });

  const inputChangeHandler = (event) => {
    setProfileDetail({
      ...profileDetail,
      [event.target.name]: event.target.value,
    });
  };

  const [showAlert, setshowAlert] = useState(false);

  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    // LangId: langIdState,
    LangId: getCookie("_gapp_LId"),
    MemId: getCookie("MemId"),
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    // LangId: langIdState,
    LangId: getCookie("_gapp_LId"),
    Page: "profile",
  };

  useEffect(() => {
    if (getCookie("MemId")) {
      axios
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });

      axios
        .get(configData.API_DOMAIN + "/api/v1/Members", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            let data = response.data.data;
            setProfileDetail({
              MId: data.mId,
              FirstName: data.firstName,
              LastName: data.lastName,
              UserName: data.UserName,
              Email: data.encryptedEmail,
              Gender: data.gender,
              DefaultCountry: data.defaultCountry,
            });

            let cid = data.defaultCountry;
            axios
              .get(configData.API_DOMAIN + "/api/v1/Countries", {
                headers: header,
              })
              .then((response) => {
                if (response.data.isSuccess) {
                  setCountryList(response.data.data);

                  if (cid != 0) {
                    let arr = response.data.data;
                    for (let index = 0; index < arr.length; index++) {
                      if (arr[index].id == cid) {
                        setCValue(arr[index]);
                      }
                    }
                  }
                  setApiCome(true);
                }
              })
              .catch((error) => {
                // alert(error);
              });

            var gid = data.gender;
            axios
              .get(configData.API_DOMAIN + "/api/v1/Genders", {
                headers: header,
              })
              .then((response) => {
                if (response.data.isSuccess) {
                  setGendersList(response.data.data);

                  if (gid != 0) {
                    let arr = response.data.data;
                    for (let index = 0; index < arr.length; index++) {
                      if (arr[index].gId == gid) {
                        setGValue(arr[index]);
                      }
                    }
                  }
                  setApiCome(true);
                }
              })
              .catch((error) => {
                // alert(error);
              });
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      props.history.push({
        pathname: "/login",
      });
    }
  }, []);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const submitHandler = (event) => {
    event.preventDefault();
    setDisable(true);
    setApiError([]);

    const dataPost = {
      firstname: profileDetail.FirstName,
      lastname: profileDetail.LastName,
      gender: profileDetail.Gender,
      defaultCountry: profileDetail.DefaultCountry,
    };

    axios
      .put(configData.API_DOMAIN + "/api/v1/Members", dataPost, {
        headers: header,
      })
      .then((response) => {
        setDisable(false);
        if (response.data.isSuccess) {
          alertHandeler();
        } else {
          setApiError(response.data.message);
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  return (
    <>
      {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}

      <Header_mobile />
      <div>
        <div className="margin-0 d-flex">
          <div className="border-left-dashbord d-none d-md-block">
            <Sidbar />
          </div>
          <div className="bg-main-dashbord">
            <Main lencomments={2}>
              <h5 className="header-main d-none d-md-block">
                Edit profile information
              </h5>
              <h5 className="header-main d-block d-md-none">
                Edit profile information
              </h5>

              {apiCome ? (
                <>
                  <div className="centerr  mt-3 mt-md-0">
                    <TextField
                      disabled
                      name="Email"
                      value={profileDetail.Email}
                      onChange={inputChangeHandler}
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      className={
                        "label-field-less5 custom-text-field custom-text-field-dash mb-0 dash-text-field"
                      }
                    />
                  </div>
                  <div className="centerr mt15">
                    <TextField
                      disabled
                      name="UserName"
                      value={profileDetail.UserName}
                      onChange={inputChangeHandler}
                      id="outlined-basic"
                      label="User Name"
                      variant="outlined"
                      className={
                        "label-field-less5 custom-text-field custom-text-field-dash mb-0 dash-text-field"
                      }
                    />
                  </div>

                  {/* inputs */}
                  <div className="row-profilefirst d-md-flex mx-auto mt15">
                    <TextField
                      name="FirstName"
                      value={profileDetail.FirstName}
                      onChange={inputChangeHandler}
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      className={
                        "label-field-less5 custom-text-field custom-text-field-dash mb-0 me-md-2 dash-text-field"
                      }
                    />
                    <div className="mt15"></div>

                    <TextField
                      name="LastName"
                      value={profileDetail.LastName}
                      onChange={inputChangeHandler}
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      className={
                        "label-field-less5 custom-text-field custom-text-field-dash mb-0 ms-md-2 dash-text-field"
                      }
                    />
                  </div>

                  {/* auto compelete */}
                  <div className="row-profilefirst d-md-flex mx-auto">
                    <div dir="ltr" className="mb-3 mb-md-0 me-md-2 width-100">
                      <div className="d-md-flex">
                        <div className=" auto10 width-100 ">
                          <Autocomplete
                            value={gValue}
                            onChange={(event, newValue) => {
                              setGValue(newValue);
                              setProfileDetail({
                                ...profileDetail,
                                Gender: newValue.gId,
                              });
                            }}
                            inputValue={gInputValue}
                            onInputChange={(event, newInputValue) => {
                              setGInputValue(newInputValue);
                            }}
                            className="costum-autocompelette-profile"
                            popupIcon={
                              <img
                                src={iconn}
                                className="icon-pro-auto"
                                alt="category img"
                              />
                            }
                            disableClearable
                            disablePortal
                            openOnFocus={true}
                            options={gendersList}
                            getOptionLabel={(option) => option.title}
                            sx={{
                              fontFamily: "inherit",
                            }}
                            renderInput={(params) => (
                              <div className="d-flex width-100">
                                <TextField
                                  {...params}
                                  label="Gender"
                                  className="label-field-more "
                                >
                                  {" "}
                                </TextField>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div dir="ltr" className="mb-3 mb-md-0 ms-md-2 width-100">
                      <div className="d-md-flex">
                        <div className=" auto10 width-100">
                          <Autocomplete
                            // open={true}
                            value={cValue}
                            onChange={(event, newValue) => {
                              setCValue(newValue);
                              setProfileDetail({
                                ...profileDetail,
                                DefaultCountry: newValue.id,
                              });
                              // setData({
                              //   ...data,
                              //   categoryy: newValue.Title,
                              //   // objectValue: newValue,
                              // });
                            }}
                            inputValue={cInputValue}
                            onInputChange={(event, newInputValue) => {
                              setCInputValue(newInputValue);
                              // setData({ ...data, categoryy: newInputValue });
                            }}
                            className="costum-autocompelette-profile"
                            popupIcon={
                              <img
                                src={iconn}
                                className="icon-pro-auto"
                                alt="category img"
                              />
                            }
                            disableClearable
                            disablePortal
                            openOnFocus={true}
                            options={countryList}
                            getOptionLabel={(option) => option.title}
                            sx={{
                              fontFamily: "inherit",
                            }}
                            renderInput={(params) => (
                              <div className="d-flex width-100">
                                <TextField
                                  {...params}
                                  label="Default Country"
                                  className=" label-field-more12"
                                >
                                  {" "}
                                </TextField>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="centerr mt15 input-error-dash text-center">
                    {apiError.map((item) => item)}
                  </div>
                </>
              ) : (
                <>
                  <div className="centerr  mt-3 mt-md-0">
                    <Skeleton
                      className="profile-Skeleton"
                      variant="text"
                      animation="wave"
                    />
                  </div>
                  <div className="centerr mt-n13">
                    <Skeleton
                      className="profile-Skeleton"
                      variant="text"
                      animation="wave"
                    />
                  </div>
                  <div className="row-profilefirst d-md-flex mx-auto mt-n13">
                    <Skeleton
                      className="profile-Skeleton me-md-2"
                      variant="text"
                      animation="wave"
                    />
                    <div className="mt-n13"></div>
                    <Skeleton
                      className="profile-Skeleton ms-md-2"
                      variant="text"
                      animation="wave"
                    />
                  </div>
                  <div className="row-profilefirst d-md-flex mx-auto mt-n13">
                    <Skeleton
                      className="profile-Skeleton me-md-2"
                      variant="text"
                      animation="wave"
                    />
                    <div className="mt-n13"></div>
                    <Skeleton
                      className="profile-Skeleton ms-md-2"
                      variant="text"
                      animation="wave"
                    />
                  </div>
                  {/* <div className="row-profilefirst d-md-flex mx-auto">
                        <div
                          dir="ltr"
                          className="mb-3 mb-md-0 ms-md-2 width-100"
                        >
                          <div className="d-md-flex">
                            {" "}
                            <Skeleton
                              className="profile-Skeleton"
                              variant="text"
                              animation="wave"
                            />{" "}
                            <Skeleton
                              className="profile-Skeleton"
                              variant="text"
                              animation="wave"
                            />
                          </div>
                        </div>
                      </div> */}
                </>
              )}

              {/* save button */}
              <MDBRow className="row_saveButton">
                <div>
                  {apiCome ? (
                    <button
                      disabled={disable}
                      onClick={submitHandler}
                      className="saveButton saveButton1 "
                    >
                      Save
                    </button>
                  ) : (
                    <Skeleton
                      className="saveButton saveButton1 "
                      // width={100}
                      height={60}
                      variant="text"
                      animation="wave"
                    />
                  )}
                </div>
              </MDBRow>
            </Main>
          </div>
        </div>
      </div>
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={"Your information has been successfully saved."}
      />
    </>
  );
};

export default Profilefirst;
