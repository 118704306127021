import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Footer from "../../components/footer/Footer";
import Facebook from "./Facebook";
import Google from "./Google";
import axios from "axios";
import { useLocation } from "react-router-dom";
// import jwt_decode from "jwt-decode";
// config
import configData from "../../config/config.json";
//
import { validate } from "./validate";
import { DomainContext, LangIdContext, CookieContext } from "../../App";
import "../../styles/css/login-pages.css";
// componennts
import MetaTagsEndUserPage from "../../components/meta-tags-seo/MetaTagsEndUserPage";
// img
import closeIcon from "../../images/header/close.svg";
import logo from "../../images/home-body/logo gishniz.svg";
//
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = (props) => {
  const { state } = props.location;
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const [render, setRender] = useState(false);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [inputData, setInputData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [existErr, setExistErr] = useState();
  const [fillErr, setFillErr] = useState();
  const [serverErr, setServerErr] = useState();
  const [disable, setDisable] = useState(false);
  const [apiError, setApiError] = useState([]);

  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backUrl = queryParams.get("target");
  // console.log("backUrl");
  // console.log(backUrl);

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
  };

  useEffect(() => {
    setErrors(validate(inputData, "login"));
  }, [inputData, touched]);

  // const inputFocusHanlder = (event) => {
  //   setTouched({ ...touched, [event.target.name]: true });
  // };
  const [Errorrr, setErrorrr] = useState(false);
  const [ErrorrrRed, setErrorrrRed] = useState(false);

  const inputChangeHandler = (event) => {
    if (event.target.name === "isAccepted") {
      if (inputData.isAccepted) {
        setInputData({
          ...inputData,
          [event.target.name]: false,
        });
      } else {
        setInputData({
          ...inputData,
          [event.target.name]: true,
        });
      }
    } else {
      setErrorrrRed(false);
      setInputData({ ...inputData, [event.target.name]: [event.target.value] });
    }
  };

  const registerHandler = (event) => {
    // dellllllllll22222222
    if (state == "from-introduce") {
      props.history.push({
        pathname: "/register",
        state: state, // your data array of objects
      });
    } else {
      props.history.push({
        pathname: "/register",
        state: backUrl, // your data array of objects
      });
    }
  };
  // dellllllllll22222222
  useEffect(() => {
    if (state == "from-introduce") {
      // console.log("from moarefiii");
    } else {
      // console.log("noooooooooooo moarefiii");
    }
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    var cookieDay = 1;
    if (inputData.isAccepted) {
      cookieDay = 365;
    }
    // setErrorrrRed(false);

    // if (!Object.keys(errors).length) {
    // if (!Errorrr) {
    //   // success
    //   if (state) {
    //     props.history.push({
    //       pathname: "/introduce-business/introduce-business-thanks",
    //       state: state, // your data array of objects
    //     });
    //   }
    // } else {
    //   setErrorrrRed(true);
    // }

    // setErrorrrRed(true);
    setErrorrrRed(false);

    if (!Object.keys(errors).length) {
      // success
      setApiError([]);
      setExistErr(false);
      setServerErr(false);
      setFillErr(false);
      setDisable(true);

      axios
        .post(
          configData.API_DOMAIN + "/api/v1/Members/login",
          {
            email: inputData.username[0],
            password: inputData.password[0],
          },
          {
            headers: header,
          }
        )
        .then((response) => {
          setDisable(false);

          if (response.data.isSuccess) {
            setCookie("token", response.data.data.token.token, cookieDay);
            setCookie("MemId", response.data.data.memberDetail.mId, cookieDay);

            localStorage.setItem(
              "MemberDetail",
              JSON.stringify(response.data.data.memberDetail)
            ); //set local
            setCookieState(response.data.data.memberDetail.mId); // set context

            if (state == "from-introduce") {
              props.history.push({
                pathname: "/introduce-business/introduce-business-thanks",
              });
            } else {
              if (backUrl == null) {
                props.history.push({
                  pathname: "/",
                  // state: response.data.OwnerDetail.OwnerId, // your data array of objects
                });
              } else {
                props.history.push({
                  pathname: backUrl,
                });
              }
            }
          } else {
            // isSuccess = false
            setApiError(response.data.message);
          }

          // if (response.data.msg == "done") {
          //   setCookie("MemId", response.data.MemberDetail.MemId, cookieDay);
          //   localStorage.setItem(
          //     "MemberDetail",
          //     JSON.stringify(response.data.MemberDetail)
          //   ); //set local
          //   setCookieState(response.data.MemberDetail.MemId);

          //   if (state == "from-introduce") {
          //     props.history.push({
          //       pathname: "/introduce-business/introduce-business-thanks",
          //     });
          //   } else {
          //     if (backUrl == null) {
          //       props.history.push({
          //         pathname: "/",
          //         // state: response.data.OwnerDetail.OwnerId, // your data array of objects
          //       });
          //     } else {
          //       props.history.push({
          //         pathname: backUrl,
          //       });
          //     }
          //   }
          // } else if (response.data.msg == "login-failed") {
          //   setExistErr(true);
          //   setErrorrrRed(true);
          // } else if (response.data.msg == "error") {
          //   setServerErr(true);
          // } else if (response.data.msg == "fill-all") {
          //   setFillErr(true);
          // }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setTouched({
        username: true,
        password: true,
      });
    }
    // // Cookies
  };

  //setCookie
  // cname == Cookiename
  // cvalue == Cookievalue
  // exdays == expierday
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const [ApiDataCome, setApiDataCome] = useState({
    footer: false,
  });
  const [footerData, setFooterData] = useState({});

  //api header
  const header2 = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
  };

  //api header
  const headerMeta = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId"),
    Page: "login",
  };

  useEffect(() => {
    setRender(false);
    if (getCookie("MemId")) {
      props.history.push({
        pathname: "/members/profile",
      });
    } else {
      setRender(true);
      axios
        .get(configData.API_DOMAIN + "/api/v1/MetaTags/memebr", {
          headers: headerMeta,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setMTagsCome(true);
            setMetaData(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });

      // 2 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, []);

  // for password btn icon
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {render ? (
        <>
          {MTagsCome ? <MetaTagsEndUserPage data={MetaData} /> : ""}
          <div className="centerr">
            <div className="centerr d-none d-md-block">
              <Link to="/" className=" logo-login-a ">
                <img src={logo} alt="logo gishniz" className="logo-login-lap" />
              </Link>
            </div>

            <div className="login-container centerr">
              <MDBContainer className="">
                <MDBRow className="text-end">
                  <Link to="/" className="a-close-img">
                    <img
                      className="login-close-img me-0"
                      src={closeIcon}
                      alt="close icon"
                    />
                  </Link>
                </MDBRow>
                <MDBRow className="">
                  <div className="col-12 text-center">
                    <div className="centerr">
                      <Link to="/" className=" logo-login-a d-block d-md-none">
                        <img
                          src={logo}
                          alt="logo gishniz"
                          className="logo-login "
                        />
                      </Link>
                    </div>

                    <p className=" login-matn1 fs-xss-21 color-515151 fw-bold mt-3 mt-md-0 mb-0">
                      Sign in for user
                    </p>
                    <p className=" color-8d8c8c mb-4 pb-2 pb-md-0 login-matn2">
                      For using of facility of website
                      <br />
                      please sign in to your account
                    </p>
                  </div>
                  <div className="col-12 mt-1 padding-xss-32">
                    {/* <Facebook /> */}
                    <Google
                      backUrl={
                        state == "from-introduce"
                          ? "/introduce-business/introduce-business-thanks"
                          : backUrl
                      }
                    />

                    <div className="centerr">
                      <div className="divider-login d-flex align-items-center">
                        <p className="text-center mb-0 divider-login-text">
                          Sign in
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <form>
                        <input
                          name="username"
                          value={inputData.username}
                          onChange={inputChangeHandler}
                          // onFocus={inputFocusHanlder}
                          className={
                            (errors.username && touched.username) || ErrorrrRed
                              ? "input-login border-error"
                              : "input-login"
                          }
                          type="text"
                          placeholder="Email or User Name"
                        />

                        {errors.username && touched.username && (
                          <span className="input-error">{errors.username}</span>
                        )}
                        <div className="position-relative w-md-fit">
                          <input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={inputData.password}
                            onChange={inputChangeHandler}
                            // onFocus={inputFocusHanlder}
                            maxLength="25"
                            // minlength={6}
                            className={
                              (errors.password && touched.password) ||
                              ErrorrrRed
                                ? "input-login input-login-pass border-error mt-2"
                                : "input-login input-login-pass  mt-2"
                            }
                            placeholder="Password"
                          />
                          <IconButton
                            className="show-pass-btn"
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="start"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </div>
                        {errors.password && touched.password && (
                          <span className="input-error">{errors.password}</span>
                        )}
                        {ErrorrrRed && (
                          <span className="input-error">
                            user name or password is wrong.
                          </span>
                        )}
                        {/* {existErr && (
                      <span className="input-error">
                        آدرس ایمیل یا رمز عبور اشتباه است.
                      </span>
                    )} */}
                        {serverErr && (
                          <span className="input-error">
                            There was a connection error. Please try again in a
                            few minutes.
                          </span>
                        )}
                        {fillErr && (
                          <span className="input-error">
                            Please complete all fields.
                          </span>
                        )}
                        {apiError.map((item) => (
                          <span className="input-error">{item}</span>
                        ))}

                        <div className="d-flex justify-content-between align-items-end login-matn3-div login-matn3-div-md">
                          <Link
                            className="color-5ae6a2 text-end login-matn3 mb-0"
                            to="/forget-password"
                          >
                            {/* <p className="text-end login-matn3 mb-0 "> */}
                            Forget password?
                            {/* </p> */}
                          </Link>

                          <label className="container-s5 m-0">
                            <input
                              type="checkbox"
                              name="isAccepted"
                              value={inputData.isAccepted}
                              onChange={inputChangeHandler}
                            />
                            <span>Remember me</span>
                            <span className="s5-24h me-0 ms-2"></span>
                          </label>
                        </div>

                        <MDBBtn
                          disabled={disable}
                          onClick={submitHandler}
                          type="submit"
                          className="shadow-off hov-shadow-btn submit-login-btn text-center"
                        >
                          Login
                        </MDBBtn>
                        <div className="d-flex justify-content-center mb-4 fs-14 fs-xss-14">
                          <p className="color-242b2e">
                          Don’t Have an account?
                          </p>

                          <Link
                            className="color-5ae6a2"
                            onClick={registerHandler}
                          >
                            <p className="text-end ms-2">Create an account</p>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Login;
