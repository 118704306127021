import React from "react";
import ReactDOM from "react-dom/client";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./styles/css/index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="759409307984-5prcgggj3i0tj0ob9fbqboumlh0qqg1v.apps.googleusercontent.com">
  {/* <GoogleOAuthProvider clientId="759409307984-5prcgggj3i0tj0ob9fbqboumlh0qqg1v.apps.googleusercontent.com"> */}
  {/* <GoogleOAuthProvider clientId="365675004831-ebm2f3t7ikdn23k0hdcrg6s98k9e584o.apps.googleusercontent.com"> */}
    <App />
  </GoogleOAuthProvider>
);
// 
// https://www.googleapis.com/oauth2/v3/userinfo?access_token=ya29.a0AbVbY6NjyGVoQG6ZX180FVodJMwEHACNi1se1F0yla3CXVzXsDSjV6L90KFux6W2ObDtEQSIEdzUKPGnnLGdOomedRwGVeLn3Kn4sWoDg0MvY1zwTKO-2IoZ9kV52muwIDjeRj7JPLLIJ6GJSA6tN8qhP1_4-EIaCgYKAW4SARESFQFWKvPlUMCfgj4ha_fO_B8vKspZKg0166

// https://www.googleapis.com/oauth2/v3/userinfo?access_token=ya29.a0AbVbY6NPBNGOdCX-_AbJ_izfx4hevSrDZ8qvvvHdHvHGHZ5Rjf60RvmFVUcua5aHoIEVYGKEGPckHLZfVZDFdoZoiz_3PfFABSm53ajNVQxDfU7xhUS7rCmBxSF_v1MsZqQDzTnYJgBU9uW9eyEmtYahl3Ux1bMaCgYKAdoSARESFQFWKvPlc0u6t_5H3rz263PGo92I_A0166