import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Checkbox from "@mui/material/Checkbox";
import { DomainContext, LangIdContext, CookieContext } from "../App";
import "../styles/css/business-page.css";
import Skeleton from "@mui/material/Skeleton";
// config
import configData from "../config/config.json";
// com
import Header2 from "../components/header/Header2";
import Headermobile from "../components/header-mobile/Headermobile";
import CardCarousel from "../components/card-carousel/CardCarousel";
import ScrollCategory from "../components/scroll-category/ScrollCategory";
import RateIcon from "../components/rate-icon/RateIcon";
import BusinessAccordion from "../components/business-accordion/BusinessAccordion";
import Comments from "../components/comments/Comments";
import Footer from "../components/footer/Footer";
import EBModal from "../components/suggest-edit-business/EBModals";
import EBModalM from "../components/suggest-edit-business-mobile/EBModals";
import Rec2Card from "../components/rec2-card/Rec2Card";
import MetaTagsBusinessPage from "../components/meta-tags-seo/MetaTagsBusinessPage";
import LoginModal from "../components/login-modal/LoginModal";
import BizMapModal from "../components/biz-map-modal/BizMapModal";
import BizMapModalMobile from "../components/biz-map-modal/BizMapModalMobile";
import AlertSuccess from "../components/Dashbord/AlertSuccess/AlertSuccess";
import ImageCarousel from "../components/image-carousel/ImageCarousel";
// img
import next_right from "../images/next-right.png";
import newkasbokar from "../images/home-body/تازه‌ترین های بیزنس.svg";
import blogicon from "../images/home-body/blog.svg";
import presentation from "../images/home-body/presentation.svg";
import address from "../images/business/address.svg";
import address_red from "../images/business/address red.svg";
import arrow from "../images/business/arrow.svg";

import wifi from "../images/sub category svg/اینترنت رایگان.svg";
import family from "../images/sub category svg/family.svg";
import dog from "../images/sub category svg/dog.svg";
import wheelchair from "../images/sub category svg/دسترسی ویلچر.svg";

import share from "../images/business/share (2).svg";
import save0 from "../images/business/save-line.svg";
import save1 from "../images/business/save- on.svg";
import reportgray from "../images/business/report gray.svg";

import pic from "../images/business/add pic.svg";
import comment from "../images/business/comment on.svg";
import key from "../images/business/key (1).svg";

const BusinessPage = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const { cookieState, setCookieState } = useContext(CookieContext);

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //this 2lines are for scrolling to an element in page
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    // console.log("com rref");
  };
  const executeScroll2 = () => {
    myRef2.current.scrollIntoView();
    // console.log("com rref2");
  };

  // check if user have comment for biz to disable comment button
  const [userHaveComment, setUserHaveComment] = useState(null); // فعلا کامنت شود تا امکان ادیت وجود داشته باشد

  const [isSave, setIsSave] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);

  const saveHandler = () => {
    setSaveDisable(true);
    const dataPost = {
      bId: props.match.params.id,
    };

    axios
      .post(
        configData.API_DOMAIN + "/api/v1/Members/saved-business",
        dataPost,
        {
          headers: {
            ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
            LangId: langIdState,
            Authorization: getCookie("token")
              ? "Bearer " + getCookie("token")
              : "",
          },
        }
      )
      .then((response) => {
        setSaveDisable(false);
        if (response.data.isSuccess) {
          if (response.data.data == "saved") {
            setIsSave(true);
          } else if (response.data.data == "removed") {
            setIsSave(false);
          }
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  // moarefi more
  const [showLess, setShowLess] = React.useState(true);
  const [text, setText] = React.useState("Description");
  const [details, setDetails] = useState({
    details: [{}],
  });
  const [emkanat, setEmkanat] = useState([
    // {
    //   COTId: "",
    //   Icon: wifi,
    //   Title: "Free Wi-Fi",
    // },
    // {
    //   COTId: "",
    //   Icon: family,
    //   Title: "Suitable for children and family",
    // },
    // {
    //   COTId: "",
    //   Icon: dog,
    //   Title: "Entrance permission pet",
    // },
    // {
    //   COTId: "",
    //   Icon: wheelchair,
    //   Title: "Wheelchair chair",
    // },
  ]);
  const [payments, setPayments] = useState([]);

  const [s, sets] = useState({
    details: [{}],
  });
  const [blog, setBlog] = useState([
    { filePath: "", titr: "", lead: "", newsId: "" },
    { filePath: "", titr: "", lead: "", newsId: "" },
    { filePath: "", titr: "", lead: "", newsId: "" },
    { filePath: "", titr: "", lead: "", newsId: "" },
  ]);
  const [adds, setAdds] = useState([]);
  const [latestbiz, setLatestbiz] = useState({
    businesses: [
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
      {
        title: "",
        catTitle: "",
        location: "",
        rate: "",
        photoPath: "",
        flg: "",
        url: "",
      },
    ],
    sectionTitle: {
      icon: "",
      title: "",
      url: "",
    },
  });

  const [ApiDataCome, setApiDataCome] = useState({
    header: false,
    footer: false,
    headerbiz: false,
    bizrate: false,
    reviewforbiz: false,
    similarforbiz: false,
    // blogposts: false,
  });

  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });
  const [bizrateData, setBizrateData] = useState({});
  const [reviewforbiz, setReviewforbiz] = useState({});
  const [shareList, setShareList] = useState([]);

  const [showAlert, setshowAlert] = useState(false);

  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 2000);
  };
  // copy to clipboard fuction
  const copyToClipboard = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(window.location.href);
    // Alert the copied text
    alertHandeler();
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    setMTagsCome(false);

    if (getCookie(`_gbiz_id_${props.match.params.id}`)) {
      var count = 1;
    } else {
      count = 0;
      setCookie(`_gbiz_id_${props.match.params.id}`, props.match.params.id, 1);
    }

    if (langIdState !== "") {
      //api header
      const header = {
        ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
        LangId: langIdState,
        BizId: props.match.params.id,
        MemberId: cookieState !== "" ? cookieState : 0,
        Count: count,
        LocId: "7",
        Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
      };

      setMTagsCome(false);
      setDetails({ details: [{}] });
      setText("");
      setApiDataCome({
        header: false,
        footer: false,
        headerbiz: false,
        bizrate: false,
        reviewforbiz: false,
        blogforbiz: false,
        similarforbiz: false,
      });

      // 1 bizdetails
      axios
        .get(
          configData.API_DOMAIN +
            `/api/v1/Businesses/${props.match.params.id}/details?count=${count}`,
          {
            headers: header,
          }
        )
        .then((response) => {
          setDetails(response.data.data);
          setText(response.data.data.details[0].description);
          setIsSave(response.data.data.details[0].isSaved);
          setShareList(response.data.data.shareDetails);

          setMTagsCome(true);
          setMetaData(response.data.data.metaTag);

          if (
            response.data.data.metaTag.canonical !==
            `${configData.SITE_URL}${props.history.location.pathname}`
          ) {
            var x = response.data.data.metaTag.canonical;
            var x2 = x.replace(`${configData.SITE_URL}/biz/`, "");

            props.history.push({
              pathname: `/biz/${x2}`,
            });
          }
          // بازدیدهای اخیر
          let bizObject = {
            bId: response.data.data.details[0].bId,
            url: response.data.data.metaTag.canonical.replace(
              "https://en.gishniz.link",
              ""
            ),
            location: `${response.data.data.details[0].cityTitle},${response.data.data.details[0].countryTitle}`,
            title: response.data.data.details[0].title,
            photoPath: response.data.data.details[0].photoPath.includes(".png")
              ? response.data.data.details[0].photoPath.replace(
                  ".png",
                  "_302x302.png"
                )
              : response.data.data.details[0].photoPath.replace(
                  ".jpg",
                  "_302x302.jpg"
                ),
          };
          let bizArray = [];
          let bizArray_update = [];

          // to remove unValid data from local storage -> delete it for 3 version
          if (localStorage.getItem("test bizzz")) {
            localStorage.removeItem("test bizzz");
          }

          const ee = localStorage.getItem("LastVisitBusinesses")
            ? JSON.parse(localStorage.getItem("LastVisitBusinesses")).reverse()
            : [];
          if (
            localStorage.getItem("LastVisitBusinesses") &&
            JSON.parse(localStorage.getItem("LastVisitBusinesses")).length > 0
          ) {
            for (let index = 0; index < ee.length; index++) {
              // console.log("22");
              // const element = JSON.parse(localStorage.getItem("LastVisitBusinesses"));
              const element = ee;
              const element2 = element[index];
              if (element[index].title !== bizObject.title) {
                bizArray.push(element2);
              }
            }
            bizArray.push(bizObject);
          } else {
            bizArray.push(bizObject);
          }
          if (bizArray.length > 5) {
            bizArray_update = bizArray.slice(1);
          } else {
            bizArray_update = bizArray;
          }

          localStorage.setItem(
            "LastVisitBusinesses",
            JSON.stringify(bizArray_update.reverse())
          );
          // بازدیدهای اخیر
        })
        .catch((error) => {
          // alert(error);
        });

      // // 2 header-biz
      // 2 header-biz
      axios
        .get(configData.API_DOMAIN + "/api/v1/Headers/business", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setheaderData(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              headerbiz: true,
            }));
          }
        })
        .catch((error) => {
          // alert(error);
        });

      // 3 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      // // 4 bizrate
      axios
        .get(
          configData.API_DOMAIN +
            `/api/v1/Businesses/${props.match.params.id}/rates`,
          {
            headers: header,
          }
        )
        .then((response) => {
          setBizrateData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, bizrate: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 5 reviewforbiz
      axios
        .get(
          configData.API_DOMAIN +
            `/api/v1/Businesses/${props.match.params.id}/reviews`,
          {
            headers: header,
          }
        )
        .then((response) => {
          setReviewforbiz(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            reviewforbiz: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // فعلا کامنت شود تا امکان ادیت وجود داشته باشد
      if (getCookie("MemId")) {
        // check if user have comment for biz to disable comment button
        axios
          .get(
            configData.API_DOMAIN +
              `/api/v1/Businesses/${parseInt(
                props.match.params.id
              )}/member-review`,
            {
              headers: header,
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              if (
                response.data.data.ReviewId != 0 &&
                response.data.data.ReviewId != null
              ) {
                setUserHaveComment(true);
              } else {
                setUserHaveComment(false);
              }
            }
          })
          .catch((error) => {
            // alert(error);
          });
      } else {
        setUserHaveComment(false);
      }

      // 6 catoptitle
      // axios
      //   .get(domain + "/api/catoptitle", { headers: header })
      //   .then((response) => {
      //     if (response.data.msg == "done") {
      //       setEmkanat(response.data.OptionsList);
      //     }
      //   })
      //   .catch((error) => {
      //     // alert(error);
      //   });

      // 7 payments-type
      // axios
      //   .get(domain + "/api/payments-type", { headers: header })
      //   .then((response) => {
      //     if (response.data.msg == "done") {
      //       setPayments(response.data.PayList);
      //     }
      //   })
      //   .catch((error) => {
      //     // alert(error);
      //   });

      // 8 similarforbiz
      axios
        .get(
          configData.API_DOMAIN +
            `/api/v1/Businesses/${props.match.params.id}/similar`,
          {
            headers: header,
          }
        )
        .then((response) => {
          setLatestbiz(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            similarforbiz: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 9 blogforbiz
      axios
        // .get(domain + "/api/blogposts", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/BlogPosts/latest", {
          headers: header,
        })
        .then((response) => {
          setBlog(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            blogposts: true,
          }));
        })
        .catch((error) => {
          // alert(error);
        });
      // 10 advertisement
      axios
        .get(configData.API_DOMAIN + "/api/v1/Advertisement", {
          headers: header,
        })
        .then((response) => {
          setAdds(response.data.data);
        })
        .catch((error) => {
          // alert(error);
        });

      // 11 Visits بازدید برای داشبورد
      axios
        .post(
          configData.API_DOMAIN + "/api/v1/Businesses/visit",
          { link: props.history.location.pathname, bId: props.match.params.id },
          {
            headers: {
              ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
              LangId: langIdState,
              Authorization: getCookie("token")
                ? "Bearer " + getCookie("token")
                : "",
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          // alert(error);
        });
    }
  }, [props.match.params.id, langIdState]);

  return (
    <>
      {MTagsCome ? <MetaTagsBusinessPage data={MetaData} /> : ""}

      <div className="d-md-block d-none">
        {/* <Header2 /> */}
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-md-none d-block">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res"></div>
      </div>
      <div className="container">
        <Link className="business-text1">
          {/* See Other Lawyers In Vancouver
          <img className="business-right-img" src={next_right} alt="next" /> */}
        </Link>
        <div className="row">
          <div className="pe-md-2 col-12 col-md-8 business-padding">
            <div className="business-card-right row">
              <div className="col-12 col-md-5 centerr business-padding">
                {MTagsCome ? (
                  <>
                    <ImageCarousel
                      photos={details.photos}
                      bizName={details.details[0].title}
                    />
                    {/* <MDBCardImage
                      className="business-card-img"
                      src={
                        details.details[0].photoPath.includes(".png")
                          ? details.details[0].photoPath.replace(
                              ".png",
                              "_302x302.png"
                            )
                          : details.details[0].photoPath.replace(
                              ".jpg",
                              "_302x302.jpg"
                            )
                      }
                      alt={details.details[0].title}
                      fluid
                    /> */}
                    {/* responsive size */}
                    <div className="business-card-text3 d-md-none d-block">
                      <div className="ms-0  business-card-icons-hov business-dropdown mb-0">
                        <img
                          className="business-card-icon1 business-dropbtn"
                          src={share}
                          alt="..."
                        />
                        <div className="business-dropdown-content">
                          {shareList.map((item) =>
                            item.title === "Copy Link" ? (
                              <a
                                onClick={copyToClipboard}
                                className=""
                                key={item.icon}
                              >
                                <img
                                  className="business-drop-img"
                                  // src={link}
                                  src={item.icon}
                                  alt=""
                                />
                                {item.title}
                              </a>
                            ) : (
                              <a
                                className=""
                                href={item.shareLink}
                                key={item.icon}
                              >
                                <img
                                  className="business-drop-img"
                                  // src={link}
                                  src={item.icon}
                                  alt=""
                                />
                                {item.title}
                              </a>
                            )
                          )}
                        </div>
                      </div>
                      {cookieState !== "" ? (
                        <div
                          className=" business-card-icons-hov business-dropdown mb-0"
                          onClick={saveHandler}
                        >
                          {!saveDisable ? (
                            isSave ? (
                              <img
                                className="business-card-icon2  business-dropbtn"
                                src={save1}
                                alt="..."
                              />
                            ) : (
                              <img
                                className="business-card-icon2  business-dropbtn"
                                src={save0}
                                alt="..."
                              />
                            )
                          ) : (
                            <Skeleton
                              variant="circular"
                              width={32}
                              height={30}
                              animation="wave"
                              className="biz-icon-skeleton2 d-none  d-md-inline-block"
                            ></Skeleton>
                          )}
                          <div className="business-dropdown-content business-dropdown-content2">
                            <a
                              className=" bdi-lastt"
                              // href="#"
                              onClick={!saveDisable ? saveHandler : ""}
                            >
                              save
                            </a>
                          </div>
                        </div>
                      ) : (
                        <>
                          <LoginModal
                            url={props.location.pathname}
                            // url={"/biz"}
                            id={2}
                            text={"savess"}
                          />
                        </>
                      )}

                      {/* فعلا کامنت تا فاز دوم */}
                      {/* {cookieState !== "" ? (
                        <Link className="business-card-icons-hov">
                          <img
                            className="business-card-icon3"
                            src={pic}
                            alt="..."
                          />
                        </Link>
                      ) : (
                        <>
                          <LoginModal
                            url={props.location.pathname}
                            id={42}
                            text={"Add photo"}
                          />
                        </>
                      )} */}
                      <a
                        onClick={executeScroll2}
                        className="business-card-icons-hov"
                      >
                        <img
                          className="business-card-icon4"
                          src={comment}
                          alt="..."
                        />
                      </a>
                      {/* گزارش تا فاز 2 کامنت میشه  */}
                      {/* {cookieState !== "" ? (
                    <div className="ms-md-1 ms-2 business-card-icons-hov business-dropdown mb-0">
                      <img
                        className="business-card-icon3  business-dropbtn"
                        src={reportgray}
                        alt="..."
                      />
                      <div className="business-dropdown-content business-dropdown-content3">
                        <a className=" bdi-lastt">report</a>
                      </div>
                    </div>
                  ) : (
                    <>
                      <LoginModal
                        url={props.location.pathname}
                        id={1}
                        text={"report"}
                      />
                    </>
                  )} */}
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="height-100 h-res-biz-skeleton mt-2 mt-md-0 radius-2"
                      animation="wave"
                    />
                  </>
                )}
              </div>
              <div className="col-12 col-md-7 p-0 order-md-last order-first ">
                {MTagsCome ? (
                  // <MDBCardBody className="p-0 bh-100">
                  //   <MDBCardTitle className="business-card-title d-flex justify-content-between">
                  //     {/* نادر پناهی */}
                  //     {details.details[0].Title}
                  //     <img
                  //       className="biz-flag d-md-none"
                  //       src={details.details[0].Flg}
                  //       alt="falg"
                  //     />
                  //     <div className="rightt d-contents d-md-block">
                  //       <img
                  //         className="biz-flag d-none d-md-block"
                  //         src={details.details[0].Flg}
                  //         alt="falg"
                  //       />
                  //       <RateIcon
                  //         cardrate={parseFloat(details.details[0].Rate)}
                  //         isRec={0}
                  //         isBusiness={1}
                  //         dir="ltr "
                  //       />
                  //     </div>
                  //   </MDBCardTitle>
                  //   <MDBCardText className="business-card-text1">
                  //     {details.details[0].JobTitle}
                  //   </MDBCardText>
                  //   <MDBCardText className="business-card-text2">
                  //     {/* ریچموند هیل، ونکوور، کانادا */}
                  //     {details.details[0].CityTitle},{" "}
                  //     {details.details[0].ProvinceTitle},{" "}
                  //     {details.details[0].CountryTitle}
                  //   </MDBCardText>
                  //   {/* laptop size */}
                  //   <div className="business-card-text3 d-md-flex d-none">
                  //     <div className=" business-card-icons-hov business-dropdown mb-0">
                  //       <img
                  //         className="business-card-icon1 business-dropbtn"
                  //         src={share}
                  //         alt="..."
                  //       />
                  //       <div className="business-dropdown-content">
                  //         {shareList.map((item) =>
                  //           item.Title === "Copy Link" ? (
                  //             <a
                  //               onClick={copyToClipboard}
                  //               className=""
                  //               key={item.Icon}
                  //             >
                  //               <img
                  //                 className="business-drop-img"
                  //                 // src={link}
                  //                 src={item.Icon}
                  //                 alt=""
                  //               />
                  //               {item.Title}
                  //             </a>
                  //           ) : (
                  //             <a
                  //               className=""
                  //               href={item.ShareLink}
                  //               key={item.Icon}
                  //             >
                  //               <img
                  //                 className="business-drop-img"
                  //                 // src={link}
                  //                 src={item.Icon}
                  //                 alt=""
                  //               />
                  //               {item.Title}
                  //             </a>
                  //           )
                  //         )}
                  //       </div>
                  //       {/* <div className="business-dropdown-content">
                  //       <a className="" href="/business">
                  //         <img
                  //           className="business-drop-img"
                  //           src={link}
                  //           alt=""
                  //         />
                  //         Copy Link
                  //       </a>
                  //       <a className="" href="/business">
                  //         <img
                  //           className="business-drop-img"
                  //           src={emailblack}
                  //           alt=""
                  //         />
                  //         Email
                  //       </a>
                  //       <a className="" href="/business">
                  //         <img
                  //           className="business-drop-img"
                  //           src={facebook}
                  //           alt=""
                  //         />
                  //         Facebook
                  //       </a>

                  //       <a className=" bdi-lastt" href="/business">
                  //         <img
                  //           className="business-drop-img"
                  //           src={twitter}
                  //           alt=""
                  //         />
                  //         Twitter
                  //       </a>
                  //     </div> */}
                  //     </div>
                  //     {cookieState !== "" ? (
                  //       <div
                  //         className="ms-1 business-card-icons-hov business-dropdown mb-0"
                  //         onClick={saveHandler}
                  //       >
                  //         {isSave ? (
                  //           <img
                  //             className="business-card-icon2  business-dropbtn"
                  //             src={save1}
                  //             alt="..."
                  //           />
                  //         ) : (
                  //           <img
                  //             className="business-card-icon2  business-dropbtn"
                  //             src={save0}
                  //             alt="..."
                  //           />
                  //         )}
                  //         <div className="business-dropdown-content business-dropdown-content2">
                  //           <a
                  //             className=" bdi-lastt"
                  //             href="#"
                  //             onClick={saveHandler}
                  //           >
                  //             save
                  //           </a>
                  //         </div>
                  //       </div>
                  //     ) : (
                  //       <>
                  //         <LoginModal
                  //           url={props.location.pathname}
                  //           id={2}
                  //           text={"save"}
                  //         />
                  //       </>
                  //     )}
                  //     {/* گزارش تا فاز 2 کامنت میشه  */}
                  //     {/* {cookieState !== "" ? (
                  //     <div className="ms-md-1 ms-2 business-card-icons-hov business-dropdown mb-0">
                  //       <img
                  //         className="business-card-icon3  business-dropbtn"
                  //         src={reportgray}
                  //         alt="..."
                  //       />
                  //       <div className="business-dropdown-content business-dropdown-content3">
                  //         <a className=" bdi-lastt">report</a>
                  //       </div>
                  //     </div>
                  //   ) : (
                  //     <>
                  //       <LoginModal
                  //         url={props.location.pathname}
                  //         id={1}
                  //         text={"report"}
                  //       />
                  //     </>
                  //   )} */}
                  //   </div>
                  // </MDBCardBody>
                  <MDBCardBody className="p-0 bh-100">
                    <div className="d-flex justify-content-between">
                      <div>
                        <MDBCardTitle className="business-card-title d-flex justify-content-between mb-md-4 mb-3">
                          {/* نادر پناهی */}
                          {details.details[0].title}
                        </MDBCardTitle>
                        {/* کککک */}
                        <MDBCardText className="business-card-text1">
                          {details.details[0].jobTitle}
                        </MDBCardText>
                        <MDBCardText className="business-card-text2">
                          {/* ریچموند هیل، ونکوور، کانادا */}
                          {details.details[0].cityTitle},{" "}
                          {details.details[0].provinceTitle},{" "}
                          {details.details[0].countryTitle}
                        </MDBCardText>
                      </div>
                      <div className="minwidth-90px ">
                        <img
                          className="biz-flag d-md-none"
                          src={details.details[0].flag}
                          alt="falg"
                        />
                        <div className="leftt d-contents d-md-block">
                          <img
                            className="biz-flag d-none d-md-block"
                            src={details.details[0].flag}
                            alt="falg"
                          />
                          <div className="d-md-none">
                            <RateIcon
                              cardrate={parseFloat(details.details[0].rate)}
                              isRec={0}
                              isBusiness={1}
                              dir="ltr "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" biz-tags-div d-md-block">
                      {/* Comment The tags for now */}
                      {/* <div className="row biz-tags-container mb-4 mb-md-0">
                        {details.tags.map((item) =>
                          item.TagTitle ? (
                            <span key={item.TId} className="biz-tags">
                              {item.TagTitle}
                            </span>
                          ) : (
                            ""
                          )
                        )}
                      </div> */}
                    </div>
                    {/* laptop size */}
                    <div className="business-card-text3 d-md-flex d-none">
                      <RateIcon
                        cardrate={parseFloat(details.details[0].rate)}
                        isRec={0}
                        isBusiness={1}
                        dir="ltr "
                      />
                      <div>
                        {/* گزارش تا فاز 2 کامنت میشه  */}
                        {/* {cookieState !== "" ? (
                    <div className="ms-md-1 ms-2 business-card-icons-hov business-dropdown mb-0">
                      <img
                        className="business-card-icon3  business-dropbtn"
                        src={reportgray}
                        alt="..."
                      />
                      <div className="business-dropdown-content business-dropdown-content3">
                        <a className=" bdi-lastt">گزارش</a>
                      </div>
                    </div>
                  ) : (
                    <LoginModal
                      url={props.location.pathname}
                      id={1}
                      text={"ثبت گزارش"}
                    />
                  )} */}

                        {cookieState !== "" ? (
                          <div
                            className="ms-1 business-card-icons-hov business-dropdown mb-0"
                            // onClick={saveHandler}
                          >
                            {!saveDisable ? (
                              isSave ? (
                                <img
                                  className="business-card-icon2  business-dropbtn"
                                  src={save1}
                                  alt="..."
                                  onClick={saveHandler}
                                />
                              ) : (
                                <img
                                  className="business-card-icon2  business-dropbtn"
                                  src={save0}
                                  alt="..."
                                  onClick={saveHandler}
                                />
                              )
                            ) : (
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={30}
                                animation="wave"
                                className="biz-icon-skeleton2 d-none  d-md-inline-block"
                              ></Skeleton>
                            )}
                            <div className="business-dropdown-content business-dropdown-content2">
                              <a
                                className=" bdi-lastt"
                                href="#"
                                onClick={!saveDisable ? saveHandler : ""}
                              >
                                save
                              </a>
                            </div>
                          </div>
                        ) : (
                          <LoginModal
                            url={props.location.pathname}
                            id={2}
                            text={"save"}
                          />
                        )}

                        <div className=" business-card-icons-hov business-dropdown mb-0">
                          <img
                            className="business-card-icon1 business-dropbtn"
                            src={share}
                            alt="..."
                          />
                          <div className="business-dropdown-content">
                            {shareList.map((item) =>
                              item.title === "Copy Link" ? (
                                <a
                                  onClick={copyToClipboard}
                                  className=""
                                  key={item.icon}
                                >
                                  <img
                                    className="business-drop-img"
                                    // src={link}
                                    src={item.icon}
                                    alt=""
                                  />
                                  {item.title}
                                </a>
                              ) : (
                                <a
                                  className=""
                                  target="_blank"
                                  href={item.shareLink}
                                  key={item.icon}
                                >
                                  <img
                                    className="business-drop-img"
                                    // src={link}
                                    src={item.icon}
                                    alt=""
                                  />
                                  {item.title}
                                </a>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </MDBCardBody>
                ) : (
                  <>
                    <Skeleton
                      className="skeleton-text1-rec"
                      variant="text"
                      sx={{ fontSize: "1.5rem" }}
                      width={120}
                      animation="wave"
                    />
                    <Skeleton
                      className="skeleton-text2-rec"
                      variant="text"
                      sx={{ fontSize: "0.75rem" }}
                      animation="wave"
                    />
                    <Skeleton
                      className="mt-0"
                      variant="text"
                      sx={{ fontSize: "0.75rem" }}
                      animation="wave"
                    />
                  </>
                )}
              </div>
            </div>

            {/* for laptop */}
            <div className="d-flex justify-content-between d-none d-md-flex">
              <div className="d-flex">
                <a
                  className="business-write-btn hov-shadow"
                  onClick={executeScroll}
                >
                  <img
                    className="business-write-btn-icon"
                    src={comment}
                    alt="comment"
                  />
                  Write a review
                </a>
                {/* فعلا کامنت تا فاز دوم */}
                {/* {cookieState !== "" ? (
                  <Link className="business-upload-btn hov-shadow">
                    <img
                      className="business-upload-btn-icon"
                      src={pic}
                      alt="pic"
                    />
                    Add photo
                  </Link>
                ) : (
                  <>
                    <LoginModal
                      url={props.location.pathname}
                      id={4}
                      text={"Addsss photo"}
                    />
                  </>
                )} */}
              </div>
            </div>
            {/* for laptop */}

            {MTagsCome ? (
              text && (
                <div className="business-intro d-md-block d-none">
                  {text.length < 251 ? (
                    // without more or less
                    <p className="mb-0">{text}</p>
                  ) : (
                    <div>
                      {
                        <>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: showLess
                                ? `${text.slice(0, 250)} ...`
                                : `${text.slice(0, 700)}`,
                            }}
                          ></p>
                          <a
                            style={{ color: "#969494", cursor: "pointer" }}
                            onClick={() => setShowLess(!showLess)}
                          >
                            {showLess ? "More" : ""}
                          </a>
                        </>
                      }
                    </div>
                  )}
                </div>
              )
            ) : (
              <div className="business-intro d-md-block d-none">
                <Skeleton
                  className="skeleton-text2-rec"
                  variant="text"
                  sx={{ fontSize: "0.75rem" }}
                  animation="wave"
                />
                <Skeleton
                  className="mt-0"
                  variant="text"
                  sx={{ fontSize: "0.75rem" }}
                  animation="wave"
                />
              </div>
            )}

            {/* for laptop */}
            <div className="row m-0 d-md-flex d-none">
              <div className=" col-md-6 p-0 pe-md-2">
                {MTagsCome ? (
                  <div className="business-map">
                    <div className="business-map-div">
                      <img
                        className="business-map-img"
                        src={address_red}
                        alt=""
                      />
                      {/* <p>{details.details[0].title} را در نقشه ببینید</p> */}
                      <p>
                        {details.details[0].lat !== "" &&
                        details.details[0].lat !== 0 &&
                        details.details[0].lng !== "" &&
                        details.details[0].lng !== 0 ? (
                          <>
                            View this business
                            <br />
                            on the map
                          </>
                        ) : (
                          <>
                            This business is not yet added on the map
                            {/* <br /> */}
                          </>
                        )}
                      </p>
                    </div>
                    <BizMapModal
                      Add_map={false}
                      dataCome={MTagsCome}
                      lat={details.details[0].lat}
                      lng={details.details[0].lng}
                      Address={details.details[0].address}
                    />
                  </div>
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="business-map radius-2 border-0"
                      animation="wave"
                      height={169}
                    />
                  </>
                )}
              </div>
              <div className=" col-md-6 p-0 ps-md-2">
                {MTagsCome ? (
                  <div className="business-ownership centerr">
                    <div className="w-100">
                      <p className="business-ownership-text2">
                        This business is registered by users and Gishniz is not
                        responsible for the published information
                      </p>
                      <p className="business-ownership-text3">
                        Is the information wrong?{" "}
                      </p>

                      {cookieState !== "" ? (
                        <>
                          <EBModal
                            res={false}
                            id={props.match.params.id}
                            BizId={details.details[0].bId}
                          />
                        </>
                      ) : (
                        <>
                          <LoginModal
                            url={props.location.pathname}
                            id={5}
                            text={"edit the information"}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="business-map radius-2 border-0"
                      animation="wave"
                      height={169}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="d-none d-md-block mt-3">
              {/*معرفی به گیشنیز*/}
              <div className="text-align-centerr d-none d-md-block">
                <div className="moarefi d-flex justify-content-around  align-items-center my-0 ">
                  <div className="align-self-center text-center ">
                    Know any Farsi-speaking businesses? <br />
                    <span>Introduce them to Gishniz!</span>
                  </div>
                  <img
                    className="moarefi-icon"
                    src={presentation}
                    alt="moarefi icon"
                  />

                  <Link
                    className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
                    to="/introduce-business"
                  >
                    Click here{" "}
                  </Link>

                  {/* </div> */}
                </div>
              </div>
              {/* معرفی به گیشنیز سایز موبایل*/}
              <div className="text-align-centerr d-block d-md-none">
                <div className="moarefi d-flex justify-content-between my-0 align-items-center">
                  <div className=" align-self-center text-white alert-m-text">
                    Know any Farsi-speaking businesses?
                    <br />
                    <span>Introduce them to Gishniz!</span>
                  </div>

                  <Link
                    className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                    to="/introduce-business"
                  >
                    Click here{" "}
                  </Link>
                </div>
              </div>

              {/* comments  */}
              <div ref={myRef} id="comments">
                {ApiDataCome.bizrate &&
                ApiDataCome.reviewforbiz &&
                userHaveComment !== null ? (
                  <Comments
                    id={props.match.params.id}
                    url={props.location.pathname}
                    data={bizrateData}
                    dataCome={ApiDataCome.bizrate}
                    com={reviewforbiz}
                    comCome={ApiDataCome.reviewforbiz}
                    userHaveComment={userHaveComment}
                  />
                ) : (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className=" mt-3 radius-2"
                      animation="wave"
                      height={92}
                    />
                  </>
                )}
              </div>
              {/* کارت های بلاگ */}
              {/* <div className="business-blog">
                <div className="d-flex align-items-end justify-content-between titbox-index-res mb-3">
                  <div className="d-flex align-items-end ">
                    <img className="tit-img" src={blogicon} alt="..." />
                    <a
                      className="color-tit"
                      href={configData.BLOG_URL}
                      target="_blank"
                    >
                      <p className="my-0 fw-800 fs-18 fs-xs-16 color-101010 color-tit">
                        Blog
                      </p>
                    </a>
                  </div>
                  <a
                    className="hover-5ae6a2"
                    href={configData.BLOG_URL}
                    target="_blank"
                  >
                    <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2 color-sm-101010">
                      more
                    </p>
                  </a>
                </div>
                <div className="row justify-content-center">
                  <div className="rec-center col-12 col-md-6 rec-md ps-auto pe-md-2">
                    <Rec2Card
                      bus={true}
                      title={blog[0].Titr}
                      desc={blog[0].Lead}
                      url={blog[0].PageURL}
                      img={blog[0].FilePath}
                    />
                  </div>
                  <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-sm-0 pe-auto ps-md-2">
                    <Rec2Card
                      bus={true}
                      title={blog[1].Titr}
                      desc={blog[1].Lead}
                      url={blog[1].PageURL}
                      img={blog[1].FilePath}
                    />
                  </div>
                  <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto pe-md-2">
                    <Rec2Card
                      bus={true}
                      title={blog[2].Titr}
                      desc={blog[2].Lead}
                      url={blog[2].PageURL}
                      img={blog[2].FilePath}
                    />
                  </div>
                  <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 pe-auto ps-md-2 ">
                    <Rec2Card
                      bus={true}
                      title={blog[3].Titr}
                      desc={blog[3].Lead}
                      url={blog[3].PageURL}
                      img={blog[3].FilePath}
                    />
                  </div>
                </div>
               
              </div> */}
            </div>
          </div>

          {/* left side */}

          <div className="ps-md-2 col-12 col-md-4 business-padding ">
            <div className="">
              <div className="business-information ">
                <div className="business-information-1" dir="ltr">
                  <div className="mb-0 d-flex">
                    <img
                      className="business-information-icon"
                      src={address}
                      alt="address"
                    />

                    {details.details[0].address == null ||
                    details.details[0].address == "" ? (
                      <div className="business-information-no-address">
                        If you know the address of this business, suggest it.
                      </div>
                    ) : (
                      <>
                        {/* // <a href="" className="business-information-text">
                      //   {details.details[0].address}
                      //   <img
                      //     className="business-information-img"
                      //     src={arrow}
                      //     alt=""
                      //   />
                      // </a> */}
                        <div className="d-none d-md-block">
                          <BizMapModal
                            Add_map={true}
                            dataCome={MTagsCome}
                            lat={details.details[0].lat}
                            lng={details.details[0].lng}
                            Address={details.details[0].address}
                          />
                        </div>

                        <div className="d-md-none">
                          <BizMapModalMobile
                            Add_map={true}
                            dataCome={MTagsCome}
                            lat={details.details[0].lat}
                            lng={details.details[0].lng}
                            Address={details.details[0].address}
                            Title={details.details[0].title}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <BusinessAccordion data={details} />
              </div>

              {/*  */}
              {/* {emkanat.length ? (
                <div className="business-facility ">
                  <p className="business-facility-title">Facilities</p>
                  {emkanat &&
                    emkanat.map((item) => (
                      <p className="business-facility-text" key={item.COTId}>
                        <img
                          className="business-facility-icon"
                          src={item.Icon}
                          alt={item.Title}
                        />
                        {item.Title}
                      </p>
                    ))}
                </div>
              ) : (
                <></>
              )} */}
              {/*  shive pardakht*/}
              {/* {payments.length ? (
                <div className="business-facility ">
                  <p className="business-facility-title">Payment method</p>
                  {payments.length ? (
                    payments.map((item) => (
                      <div key={item.COTId} className="result-filter-item">
                        <label className="container-s2 m-0 me-0 mb-0 d-inline-flex cursor-auto">
                          <Checkbox
                            checked={item.IsSelected}
                            disableRipple
                            size="small"
                            sx={{
                              color: "#33e0a0",
                              "&.Mui-checked": {
                                color: "#33e0a0",
                              },
                            }}
                          />
                          <span>{item.Title}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )} */}
            </div>

            <div className="d-md-block d-none mt-3">
              {adds.map((item) => (
                <div className="tabliqat-box mb-3" key={item.title}>
                  <a href={item.urlTarget} target="_blank" rel={item.relType}>
                    <LazyLoadImage
                      // PlaceholderSrc={
                      //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                      // }
                      src={item.fileAddress}
                      alt={item.title}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>

          {/* for responsive */}
          <div className="col-12">
            <div
              className={
                MTagsCome
                  ? "business-intro  d-md-none"
                  : "business-intro d-block  d-md-none"
              }
            >
              {MTagsCome ? (
                <>
                  {text && text.length < 251 ? (
                    // without more or less
                    <p className="mb-0">{text}</p>
                  ) : (
                    <div>
                      {text && (
                        <>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: showLess
                                ? `${text.slice(0, 250)} ...`
                                : `${text.slice(0, 700)}`,
                            }}
                          ></p>
                          <a
                            style={{ color: "#969494", cursor: "pointer" }}
                            onClick={() => setShowLess(!showLess)}
                          >
                            {showLess ? "More" : ""}
                          </a>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Skeleton
                    className="skeleton-text2-rec"
                    variant="text"
                    sx={{ fontSize: "0.75rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="mt-0"
                    variant="text"
                    sx={{ fontSize: "0.75rem" }}
                    animation="wave"
                  />
                </>
              )}
            </div>
          </div>
          {/* for responsive */}
          <div className="row m-0 d-md-none">
            <div className=" col-md-6 p-0 ps-md-2">
              {MTagsCome ? (
                <div className="business-map">
                  <div className="business-map-div">
                    <img
                      className="business-map-img"
                      src={address_red}
                      alt=""
                    />
                    <p>
                      {details.details[0].lat !== "" &&
                      details.details[0].lat !== 0 &&
                      details.details[0].lng !== "" &&
                      details.details[0].lng !== 0 ? (
                        <>
                          View this business
                          <br />
                          oon the map
                        </>
                      ) : (
                        <>
                          <p>This business is not yet added on the map</p>
                        </>
                      )}
                    </p>
                  </div>
                  <BizMapModalMobile
                    Add_map={false}
                    dataCome={MTagsCome}
                    lat={details.details[0].lat}
                    lng={details.details[0].lng}
                    Address={details.details[0].address}
                    Title={details.details[0].title}
                  />
                </div>
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className="business-map radius-2 border-0"
                    animation="wave"
                    height={100}
                  />
                </>
              )}
            </div>
            <div className=" col-md-6 p-0 ps-md-2">
              {MTagsCome ? (
                <div className="business-ownership centerr">
                  <div className="w-100">
                    <p className="business-ownership-text2">
                      This business is registered by users and Gishniz is not
                      responsible for the published information
                    </p>
                    <p className="business-ownership-text3">
                      Is the information wrong?
                    </p>
                    {/* {getCookie("MemId") ? ( */}
                    {cookieState !== "" ? (
                      <>
                        <EBModalM
                          id={props.match.params.id}
                          BizId={details.details[0].bId}
                        />
                      </>
                    ) : (
                      <>
                        <LoginModal
                          url={props.location.pathname}
                          id={5}
                          text={"edit the information"}
                        />{" "}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className="business-map radius-2 border-0"
                    animation="wave"
                    height={100}
                  />
                </>
              )}
            </div>
          </div>

          <div className="ps-md-2 col-12 col-md-8 mt-3 d-md-none">
            {/*معرفی به گیشنیز*/}
            <div className="text-align-centerr d-none d-md-block">
              <div className="moarefi d-flex justify-content-around  align-items-center my-0 ">
                <div className="align-self-center text-center ">
                  Know any Farsi-speaking businesses?
                  <br />
                  <span>Introduce them to Gishniz!</span>
                </div>
                <img
                  className="moarefi-icon"
                  src={presentation}
                  alt="moarefi icon"
                />

                <Link
                  className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
                  to="/introduce-business"
                >
                  Click here{" "}
                </Link>

                {/* </div> */}
              </div>
            </div>
            {/* معرفی به گیشنیز سایز موبایل*/}
            <div className="text-align-centerr d-block d-md-none">
              <div className="moarefi d-flex justify-content-between my-0 align-items-center">
                <div className=" align-self-center text-white alert-m-text">
                  Know any Farsi-speaking businesses?
                  <br />
                  <span>Introduce them to Gishniz!</span>
                </div>

                <Link
                  className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                  to="/introduce-business"
                >
                  Click here{" "}
                </Link>
              </div>
            </div>
            {/* comments  */}
            <div ref={myRef2} id="comments">
              {ApiDataCome.bizrate &&
              ApiDataCome.reviewforbiz &&
              userHaveComment !== null ? (
                <Comments
                  id={props.match.params.id}
                  url={props.location.pathname}
                  data={bizrateData}
                  dataCome={ApiDataCome.bizrate}
                  com={reviewforbiz}
                  comCome={ApiDataCome.reviewforbiz}
                  userHaveComment={userHaveComment}
                />
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    className=" mt-3 radius-2"
                    animation="wave"
                    height={92}
                  />
                </>
              )}
            </div>

            {/* کارت های بلاگ */}
            {/* <div className="business-blog">
              <div className="d-flex align-items-end justify-content-between titbox-index-res mb-3">
                <div className="d-flex align-items-end ">
                  <img className="tit-img" src={blogicon} alt="..." />
                  <a
                    className="color-tit"
                    href={configData.BLOG_URL}
                    target="_blank"
                  >
                    <p className="my-0 fw-800 fs-18 fs-xs-16 color-101010 color-tit">
                      Blog
                    </p>
                  </a>
                </div>
                <a
                  className="hover-5ae6a2"
                  href={configData.BLOG_URL}
                  target="_blank"
                >
                  <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2 color-sm-101010">
                    more
                  </p>
                </a>
              </div>
              <div className="row justify-content-center">
                <div className="rec-center col-12 col-md-6 rec-md pe-auto ps-md-2">
                  <Rec2Card
                    bus={true}
                    title={blog[0].Titr}
                    desc={blog[0].Lead}
                    url={blog[0].PageURL}
                    img={blog[0].FilePath}
                  />
                </div>
                <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-sm-0 ps-auto pe-md-2">
                  <Rec2Card
                    bus={true}
                    title={blog[1].Titr}
                    desc={blog[1].Lead}
                    url={blog[1].PageURL}
                    img={blog[1].FilePath}
                  />
                </div>
                <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto ps-md-2">
                  <Rec2Card
                    bus={true}
                    title={blog[2].Titr}
                    desc={blog[2].Lead}
                    url={blog[2].PageURL}
                    img={blog[2].FilePath}
                  />
                </div>
                <div className="rec-center col-12 col-md-6 rec-md mt-xs-13 mt-md-3 ps-auto pe-md-2 ">
                  <Rec2Card
                    bus={true}
                    title={blog[3].Titr}
                    desc={blog[3].Lead}
                    url={blog[3].PageURL}
                    img={blog[3].FilePath}
                  />
                </div>
              </div>
            </div> */}
          </div>
          {/* changed tarh */}
          {/* <div className="container pe-sm-0 p-xs-0">
            <div className="row"> */}
          <div className="pe-md-2 col-12 col-md-4 mt-md-3 centerr d-md-none mt-3">
            {adds.map((item) => (
              <div className="tabliqat-box mb-3" key={item.title}>
                <a href={item.urlTarget} target="_blank" rel={item.relType}>
                  <LazyLoadImage
                    // PlaceholderSrc={
                    //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                    // }
                    src={item.fileAddress}
                    alt={item.title}
                  />
                </a>
              </div>
            ))}
          </div>
          {/* </div>
          </div> */}

          <div className="col-12 col-md-8 "></div>
          <div className="pe-md-2 col-12 col-md-4 centerr"></div>
        </div>
      </div>
      {/*comment for now thre is not blog */}
      {/* کارت های بلاگ */}
      {/* <div className="container">
        <div className="business-blog">
          <div className="d-flex align-items-end justify-content-between titbox-index-res mb-3">
            <div className="d-flex align-items-end ">
              <img className="tit-img" src={blogicon} alt="..." />
              <a
                className="color-tit"
                href={configData.BLOG_URL}
                target="_blank"
              >
                <p className="my-0 fw-800 fs-18 fs-xs-16 color-101010 color-tit">
                  Blog
                </p>
              </a>
            </div>
            <a
              className="hover-5ae6a2"
              href={configData.BLOG_URL}
              target="_blank"
            >
              <p className="my-0 fs-14 fs-xs-14 color-7e7e7e hover-5ae6a2">
                more
              </p>
            </a>
          </div>
          <div className="row justify-content-center me-sm-0">
            <div className="rec-center col-12 col-md-4 rec-md ps-auto pe-sm-2 pe-md-3">
              <Rec2Card
                bus={true}
                title={blog[0].Titr}
                desc={blog[0].Lead}
                url={blog[0].PageURL}
                img={blog[0].FilePath}
              />
            </div>
            <div className="rec-center col-12 col-md-4 rec-md mt-xs-13 mt-sm-0 pe-sm-2 pe-md-3 ps-md-0">
              <Rec2Card
                bus={true}
                title={blog[1].Titr}
                desc={blog[1].Lead}
                url={blog[1].PageURL}
                img={blog[1].FilePath}
              />
            </div>
            <div className="rec-center col-12 col-md-4 rec-md mt-xs-13 mt-md-0 px-sm-0">
              <Rec2Card
                bus={true}
                title={blog[2].Titr}
                desc={blog[2].Lead}
                url={blog[2].PageURL}
                img={blog[2].FilePath}
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* اسلایدر مکان‌های مشابه */}
      <div className="container pe-sm-0 p-xs-0 mt-md-5">
        <div className="mb-40 mb-xs-12">
          <div className="d-flex align-items-end justify-content-between position-relative titbox-index-res px-1 mb-0">
            <div className="d-flex align-items-end business-makan-position">
              {/* <img className="tit-img" src={newkasbokar} alt="..." /> */}
              <object
                data={latestbiz.sectionTitle.icon}
                alt={latestbiz.sectionTitle.title}
                className="tit-img"
                type={latestbiz.sectionTitle.type}
              ></object>
              <a className="color-tit" href={latestbiz.sectionTitle.url}>
                <p className="color-tit2 my-0 fw-800 fs-18 fs-xs-16 color-101010 color-tit">
                  {/* Similar businesses */}
                  {latestbiz.sectionTitle.title}
                </p>
              </a>
            </div>
          </div>
          <div className="d-none d-md-block">
            <CardCarousel
              data={latestbiz.businesses}
              withRate={true}
              datacome={ApiDataCome.similarforbiz}
            />
          </div>
          <div className="d-block d-md-none">
            <ScrollCategory
              data={latestbiz.businesses}
              isCarousel={true}
              withRate={true}
              datacome={ApiDataCome.similarforbiz}
            />
          </div>
        </div>
      </div>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={"The link has been copied to your clipboard."}
      />
    </>
  );
};

export default BusinessPage;
