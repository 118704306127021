import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MDBBtn } from "mdb-react-ui-kit";
import "./application.css";
import { DomainContext, LangIdContext } from "../../App";
import { LazyLoadImage } from "react-lazy-load-image-component";
// components
import AlertSuccess from "../Dashbord/AlertSuccess/AlertSuccess";
import { validate } from "../../pages/login/validate";
// config
import configData from "../../config/config.json";
// images
import google from "../../images/home-body/google-play2.png";
import apple from "../../images/home-body/apple store2.png";

const Application = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  // for validation
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [emailData, setEmailData] = useState({ email: "" });
  const [disable, setDisable] = useState(false);

  const inputChangeHandler = (event) => {
    setDisable(false);
    setEmailData({ email: event.target.value });
  };

  useEffect(() => {
    setErrors(validate(emailData, "application"));
  }, [emailData, touched]);

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (!Object.keys(errors).length) {
      setDisable(true);

      axios
        .post(
          configData.API_DOMAIN + "/api/v1/DownloadRequests",
          {
            email: emailData.email,
          },
          {
            headers: header,
          }
        )
        .then((response) => {
          setDisable(false);
          if (response.data.isSuccess) {
            // success
            delete errors.email;
            alertHandeler();
            setEmailData({ email: "" });
            setTouched({
              email: false,
            });
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setTouched({
        email: true,
      });
    }
  };

  return (
    <div className="app-center app-btn-center">
      <div className="my-33 mb-xs-16 text-center app-text">
        <span className="app-title">Gishniz App</span>
        {/* <br /> */}
        <p className=" mb-0 app-text2">
          {/* <br className="d-none d-md-block" /> */}
          The "Gishniz" app will be ready soon for download.
          <br className="d-none d-md-block" />
          If you want to be among its first users,
          <br className="d-none d-md-block" />
          enter your email in the box below, and we'll keep you informed
          <br className="d-none d-md-block" />
          as soon as the app is available.
        </p>
      </div>
      <input
        type="email"
        placeholder="Email"
        value={emailData.email}
        onChange={inputChangeHandler}
        className={
          errors.email && touched.email ? "app-input border-error" : "app-input"
        }
      />
      {errors.email && touched.email && (
        <span className="input-error mx-0 error-app">{errors.email}</span>
      )}
      <br />

      <MDBBtn
        onClick={submitHandler}
        disabled={disable}
        className="app-btn hov-shadow-btn shadow-off my-xs-3 mb-60"
      >
        Save
      </MDBBtn>

      <div className="d-flex text-center justify-content-center">
        <a href="javascript:void(0);">
          <LazyLoadImage
            // PlaceholderSrc={
            //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
            // }
            className="app-img me-2 hov-shadow2 hov-trans"
            src={google}
            alt="google play"
          />
        </a>
        <a href="javascript:void(0);">
          <LazyLoadImage
            // PlaceholderSrc={
            //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
            // }
            className="app-img ms-2 hov-shadow2 hov-trans"
            src={apple}
            alt="apple store"
          />
        </a>
      </div>
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={"The email was successfully registered."}
      />
    </div>
  );
};

export default Application;
