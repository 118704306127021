import React from "react";
import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import "./MoarefiAlert.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MoarefiAlert = () => {
  const themee = createTheme({
    secondary: {
      light: "#ef5350",
      main: "#ef5350",
      dark: "#ef5350",
    },
  });
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <ThemeProvider theme={themee}>
        <Stack spacing={2} dir="rtl" className="align-items-center">
          <Snackbar
            open={open}
            autoHideDuration={600000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            className="alert-m-snackbar"
          >
            <Alert
              color="secondary"
              onClose={handleClose}
              icon={false}
              sx={{ width: "100%" }}
            >
              <div
                className="alert-m-body d-flex justify-content-between align-items-center "
                dir="ltr"
              >
                <div className=" align-self-center text-white alert-m-text  p-0">
                  Know any Farsi-speaking businesses?
                  <br />
                  <span>Introduce them to Gishniz!</span>
                </div>

                <Link
                  className="align-self-center color-242424 alert-m-btn alert-m-btn13 shadow-off hov-shadow-btn "
                  to="/introduce-business"
                >
                  Click here
                </Link>

                {/* </div> */}
              </div>
            </Alert>
          </Snackbar>
        </Stack>
      </ThemeProvider>
    </div>
  );
};

export default MoarefiAlert;
