import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import axios from "axios";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { Chip } from "@mui/material";
// styles
import "./header2.css";

import { DomainContext, LangIdContext, CookieContext } from "../../App";
// config
import configData from "../../config/config.json";
// components
import AlertError from "../alertError/AlertError";
// img
import searchBlack from "../../images/header/search black.svg";
import imageLoginwhite from "../../images/login img/user.svg";
import imageLogout from "../../images/login img/log-out gray.svg";
import nextLogin from "../../images/login img/play (1).svg";
import account from "../../images/login img/account.svg";
import searchImg from "../../images/header/search.svg";
import nextBiz from "../../images/next-blue.svg";
import regBiz from "../../images/header/business register.svg";
import loginBiz from "../../images/header/login business.svg";
import close from "../../images/header/close.png";

const Header2 = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const [farsiError, setFarsiError] = useState(false);

  const [whereValue, setWhereValue] = useState("");

  const [keyTime, setKeyTime] = useState("");
  const [keyTime2, setKeyTime2] = useState("");

  // auto completes
  const [whatInput, setWhatInput] = useState("");
  const [whereInput, setWhereInput] = useState("");
  const [bizList, setBizList] = useState([]);
  const [catList, setCatList] = useState([]);

  const [locList, setLocList] = useState([]);

  const [listComes, setListComes] = useState(false);
  const [listWhereComes, setListWhereComes] = useState(false);

  const [kojaValue, setKojaValue] = useState(null);
  const [kojaInputValue, setKojaInputValue] = useState("");

  const [chiValue, setChiValue] = useState(null);
  const [chiInputValue, setChiInputValue] = useState("");

  // for loading
  const [nullList, setNullList] = useState([]);
  const [nullListWhere, setNullListWhere] = useState([]);
  //
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const find_desc = queryParams.get("find_desc");
  const find_loc = queryParams.get("find_loc");
  const cat_id = queryParams.get("cat_id");
  const find_cat = queryParams.get("find_cat");

  useEffect(() => {
    // console.log("33333333330----");
    // console.log(queryParams.get("find_loc"));
    // console.log(find_loc == "");

    if (find_loc && find_loc !== "") {
      setKojaInputValue(find_loc.split("-").join(" "));
      setKojaValue(null);
    } else if (find_loc == "") {
      setKojaInputValue("");
      setKojaValue(null);
    }
    if (find_cat && find_cat !== "") {
      setChiInputValue(find_cat.split("-").join(" "));
    } else if (find_desc && find_desc !== "") {
      setChiInputValue(find_desc.split("-").join(" "));
    }
  }, [find_loc, find_cat, find_desc]);

  const chipDeleteHandleer = (key) => {
    let url = "";
    switch (key) {
      case 1:
        // find_cat
        setChiInputValue("");
        url =
          "/search?find_desc=" +
          find_desc +
          "&find_loc=" +
          find_loc +
          "&cat_id=&find_cat=";
        break;
      case 2:
        // find-desc
        setChiInputValue("");
        url =
          "/search?find_desc=&find_loc=" +
          find_loc +
          "&cat_id=" +
          cat_id +
          "&find_cat=" +
          find_cat;
        break;
      case 3:
        setKojaInputValue("");
        url =
          "/search?find_desc=" +
          find_desc +
          "&find_loc=&cat_id=" +
          cat_id +
          "&find_cat=" +
          find_cat;
        break;

      default:
        break;
    }
    history.push(url);
  };

  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState({ link: "", title: "" });
  const [catListHeader, setCatListHeader] = useState([
    {
      cId: "",
      icon: "",
      shortTitle: "",
      title: "",
      url: "",
    },
  ]);

  useEffect(() => {
    // console.log("open chnaged");
    // console.log(open);
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // history.push(go);
    // setOpen(false);
  };
  const handleClose2 = () => {
    if (
      !(
        (whereInput.length > 0 && !whereCome) ||
        chiInputValue == "" ||
        (kojaInputValue !== "" && kojaValue == null)
      )
    ) {
      setOpen(false);
      history.push(go);
    }
  };
  const handleClose3 = (url) => {
    // console.log("3 called");

    setOpen(false);
    history.push(url);
    // window.location.reload();
    setOpen(false);
  };
  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
  };

  const [showmenu, setshowmenu] = useState(false);
  const [showmenuBiz, setshowmenuBiz] = useState(false);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // delete all cookies logggggg outttt
  function deleteAllCookies() {
    localStorage.clear();

    // var cookies = document.cookie.split(";");

    // for (var i = 0; i < cookies.length; i++) {
    //   var cookie = cookies[i];
    //   var eqPos = cookie.indexOf("=");
    //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // }
    document.cookie = "MemId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    setCookieState("");

    setshowmenu(!showmenu);
  }

  const handleShow = () => {
    setshowmenu((prev) => !prev);
  };
  const handleShowAway = () => {
    setshowmenu(false);
  };

  const handleShowBiz = () => {
    setshowmenuBiz((prevState) => !prevState);
  };
  const handleShowAwayBiz = () => {
    setshowmenuBiz(false);
  };

  // const [whatInput, setWhatInput] = useState("");
  // const [whereInput, setWhereInput] = useState("");

  // var go =
  //   "/search?find_desc=" +
  //   whatInput.replace(/\s+/g, "-") +
  //   "&find_loc=" +
  //   whereInput.replace(/\s+/g, "-") +
  //   "&cat_id=&find_cat=";

  var go =
    "/search?find_desc=" +
    chiInputValue.replace(/\s+/g, "-") +
    "&find_loc=" +
    kojaInputValue.replace(/\s+/g, "-") +
    "&cat_id=&find_cat=";

  // useEffect(() => {
  //   if (whatInput.length > 1) {
  //     setCatList([]);
  //     setBizList([]);

  //     setListComes(false);

  //     axios
  //       .post(
  //         domain + "/api/search/what",
  //         { txtWhat: whatInput },
  //         {
  //           headers: header,
  //         }
  //       )
  //       .then((response) => {
  //         console.log(response.data);
  //         const arr = [...response.data.BizList, ...response.data.CatList];
  //         setCatList(arr);
  //       })
  //       .catch((error) => {
  //         // alert(error);
  //       });
  //   } else {
  //     setCatList([]);
  //   }
  // }, [whatInput]);

  // useEffect(() => {
  //   if (whereInput.length > 1) {
  //     // setCatList([]);
  //     // setBizList([]);

  //     setListWhereComes(false);

  //     axios
  //       .post(
  //         domain + "/api/search/where",
  //         { txtWhere: whereInput },
  //         {
  //           headers: header,
  //         }
  //       )
  //       .then((response) => {
  //         console.log("where log");
  //         console.log(response.data);
  //         // const arr = [...response.data.BizList, ...response.data.CatList];
  //         // setCatList(arr);
  //         setLocList(response.data.Locations);
  //       })
  //       .catch((error) => {
  //         // alert(error);
  //       });
  //   } else {
  //     setLocList([]);
  //   }
  // }, [whereInput]);

  const apiWhat = () => {
    if (whatInput.length > 1) {
      setCatList([]);
      setBizList([]);
      setListComes(false);
      // برای عدم نمایش این لیست بین سرج ها کامنت بشه فعلا
      // if (props.data.categoriesOnFocus) {
      //   if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
      //     var arrNull = [
      //       ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
      //       ...props.data.categoriesOnFocus,
      //     ];
      //     setNullList(arrNull);
      //   } else {
      //     setNullList(props.data.categoriesOnFocus);
      //   }
      // }

      axios
        .get(configData.API_DOMAIN + `/api/v1/Search/what/${whatInput}`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setNullList([]);
            const arr = [
              ...response.data.data.businesses,
              ...response.data.data.categories,
            ];
            setCatList(arr);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setCatList([]);
    }
  };

  useEffect(() => {
    if (whatInput.length > 1) {
      setCatList([]);
      setBizList([]);

      setListComes(false);

      clearTimeout(keyTime2);
      setKeyTime2(setTimeout(apiWhat, 1000));
    } else if (whatInput.length == 1 && props.data.categoriesOnFocus) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data.categoriesOnFocus,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data.categoriesOnFocus);
      }
    }
  }, [whatInput]);

  // for disabling
  const [whereCome, setWhereCome] = useState(false);

  const apiWhere = () => {
    if (kojaValue == null || kojaValue.title !== kojaInputValue) {
      setWhereCome(false);

      if (whereInput.length > 1) {
        setListWhereComes(false);
        setLocList([]);
        setNullListWhere([{ id: "001", title: "" }]);

        axios
          .get(configData.API_DOMAIN + `/api/v1/Search/where/${whereInput}`, {
            headers: header,
          })
          .then((response) => {
            if (response.data.isSuccess) {
              setNullListWhere([]);
              setLocList(response.data.data.locations);
              setWhereCome(true);
            }
          })
          .catch((error) => {
            // alert(error);
          });
      } else {
        setLocList([]);
      }
    }
  };

  useEffect(() => {
    if (whereInput.length > 1) {
      setListWhereComes(false);

      clearTimeout(keyTime);
      setKeyTime(setTimeout(apiWhere, 1000));
    } else if (whereInput.length == 1) {
      setLocList([]);
      setNullListWhere([{ id: "001", title: "" }]);
    }
  }, [whereInput]);

  useEffect(() => {
    if (whatInput.length <= 1 && props.data.categoriesOnFocus) {
      if (JSON.parse(localStorage.getItem("LastVisitBusinesses"))) {
        var arrNull = [
          ...JSON.parse(localStorage.getItem("LastVisitBusinesses")),
          ...props.data.categoriesOnFocus,
        ];
        setNullList(arrNull);
      } else {
        setNullList(props.data.categoriesOnFocus);
      }
    }
    // }, [props.data.categoriesOnFocus , JSON.parse(localStorage.getItem("LastVisitBusinesses"))]);
  }, [props.data.categoriesOnFocus]);

  // const options = props.data.map((option) => {
  const options = catList.map((option) => {
    if (option.bId || option.bId == "") {
      const op = option.title;
      return {
        op: "Recently Search",
        ...option,
      };
    } else {
      const op = option.title;
      return {
        op: "Category",
        ...option,
      };
    }
  });
  // for loading
  const optionss2 =
    nullList.length > 0
      ? nullList.map((option) => {
          if (option.bId || option.bId == "") {
            const op = option.title;
            return {
              op: "Last Visits",
              ...option,
            };
          } else {
            const op = option.title;
            return {
              op: "Category",
              ...option,
            };
          }
        })
      : [];

  const history = useHistory();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        !(
          (whereInput.length > 0 && !whereCome) ||
          chiInputValue == "" ||
          (kojaInputValue !== "" && kojaValue == null)
        )
      ) {
        history.push(go);
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (props.dataCome) {
      setLogo(props.data.headerLogo);
      setCatListHeader(props.data.categories);
    }
  }, [props.dataCome]);

  const lanHandler = (event) => {
    if (window.location.pathname == "/search") {
      window.location.href = `https://gishniz.link${
        window.location.pathname + window.location.search
      }`;
      // window.location.href = `https://gishniz.app${window.location.pathname + window.location.search}`;
    } else {
      // if (getCookie("_gapp_Locale") == "") {
      window.location.href = `https://gishniz.link${window.location.pathname}`;
      // window.location.href = `https://gishniz.app${window.location.pathname}`;
      // }
    }
  };

  return (
    <>
      <div className="header2">
        <div className="header2-container">
          <div className="d-flex justify-content-between px-2 mx-0 align-items-center">
            <div className=" d-flex justify-content-right align-items-center px-0">
              <Link to="/" className=" logo-header2-a">
                <img
                  src={logo.link}
                  alt={logo.title}
                  className="logo-header2-lap"
                />
              </Link>
              <div className="z2">
                <div className="searchinput-header2 me-2">
                  <div
                    className="first-input-what-container first-input-header2"
                    // onClick={handleClickOpen}
                  >
                    <div className=" auto7  w-100 auto-what-head2 auto-g auto-paper-space">
                      <Autocomplete
                        disabled={
                          ((find_cat && find_cat !== "") ||
                            (find_desc && find_desc !== "") ||
                            (find_loc && find_loc !== "")) &&
                          !open
                        }
                        onKeyDown={handleKeyDown}
                        onClick={handleClickOpen}
                        value={chiValue}
                        onChange={(event, newValue) => {
                          setChiValue(newValue);
                          // newValue.title,
                        }}
                        // inputValue={chiInputValue}
                        inputValue={!open ? "" : chiInputValue}
                        onInputChange={(event, newInputValue) => {
                          var result;
                          if (newInputValue == "undefined") {
                            result = chiInputValue;
                          } else {
                            result = newInputValue;
                          }
                          setChiInputValue(result);
                          setWhatInput(result);
                        }}
                        // open={true}
                        disableClearable
                        freeSolo={true}
                        className=""
                        disablePortal
                        openOnFocus={false}
                        id="grouped-demo"
                        // options={whatList}
                        filterOptions={(options, state) => options}
                        options={
                          // whatInput.length > 1 && nullList.length < 1
                          whatInput.length > 1
                            ? options.sort((a, b) => -a.op.localeCompare(b.op))
                            : optionss2.sort(
                                (a, b) => -a.op.localeCompare(b.op)
                              )
                        }
                        groupBy={(option) => option.op}
                        getOptionLabel={(option) => option.title}
                        getOptionDisabled={(option) =>
                          option.bId === "001" || option.cId === "001"
                        }
                        popupIcon=""
                        clearIcon
                        // id="auto7"
                        sx={{
                          fontFamily: "inherit",
                        }}
                        renderOption={
                          (props, option) => (
                            // whatInput.length > 0 ? (
                            <Box
                              component="li"
                              sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                              className="fs-4vw"
                              {...props}
                            >
                              {/* {(option.bId === "001" || option.cId === "001") &&  nullList.length > 0 ? ( */}
                              {options.length == 0 && nullList.length < 1 ? (
                                <div className="d-flex align-items-center px-3 my-1 w-100">
                                  <Skeleton
                                    variant="circular"
                                    width={30}
                                    height={26}
                                    animation="wave"
                                  >
                                    <Avatar animation="wave" />
                                  </Skeleton>
                                  <Skeleton
                                    className="mt-1 skeleton-currency"
                                    animation="wave"
                                  />
                                </div>
                              ) : option.bId || option.bId == "" ? (
                                <Link
                                  // to={option.url}
                                  className="width-100"
                                  onClick={() => handleClose3(option.url)}
                                >
                                  <div className="position-relative d-flex div-auto-g1 color-101010">
                                    <img
                                      src={option.photoPath}
                                      alt="search black"
                                      className=" ms-2  autocomplete-search-lap-bizphoto"
                                    />
                                    <div className="last1-auto">
                                      {option.title}
                                    </div>
                                    <div className="last2-auto">
                                      {option.location}
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                <div
                                  // onClick={handleClose}
                                  // to={option.url.replace(
                                  //   /({loc})/g,
                                  //   whereInput
                                  // )}
                                  className="width-100"
                                >
                                  <div className="position-relative d-flex div-auto-g2 color-101010">
                                    <img
                                      src={searchBlack}
                                      alt="search black"
                                      className=" autocomplete-search-lap"
                                    />
                                    {option.title}
                                  </div>
                                </div>
                              )}
                            </Box>
                          )
                          // ) : (
                          //   ""
                          // )
                        }
                        renderInput={(params) => (
                          <div className="d-flex position-relative">
                            <TextField
                              onClick={handleClickOpen}
                              id="auto7"
                              className=""
                              {...params}
                              placeholder={
                                open
                                  ? (find_cat && find_cat !== "") ||
                                    (find_desc && find_desc !== "")
                                    ? ""
                                    : "What are you looking for?"
                                  : (find_cat && find_cat !== "") ||
                                    (find_desc && find_desc !== "") ||
                                    (find_loc && find_loc !== "")
                                  ? ""
                                  : "What are you looking for?"
                              }
                            >
                              {" "}
                            </TextField>
                            {!open ? (
                              <div
                                className="header-chip-div"
                                onClick={handleClickOpen}
                              >
                                {find_cat && find_cat !== "" && !open ? (
                                  // <div
                                  //   className="header-chip-div"
                                  //   onClick={handleClickOpen}
                                  // >
                                  <Chip
                                    id="rb-chip"
                                    // dir="ltr"
                                    className="header-chip"
                                    label={find_cat.split("-").join(" ")}
                                    onDelete={() => {
                                      // if (list3.length == 1) {
                                      //   deleteHandler(item);
                                      //   setShowBtns(false);
                                      //   // setOther(false);
                                      // } else {
                                      //   deleteHandler(item);
                                      // }
                                      chipDeleteHandleer(1);
                                    }}
                                    deleteIcon={
                                      <img
                                        src={close}
                                        alt="X"
                                        className="s2-chip-icon"
                                      />
                                    }
                                  />
                                ) : (
                                  // </div>
                                  ""
                                )}
                                {find_desc && find_desc !== "" && !open ? (
                                  // <div
                                  //   className="header-chip-div"
                                  //   onClick={handleClickOpen}
                                  // >
                                  <Chip
                                    id="rb-chip"
                                    className="header-chip"
                                    // dir="ltr"
                                    label={find_desc.split("-").join(" ")}
                                    onDelete={() => {
                                      // if (list3.length == 1) {
                                      //   deleteHandler(item);
                                      //   setShowBtns(false);
                                      //   // setOther(false);
                                      // } else {
                                      //   deleteHandler(item);
                                      // }
                                      chipDeleteHandleer(2);
                                    }}
                                    deleteIcon={
                                      <img
                                        src={close}
                                        alt="X"
                                        className="s2-chip-icon"
                                      />
                                    }
                                  />
                                ) : (
                                  // </div>
                                  ""
                                )}
                                {find_loc && find_loc !== "" && !open ? (
                                  // <div
                                  //   className="header-chip-div"
                                  //   onClick={handleClickOpen}
                                  // >
                                  <Chip
                                    id="rb-chip"
                                    // dir="ltr"
                                    className="header-chip"
                                    label={find_loc.split("-").join(" ")}
                                    onDelete={() => {
                                      // if (list3.length == 1) {
                                      //   deleteHandler(item);
                                      //   setShowBtns(false);
                                      //   // setOther(false);
                                      // } else {
                                      //   deleteHandler(item);
                                      // }
                                      chipDeleteHandleer(3);
                                    }}
                                    deleteIcon={
                                      <img
                                        src={close}
                                        alt="X"
                                        className="s2-chip-icon"
                                      />
                                    }
                                  />
                                ) : (
                                  // </div>
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <MDBBtn
                    className="search-submit-btn search-submit-btn-head2"
                    onClick={handleClose2}
                    disabled={
                      (whereInput.length > 0 && !whereCome) ||
                      chiInputValue == "" ||
                      (kojaInputValue !== "" && kojaValue == null)
                    }
                  >
                    {/* <Link to={go}> */}{" "}
                    <img
                      src={searchImg}
                      className="search-img-header2"
                      alt="search img"
                    />
                    {/* </Link> */}
                  </MDBBtn>
                </div>
                {open ? (
                  <div className="z3 header2-box">
                    <div className=" auto7 mt-2">
                      <Autocomplete
                        // disabled={find_loc && find_loc !== ""}
                        onKeyDown={handleKeyDown}
                        value={kojaValue}
                        onChange={(event, newValue) => {
                          // console.log("newValue");
                          // console.log(newValue);
                          setKojaValue(newValue);

                          // to have city, province and country for search api of search page
                          localStorage.setItem(
                            "SearchLocation",
                            JSON.stringify(newValue)
                          );

                          if (
                            chiInputValue != "" ||
                            chiValue ||
                            find_desc ||
                            find_cat
                          ) {
                            let koja = newValue.title;
                            history.push(
                              "/search?find_desc=" +
                                chiInputValue.replace(/\s+/g, "-") +
                                "&find_loc=" +
                                koja.replace(/\s+/g, "-") +
                                "&cat_id=&find_cat="
                            );
                            setOpen(false);
                          }
                        }}
                        inputValue={kojaInputValue}
                        onInputChange={(event, newInputValue) => {
                          var result;
                          if (newInputValue == "undefined") {
                            result = kojaInputValue.replace(
                              /[^A-Za-z\s,]/gi,
                              ""
                            );
                          } else {
                            // for farsi error
                            var charCode = newInputValue.charCodeAt(
                              newInputValue.length - 1
                            );
                            if (
                              !(
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode >= 65 && charCode <= 90) ||
                                charCode == 32
                              ) &&
                              newInputValue !== ""
                            ) {
                              setFarsiError(true);
                              setTimeout(() => {
                                setFarsiError(false);
                              }, 4000);
                            } else {
                              setFarsiError(false);
                            }
                            // for farsi error
                            result = newInputValue.replace(
                              /[^A-Za-z\s,]/gi,
                              ""
                            );
                          }
                          setKojaInputValue(result);
                          setWhereInput(result);
                        }}
                        onBlur={() => setFarsiError(false)} // for farsi error
                        filterOptions={(options, state) => options}
                        disableClearable
                        freeSolo={true}
                        // open={true}
                        disablePortal
                        openOnFocus={false}
                        // onChange={(event, newValue) => {
                        // }}
                        popupIcon=""
                        clearIcon
                        id="auto7"
                        options={
                          whereInput.length > 1 && nullListWhere.length < 1
                            ? locList
                            : nullListWhere
                        }
                        getOptionLabel={(option) => option.title}
                        getOptionDisabled={(option) =>
                          option.id === "001" || option.id === ""
                        }
                        className=""
                        sx={{
                          fontFamily: "inherit",
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                            className="fs-4vw"
                            {...props}
                          >
                            {nullListWhere.length > 0 && option.id === "001" ? (
                              <div className="d-flex align-items-center px-2 w-100 ">
                                <Skeleton
                                  variant="circular"
                                  width={30}
                                  height={26}
                                  animation="wave"
                                >
                                  <Avatar animation="wave" />
                                </Skeleton>
                                <Skeleton
                                  className="mt-1 skeleton-currency"
                                  animation="wave"
                                />
                              </div>
                            ) : (
                              <>
                                <div className="">{option.title}</div>
                              </>
                            )}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <div className="d-flex position-relative">
                            <TextField
                              id="auto7"
                              className=""
                              {...params}
                              placeholder={
                                find_loc && find_loc !== "" ? "" : "Where?"
                              }
                            ></TextField>
                            {/* <div
                              className="header-chip-div"
                              onClick={handleClickOpen}
                            > */}
                            {find_loc && find_loc !== "" && !open ? (
                              <div
                                className="header-chip-div"
                                onClick={handleClickOpen}
                              >
                                <Chip
                                  id="rb-chip"
                                  // dir="ltr"
                                  className="header-chip"
                                  label={find_loc.split("-").join(" ")}
                                  onDelete={() => {
                                    chipDeleteHandleer(3);
                                  }}
                                  deleteIcon={
                                    <img
                                      src={close}
                                      alt="X"
                                      className="s2-chip-icon"
                                    />
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {/* </div> */}
                          </div>
                        )}
                      />
                    </div>

                    {/* <div className=" auto7">
                      <Autocomplete
                        value={nullList.label}
                        // onChange={(event, newValue) => {
                        // }}
                        popupIcon=""
                        // open={false}
                        clearIcon
                        disableClearable
                        disablePortal
                        openOnFocus={true}
                        id="auto7"
                        options={nullList}
                        className=""
                        sx={{
                          fontFamily: "inherit",
                        }}
                        // renderOption={(props, option) => (
                        //   <Box
                        //     component="li"
                        //     sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
                        //     className="fs-4vw"
                        //     {...props}
                        //   >
                        //     <img
                        //       src={searchBlack}
                        //       alt="search black"
                        //       className=" ms-2  autocomplete-search"
                        //     />
                        //     {option.label}
                        //   </Box>
                        // )}
                        renderInput={(params) => (
                          <div className="d-flex">
                            <TextField
                              id="auto7"
                              className=""
                              {...params}
                              placeholder="دسته بندی"
                            >
                              {" "}
                            </TextField>
                          </div>
                        )}
                      />
                    </div> */}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className="p-0 d-flex justify-content-end  align-items-center">
              {/* <div className="width-533px width-header2-p">
                <a
                  href={configData.BLOG_URL}
                  target="_blank"
                  className=" fs-18 color-text1-header2 width-533px"
                >
                  <p className=" mb-0 width-header2-p">مجله گیشنیز</p>
                </a>
              </div>
              <a
                href={`${configData.BIZ_URL}/choose-owner`}
                className="color-text2-header2 fs-18 align-items-center ps-1 ms-4 "
              >
                <p className=" mb-0">ثبت کسب‌وکار</p>
              </a> */}
              {/*comment for now thre is not blog */}
              {/* <div className="width-53px d-flex">
                <a
                  href={configData.BLOG_URL}
                  target="_blank"
                  className=" fs-18 color-5ae6a2 width-53px ms-4"
                >
                  <p className="sabt-hov mb-0 ">Blog</p>
                </a>
              </div> */}
              <MDBBtn className="chamge-lan-btn ms-0 me-0" onClick={lanHandler}>
                فا
              </MDBBtn>
              <ClickAwayListener onClickAway={handleShowAwayBiz}>
                <Box className="position-relative">
                  <div
                    className={
                      showmenuBiz
                        ? "select_login_head_biz  me-4 ms-2 px-3"
                        : "select_login_head_biz  me-4 ms-2 px-3"
                    }
                    // onMouseOver={() => setshowmenu(true)}
                    // onMouseOut={() => setshowmenu(false)}

                    onClick={handleShowBiz}
                    // onMouseDown ={() => setshowmenu(false)}
                  >
                    <a className="color-5ae6a2 fs-18 align-items-center text-end ">
                      <p className="mb-0 pe-2">Gishniz for Businesses</p>
                    </a>

                    <object
                      onClick={handleShowBiz}
                      className={
                        showmenuBiz
                          ? "next-biz nextLogin-rotate-biz"
                          : "next-biz nextLogin-rotateback-biz"
                      }
                      data={nextBiz}
                      alt=""
                      type="image/svg+xml"
                    ></object>
                  </div>
                  {showmenuBiz && (
                    <List
                      onMouseOver={() => setshowmenuBiz(true)}
                      onMouseOut={() => setshowmenuBiz(false)}
                      className="box_show_info_userlogin_biz"
                      component="nav"
                      aria-label="mailbox folders"
                    >
                      <a
                        // href={`${configData.BIZ_URL}/choose-owner`}
                        href={`${configData.BIZ_URL}/signup`}
                        target="_blank"
                      >
                        <ListItem button>
                          <object
                            data={regBiz}
                            className="box_show_info_biz_img"
                            alt="regBiz"
                            type="image/svg+xml"
                          ></object>
                          Add a business
                        </ListItem>
                      </a>
                      <Divider />
                      <a href={`${configData.BIZ_URL}/login`} target="_blank">
                        <ListItem button>
                          <object
                            data={loginBiz}
                            className="box_show_info_biz_img"
                            alt="regBiz"
                            type="image/svg+xml"
                          ></object>
                          Log in
                        </ListItem>
                      </a>
                    </List>
                  )}
                </Box>
              </ClickAwayListener>

              <ClickAwayListener onClickAway={handleShowAway}>
                <Box className="position-relative">
                  {getCookie("MemId") &&
                  JSON.parse(localStorage.getItem("MemberDetail")) ? (
                    <>
                      <div
                        className={
                          showmenu
                            ? "select_login_sucess select_login_sucess_color "
                            : "select_login_sucess select_login_sucess2"
                        }
                        // onMouseOver={() => setshowmenu(true)}
                        // onMouseOut={() => setshowmenu(false)}

                        onClick={handleShow}
                        // onMouseDown ={() => setshowmenu(false)}
                      >
                        {/* <img
                          className="imageLogin_success_header"
                          src={imageLoginwhite}
                          alt=""
                        /> */}
                        <div>
                          <object
                            onClick={handleShow}
                            className="imageLogin_success_header"
                            data={imageLoginwhite}
                            alt=""
                            type="image/svg+xml"
                          ></object>
                        </div>
                        {/* <img
                          className={
                            showmenu
                              ? "nextLogin nextLogin-rotate"
                              : "nextLogin nextLogin-rotateback"
                          }
                          src={nextLogin}
                          
                          type="image/svg+xml"
                          alt=""
                        /> */}
                        <object
                          onClick={handleShow}
                          className={
                            showmenu
                              ? "nextLogin nextLogin-rotate"
                              : "nextLogin nextLogin-rotateback"
                          }
                          data={nextLogin}
                          alt=""
                          type="image/svg+xml"
                        ></object>
                      </div>
                      {showmenu && (
                        <List
                          onMouseOver={() => setshowmenu(true)}
                          onMouseOut={() => setshowmenu(false)}
                          className="box_show_info_userlogin"
                          component="nav"
                          aria-label="mailbox folders"
                        >
                          <ListItem button>
                            <img
                              className="img20x20"
                              // src={imageLogin}
                              src={
                                JSON.parse(localStorage.getItem("MemberDetail"))
                                  .photo
                              }
                              alt="icon"
                            />
                            {
                              JSON.parse(localStorage.getItem("MemberDetail"))
                                .firstname
                            }{" "}
                            {
                              JSON.parse(localStorage.getItem("MemberDetail"))
                                .lastname
                            }
                          </ListItem>
                          <Divider />
                          <Link to={"/members/profile"}>
                            <ListItem button>
                              <img className="img12x12" src={account} alt="" />
                              Profile
                            </ListItem>
                          </Link>
                          <Divider />
                          <ListItem onClick={deleteAllCookies} button>
                            <img
                              className="img20x20"
                              src={imageLogout}
                              alt=""
                            />
                            Log out
                          </ListItem>
                        </List>
                      )}
                    </>
                  ) : (
                    <>
                      {/* // <select className="select_login_sucess">
                      //   <option></option>
                      //   <option>{getCookie("userName")}</option>
                      //   <option>مشاهده پروفایل</option>
                      //   <option>خروج</option>
                      // </select> */}
                      <Link className=" bg-head-btn" to="/login">
                        Sign in
                      </Link>
                    </>
                  )}
                </Box>
              </ClickAwayListener>

              {/* </MDBBtn> */}
            </div>
          </div>

          <div className="header2-list d-flex justify-content-start">
            {catListHeader &&
              catListHeader.map((item) => (
                <Link
                  key={item.cId}
                  to={"/" + item.url}
                  className="header2-list-a"
                >
                  {item.shortTitle}
                </Link>
              ))}
          </div>
        </div>
      </div>

      {open ? (
        <div className="business-bg-color" onClick={handleClose}></div>
      ) : (
        <div></div>
      )}

      {/* alert error */}
      <AlertError
        showAlert={farsiError}
        text={`Change the keyboard language to English.`}
      />
    </>
  );
};

export default Header2;
