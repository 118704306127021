import React from "react";
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "./result-card.css";
import Skeleton from "@mui/material/Skeleton";
import RateIcon from "../rate-icon/RateIcon";
import Avatar from "@mui/material/Avatar";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ResultCard = (props) => {
  let imageSrc = "";

  if (props.img) {
    imageSrc = props.img.includes(".png")
      ? props.img.replace(".png", "_162x162.png")
      : props.img.replace(".jpg", "_162x162.jpg");
  }

  return (
    <Link to={props.url} className="res-card-a w-auto">
      <MDBCard className="res-card hov-shadow">
        <MDBRow className="g-0">
          <MDBCol md="4">
            {props.pageChanged ? (
              <Skeleton
                variant="rectangular"
                className="res-card-img "
                animation="wave"
              />
            ) : (
              <LazyLoadImage
                // PlaceholderSrc={
                //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                // }
                className="res-card-img img-fluid"
                src={imageSrc}
                alt={props.title}
              />
            )}
          </MDBCol>
          <MDBCol md="8">
            <MDBCardBody>
              <MDBCardTitle className="res-card-tit">
                {props.pageChanged ? (
                  <Skeleton
                    className="skeleton-text1-rec"
                    variant="text"
                    sx={{ fontSize: "1.5rem" }}
                    width={120}
                    animation="wave"
                  />
                ) : (
                  <p className="mb-0">{props.title}</p>
                )}
                {props.pageChanged ? (
                  <Skeleton
                    variant="circular"
                    width={22}
                    height={22}
                    animation="wave"
                  >
                    <Avatar animation="wave" />
                  </Skeleton>
                ) : (
                  <>
                    <LazyLoadImage
                      // PlaceholderSrc={
                      //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                      // }
                      className="res-card-tit-img"
                      src={props.icon}
                      alt="country"
                    />
                  </>
                )}
              </MDBCardTitle>
              {props.pageChanged ? (
                <Skeleton
                  className="skeleton-text1-rec"
                  variant="text"
                  // sx={{ fontSize: "1.5rem" }}
                  width={120}
                  animation="wave"
                />
              ) : (
                <MDBCardText className="res-card-text1">
                  {props.desc1}
                  {/* مشاور املاک */}
                </MDBCardText>
              )}
              {props.pageChanged ? (
                <Skeleton
                  className="skeleton-text1-rec"
                  variant="text"
                  sx={{ fontSize: "0.6rem" }}
                  width={120}
                  animation="wave"
                />
              ) : (
                <MDBCardText className="res-card-text2 mt-md-0 mt-3">
                  {props.city}
                </MDBCardText>
              )}
              {/* { props.pageChanged ? (
                <Skeleton
                  className="skeleton-text1-rec"
                  variant="text"
                  sx={{ fontSize: "0.6rem" }}
                  width={120}
                  animation="wave"
                />
              ) : (
                <MDBCardText className="res-card-text2">
                  {props.country}
                </MDBCardText>
              )} */}

              {props.pageChanged ? (
                <Skeleton
                  className="skeleton-text1-rec mt-3"
                  variant="text"
                  width={120}
                  sx={{ fontSize: "1.2rem" }}
                  animation="wave"
                />
              ) : (
                <>
                  <div className="res-card-box2 mt-md-3 mt-1 pt-1 pb-1">
                    <RateIcon
                      //   cardrate={parseFloat(props.rate)}
                      cardrate={parseFloat(props.rate)}
                      isRec={0}
                      isAdd={1}
                      dir="ltr "
                    />
                  </div>
                  <br />
                </>
              )}
              {props.pageChanged ? (
                ""
              ) : (
                <div className="row biz-tags-container pt-2">
                  {/* Comment The tags for now */}
                  {/* {props.Tags &&
                    props.Tags.map((item) => (
                      <span key={item} className="biz-tags">
                        {item}
                      </span>
                    ))} */}
                </div>
              )}

              {/* { props.pageChanged ? (
                <>
                  <Skeleton
                    className="skeleton-text1-rec"
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    animation="wave"
                  />
                  <br />
                </>
              ) : (
                <div className="res-card-box">
                  <p>ایرانی</p>

                  <p>ایتالیایی</p>

                  <p>گیلانی</p>
                </div>
              )} */}

              {props.pageChanged ? (
                <>
                  <Skeleton
                    className="skeleton-text1-rec"
                    variant="text"
                    sx={{ fontSize: "0.5rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="skeleton-text1-rec mb-n2"
                    variant="text"
                    sx={{ fontSize: "0.5rem" }}
                    animation="wave"
                  />
                </>
              ) : (
                <MDBCardText className="res-card-text3 ">
                  <p className="show-1line mb-0">{props.tozih}</p> ...
                  <span>More</span>
                </MDBCardText>
              )}
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </Link>
  );
};

export default ResultCard;

// import React from "react";
// import { Link } from "react-router-dom";
// import {
//   MDBCard,
//   MDBCardTitle,
//   MDBCardText,
//   MDBCardBody,
//   MDBCardImage,
//   MDBRow,
//   MDBCol,
// } from "mdb-react-ui-kit";
// import "./result-card.css";
// import Skeleton from "@mui/material/Skeleton";
// import RateIcon from "../rate-icon/RateIcon";
// import Avatar from "@mui/material/Avatar";

// const ResultCard = (props) => {
//   return (
//     <Link to={props.url} className="res-card-a w-auto">
//       <MDBCard className="res-card hov-shadow">
//         <MDBRow className="g-0">
//           <MDBCol md="4">
//             {props.pageChanged ? (
//               <Skeleton
//                 variant="rectangular"
//                 className="res-card-img "
//                 animation="wave"
//               />
//             ) : (
//               <MDBCardImage
//                 className="res-card-img"
//                 src={props.img}
//                 alt={props.title}
//                 fluid
//               />
//             )}
//           </MDBCol>
//           <MDBCol md="8">
//             <MDBCardBody>
//               <MDBCardTitle className="res-card-tit">
//                 {props.pageChanged ? (
//                   <Skeleton
//                     className="skeleton-text1-rec"
//                     variant="text"
//                     sx={{ fontSize: "1.5rem" }}
//                     width={120}
//                     animation="wave"
//                   />
//                 ) : (
//                   <p className="mb-0">{props.title}</p>
//                 )}
//                 {props.pageChanged ? (
//                   <Skeleton
//                     variant="circular"
//                     width={22}
//                     height={22}
//                     animation="wave"
//                   >
//                     <Avatar animation="wave" />
//                   </Skeleton>
//                 ) : (
//                   <>
//                     <img
//                       className="res-card-tit-img"
//                       // src={props.icon}
//                       src="https://cdn.gishniz.link/flag/canada.png"
//                       alt="country"
//                     />
//                   </>
//                 )}
//               </MDBCardTitle>
//               {props.pageChanged ? (
//                 <Skeleton
//                   className="skeleton-text1-rec"
//                   variant="text"
//                   // sx={{ fontSize: "1.5rem" }}
//                   width={120}
//                   animation="wave"
//                 />
//               ) : (
//                 <MDBCardText className="res-card-text1">
//                   {props.desc1}
//                 </MDBCardText>
//               )}
//               {props.pageChanged ? (
//                 <Skeleton
//                   className="skeleton-text1-rec"
//                   variant="text"
//                   sx={{ fontSize: "0.6rem" }}
//                   width={120}
//                   animation="wave"
//                 />
//               ) : (
//                 <MDBCardText className="res-card-text2">
//                   {props.city}
//                 </MDBCardText>
//               )}
//               {/* { props.pageChanged ? (
//                 <Skeleton
//                   className="skeleton-text1-rec"
//                   variant="text"
//                   sx={{ fontSize: "0.6rem" }}
//                   width={120}
//                   animation="wave"
//                 />
//               ) : (
//                 <MDBCardText className="res-card-text2">
//                   {props.country}
//                 </MDBCardText>
//               )} */}

//               {props.pageChanged ? (
//                 <Skeleton
//                   className="skeleton-text1-rec"
//                   variant="text"
//                   width={120}
//                   sx={{ fontSize: "1.2rem" }}
//                   animation="wave"
//                 />
//               ) : (
//                 <>
//                   <div className="res-card-box2 mt-md-2">
//                     <RateIcon
//                       //   cardrate={parseFloat(props.rate)}
//                       cardrate={parseFloat(props.rate)}
//                       isRec={0}
//                       isAdd={1}
//                       dir="ltr "
//                     />
//                   </div>
//                   <br />
//                 </>
//               )}

//               { props.pageChanged ? (
//                 <>
//                   <Skeleton
//                     className="skeleton-text1-rec"
//                     variant="text"
//                     sx={{ fontSize: "1rem" }}
//                     animation="wave"
//                   />
//                   <br />
//                 </>
//               ) : (
//                 <div className="res-card-box">
//                   <p>Gilac</p>
//                   <p>Italian</p>
//                   <p>Irani</p>
//                 </div>
//               )}

//               {props.pageChanged ? (
//                 <>
//                   <Skeleton
//                     className="skeleton-text1-rec"
//                     variant="text"
//                     sx={{ fontSize: "0.5rem" }}
//                     animation="wave"
//                   />
//                   <Skeleton
//                     className="skeleton-text1-rec mb-n2"
//                     variant="text"
//                     sx={{ fontSize: "0.5rem" }}
//                     animation="wave"
//                   />
//                 </>
//               ) : (
//                 <MDBCardText className="res-card-text3 ">
//                   <p className="show-2line mb-0">{props.tozih}</p> ...
//                   <span>More</span>
//                 </MDBCardText>
//               )}
//             </MDBCardBody>
//           </MDBCol>
//         </MDBRow>
//       </MDBCard>
//     </Link>
//   );
// };

// export default ResultCard;
