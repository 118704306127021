import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../images/home-body/logo gishniz.svg";
import "./Slider.css";
import { MDBListGroupItem, MDBIcon } from "mdb-react-ui-kit";
// imgages
import User from "../../../images/end user/user (1).svg";
import greenUser from "../../../images/end user/user f(1).svg";
import resetpass from "../../../images/end user/reset-password.svg";
import resetpassGreen from "../../../images/end user/reset-password green.svg";
import save from "../../../images/end user/save-line black.svg";
import saveGreen from "../../../images/end user/save-line.svg";
import comment from "../../../images/end user/comment.svg";
import commentGreen from "../../../images/end user/comment green.svg";
import payam from "../../../images/end user/send (1).svg";
import payamGreen from "../../../images/end user/send (1) green.svg";
import exit from "../../../images/end user/log-out.svg";
import exitGreen from "../../../images/end user/log-out green.svg";

const Sidbar = () => {
  const location = useLocation().pathname;

  return (
    <div className="container dashbord-side">
      <div className="text-align-last-center">
        <Link to="/">
          <img src={logo} alt="logoGishniz" className="img-sidbar-logo" />
        </Link>
      </div>
      <div className="mt-120">
        <Link to={"/members/profile"}>
          <MDBListGroupItem
            noBorders
            className={
              location === "/members/profile"
                ? "chooseitem eu-mx-12px d-flex align-items-center"
                : "click eu-mx-12px d-flex align-items-center"
            }
          >
            {location === "/members/profile" ? (
              <img src={greenUser} alt="user" className={"img_slider_true"} />
            ) : (
              <img src={User} alt={"user"} className={"img_slider_false"} />
            )}
            Profile
          </MDBListGroupItem>
        </Link>
        <Link to={"/members/change-password"}>
          <MDBListGroupItem
            noBorders
            className={
              location === "/members/change-password"
                ? "chooseitem eu-mx-12px d-flex align-items-center"
                : "click eu-mx-12px d-flex align-items-center"
            }
          >
            {" "}
            {location === "/members/change-password" ? (
              <img
                src={resetpassGreen}
                alt="user"
                className={"img_slider_true"}
              />
            ) : (
              <img
                src={resetpass}
                alt={"user"}
                className={"img_slider_false"}
              />
            )}
            Password
          </MDBListGroupItem>
        </Link>
        <Link to={"/members/saved-business"}>
          <MDBListGroupItem
            noBorders
            className={
              location === "/members/saved-business"
                ? "chooseitem eu-mx-12px d-flex align-items-center"
                : "click eu-mx-12px d-flex align-items-center"
            }
          >
            {location === "/members/saved-business" ? (
              <img src={saveGreen} alt="user" className={"img_slider_true"} />
            ) : (
              <img src={save} alt={"user"} className={"img_slider_false"} />
            )}
            Favorite
          </MDBListGroupItem>
        </Link>
        <Link to={"/members/reviews"}>
          <MDBListGroupItem
            noBorders
            className={
              location === "/members/reviews"
                ? "chooseitem eu-mx-12px d-flex align-items-center"
                : "click eu-mx-12px d-flex align-items-center"
            }
          >
            {location === "/members/reviews" ? (
              <img
                src={commentGreen}
                alt="user"
                className={"img_slider_true"}
              />
            ) : (
              <img src={comment} alt={"user"} className={"img_slider_false"} />
            )}
            Comment
          </MDBListGroupItem>
        </Link>
        <Link to={"/members/messages"}>
          <MDBListGroupItem
            noBorders
            className={
              location === "/members/messages"
                ? "chooseitem eu-mx-12px d-flex align-items-center"
                : "click eu-mx-12px d-flex align-items-center"
            }
          >
            {location === "/members/messages" ? (
              <img src={payamGreen} alt="user" className={"img_slider_true"} />
            ) : (
              <img src={payam} alt={"user"} className={"img_slider_false"} />
            )}
            Message
          </MDBListGroupItem>
        </Link>
        <Link to={"/members/exit"}>
          <MDBListGroupItem
            noBorders
            className={
              location === "/members/exit"
                ? "chooseitem eu-mx-12px d-flex align-items-center "
                : "click eu-mx-12px  d-flex align-items-center"
            }
          >
            {location === "/members/exit" ? (
              <img src={exitGreen} alt="user" className={"img_slider_true"} />
            ) : (
              <img src={exit} alt={"user"} className={"img_slider_false"} />
            )}
            Log out
          </MDBListGroupItem>
        </Link>
      </div>
    </div>
  );
};

export default Sidbar;
