export const validate = (data, type) => {
  const errors = {};

  if (type === "login") {
    // .toString().trim()
    if (!data.username.toString().trim()) {
      errors.username = "Please complete this field.";
    } else {
      delete errors.username;
    }

    if (!data.password.toString().trim()) {
      errors.password = "Please complete this field.";
    } else if (data.password.toString().trim().length < 8) {
      errors.password =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.password;
    }
  }
  // sign up
  if (type === "signup") {
    // if (!data.fname.toString().trim()) {
    //   errors.fname = "Please complete all fields.";
    // } else {
    //   delete errors.fname;
    // }

    // if (!data.lname.toString().trim()) {
    //   errors.lname = "Please complete all fields.";
    // } else {
    //   delete errors.lname;
    // }
    if (!data.email) {
      errors.email = "Please complete this field.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "The email address is invalid.";
    } else {
      delete errors.email;
    }
    if (!data.username.toString().trim()) {
      errors.username = "Please complete this field.";
    } else {
      delete errors.username;
    }
    if (!data.password) {
      errors.password = "Please complete this field.";
    } else if (data.password.toString().trim().length < 8) {
      errors.password =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.password;
    }
    if (!data.isAccepted) {
      errors.isAccepted = "Please agree to all the terms and conditions.";
    } else {
      delete errors.isAccepted;
    }
  }
  // forget pass
  if (type === "forget-pass") {
    // .toString().trim()
    if (!data.email) {
      errors.email = "Please complete this field.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "The email address is invalid.";
    } else {
      delete errors.email;
    }
  }
  // change pass
  if (type === "change-pass") {
    if (!data.password.toString().trim()) {
      errors.password = "Please complete this field.";
    } else if (data.password.toString().trim().length < 8) {
      errors.password =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.password;
    }

    if (!data.repeatPassword.toString().trim()) {
      errors.repeatPassword = "Please complete this field.";
    } else if (data.password !== data.repeatPassword) {
      errors.repeatPassword = "Password does not match.";
    } else if (data.repeatPassword.toString().trim().length < 8) {
      errors.repeatPassword =
        "Your password should be at least 8 characters or longer.";
    } else if (data.repeatPassword.toString().trim().length < 8) {
      errors.repeatPassword =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.repeatPassword;
    }
  }

  //
  if (type === "introduce-business") {
    if (!data.engName.toString().trim()) {
      errors.engName = "Please complete this field.";
    } else {
      delete errors.engName;
    }
    if (!data.countryy.toString().trim()) {
      errors.countryy = "Please complete this field.";
    } else {
      delete errors.countryy;
    }
    if (!data.provincee.toString().trim()) {
      errors.provincee = "Please complete this field.";
    } else {
      delete errors.provincee;
    }
    if (!data.cityy.toString().trim()) {
      errors.cityy = "Please complete this field.";
    } else {
      delete errors.cityy;
    }
  }

  if (type === "register-business-s1") {
    if (!data.engName.toString().trim()) {
      errors.engName = "Please complete this field.";
    } else {
      delete errors.engName;
    }
    // if (!rbdata2.toString().trim()) {
    //   errors.countryy = "Please complete all fields.";
    // } else {
    //   delete errors.countryy;
    // }
    // if (!data.provincee.toString().trim()) {
    //   errors.provincee = "Please complete all fields.";
    // } else {
    //   delete errors.provincee;
    // }
    // if (!data.cityy.toString().trim()) {
    //   errors.cityy = "Please complete all fields.";
    // } else {
    //   delete errors.cityy;
    // }
  }
  if (type === "register-business-s4") {
    if (!data.countryy.toString().trim()) {
      errors.countryy = "Please complete this field.";
    } else {
      delete errors.countryy;
    }
  }
  // contact us
  if (type === "contact") {
    if (!data.name.toString().trim()) {
      errors.name = "Please complete this field.";
    } else {
      delete errors.name;
    }
    if (!data.email) {
      errors.email = "Please complete this field.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "The email address is invalid.";
    } else {
      delete errors.email;
    }
  }
  // advertise
  if (type === "advertise") {
    if (!data.Business.toString().trim()) {
      errors.Business = "Please complete this field.";
    } else {
      delete errors.Business;
    }
    if (!data.Countryy.toString().trim()) {
      errors.Countryy = "Please complete this field.";
    } else {
      delete errors.Countryy;
    }
    if (!data.Cityy.toString().trim()) {
      errors.Cityy = "Please complete this field.";
    } else {
      delete errors.Cityy;
    }
    if (!data.Emaill) {
      errors.Emaill = "Please complete this field.";
    } else if (!/\S+@\S+\.\S+/.test(data.Emaill)) {
      errors.Emaill = "The email address is invalid.";
    } else {
      delete errors.Emaill;
    }
  }
  // write comment
  if (type === "writeComment") {
    if (!data.onvan.toString().trim()) {
      errors.onvan = "Please complete this field.";
    } else {
      delete errors.onvan;
    }
    if (!data.nazar.toString().trim()) {
      errors.nazar = "Please complete this field.";
    } else {
      delete errors.nazar;
    }
  }

  if (type === "application") {
    if (!data.email) {
      errors.email = "Please complete this field.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "The email address is invalid.";
    } else {
      delete errors.email;
    }
  }
  // change pass dashboard
  if (type === "change-pass-dashboard") {
    if (!data.OldPassword.toString().trim()) {
      errors.OldPassword = "Please complete this field.";
    } else if (data.OldPassword.toString().trim().length < 8) {
      errors.OldPassword =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.OldPassword;
    }

    if (!data.NewPassword.toString().trim()) {
      errors.NewPassword = "Please complete this field.";
    } else if (data.NewPassword.toString().trim().length < 8) {
      errors.NewPassword =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.NewPassword;
    }

    if (!data.RepeatNewPassword.toString().trim()) {
      errors.RepeatNewPassword = "Please complete this field.";
    } else if (data.NewPassword !== data.RepeatNewPassword) {
      errors.RepeatNewPassword = "Password does not match.";
    } else if (data.RepeatNewPassword.toString().trim().length < 8) {
      errors.RepeatNewPassword =
        "Your password should be at least 8 characters or longer.";
    } else {
      delete errors.RepeatNewPassword;
    }
  }

  return errors;
};
