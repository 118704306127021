import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { MDBFooter, MDBIcon } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./footer.css";
import { UrlChecker } from "../url-checker/UrlChecker";

import facebook from "../../images/footer/facebook foter.png";
import youtube from "../../images/footer/youtube foter.png";
import instagram from "../../images/footer/instagram foter.png";
import twitter from "../../images/footer/twitter foter.png";
import logo from "../../images/footer/logo gishniz.png";

import { DomainContext, LangIdContext } from "../../App";

const Footer = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [changeUrl, setchangeUrl] = useState(false);

  const [country, setCountry] = useState([{}, {}]);
  const [language, setLanguage] = useState([{}, {}]);
  const [menu, setMenu] = React.useState([
    {
      columnTitle: "",
      items: [
        { link: "1", title: "" },
        { link: "2", title: "" },
      ],
    },
    {
      columnTitle: "",
      items: [
        { link: "1", title: "" },
        { link: "2", title: "" },
      ],
    },
  ]);

  const handleChange2 = (event) => {
    setCountry(event.target.value);
  };

  const lanHandler = (event) => {
    for (let index = 0; index < language.length; index++) {
      const element = language[index];
      if (element.languageTitle == event.target.value) {
        if (element.locale == "") {
          // setCookie("_gapp_Locale", element.locale, 365);
          // setCookie("_gapp_LId", element.lId, 365);
          window.location.href = `https://gishniz.link${window.location.pathname}`;
          // window.location.href = `https://gishniz.app${window.location.pathname}`;
          // window.location.href = `https://localhost:3000${window.location.pathname}`;
        } else {
          // setCookie("_gapp_Locale", element.locale, 365);
          // setCookie("_gapp_LId", element.lId, 365);
          window.location.href = `https://${element.locale}.gishniz.link${window.location.pathname}`;
          // window.location.href = `https://${element.locale}.gishniz.app${window.location.pathname}`;
          // window.location.href = `https://${element.locale}.localhost:3000${window.location.pathname}`;
        }
      }
    }
    // if (event.target.value == "فارسی") {
    //   console.log("11");
    //   window.location.href = `https://gishniz.app${window.location.pathname}`;
    // }
    // console.log(event.target.value);
    // for (let index = 0; index < language.length; index++) {
    //   const element = language[index];
    //   if (element.languageTitle == event.target.value) {
    //     setCookie("_gapp_langId", element.lId, 365);
    //     setCookie("_gapp_locale", element.locale, 365);
    //     setCookie("_gapp_lTitle", event.target.value, 365);
    //   }
    // }
    // setchangeUrl(!changeUrl);
  };

  // useEffect(() => {
  //   UrlChecker();
  // }, [changeUrl]);

  const [data, setData] = useState({
    footerLogo: { link: "", title: "" },
    socialMedias: [{}],
  });

  useEffect(() => {
    if (props.dataCome) {
      setData(props.data);
      setLanguage(props.data.languages);
      setCountry(props.data.countries);
      setMenu(props.data.footerMenuItems);
    }
  }, [props.dataCome]);

  //setCookie
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      <MDBFooter
        className="text-center text-lg-end bg-footer height-287-footer "
        dir="ltr"
      >
        <section className="container text-center py-2 ">
          <div className="py-1">
            {data.socialMedias &&
              data.socialMedias.map((item) => (
                <a
                  key={item.socialAddress}
                  href={item.socialAddress}
                  className="mx-10px text-reset foot-hover"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage
                    // PlaceholderSrc={
                    //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                    // }
                    className="footer-icon"
                    src={item.icon}
                    alt={item.title}
                  />
                </a>
              ))}
          </div>
        </section>
        <hr className="my-0" />

        <section className="">
          <div className="container text-start text-md-start mt-3">
            <div className="row ps-3 ps-lg-0">
              <div className="col-6 col-sm-3 col-md-3 col-lg-2 col-xl-2 fs-14 fs-xs-14 mx-auto mt-2 ">
                <p className="footer-tit1">{menu[0].columnTitle}</p>
                <div className=" mb-sm-4 ">
                  {menu[0].items.map((item) => (
                    <p className="mb-0" key={item.title}>
                      <a href={item.link} className="foot-hover footer-text1 ">
                        {item.title}
                      </a>
                    </p>
                  ))}
                </div>
              </div>

              <div className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 mx-auto fs-14 fs-xs-14 mt-2 ">
                <p className="footer-tit1">{menu[1].columnTitle}</p>
                <div className="">
                  {menu[1].items.map((item) => (
                    <p className="mb-0" key={item.title}>
                      <a href={item.link} className="foot-hover footer-text1">
                        {item.title}
                      </a>
                    </p>
                  ))}
                </div>
              </div>

              <div className="col-0 col-sm-2 col-md-4 col-lg-5 col-xl-6 mx-auto "></div>

              <div className="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-1 mx-auto  mt-0 mt-sm-5 pe-lg-0">
                <div className="mb-3 mb-sm-4 mt-4  text-align-last-left">
                  <h6 className="footer-tit2 ">Language</h6>
                  <select
                    onChange={lanHandler}
                    // onChange={handleChange2}
                    id="country"
                    className="select-footer bg-footer text-white fs-12 fs-xs-12  "
                    icon="camera-retro"
                  >
                    {language.map((item) => (
                      <option
                        key={item.lId}
                        value={item.languageTitle}
                        selected={item.locale == getCookie("_gapp_Locale")}
                      >
                        {item.languageTitle}
                      </option>
                    ))}
                    {/* <option value="canada">America</option> */}
                  </select>
                </div>
                <div className="mb-sm-4 mb-4 text-align-last-left">
                  <h6 className="footer-tit2">Country</h6>
                  <select
                    // onChange={handleChange2}
                    id="country"
                    className="select-footer bg-footer text-white fs-12 fs-xs-12  "
                    icon="camera-retro"
                  >
                    {country.map((item) => (
                      <option key={item.id} value={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MDBFooter>

      <div className="text-center d-flex align-self-center footer-rights">
        <div className="container align-self-center ">
          <div className="row ">
            <div className="col-8 col-sm-8 align-self-center text-start pe-0">
              <p className="m-0 fs-14 fs-xs-12 fw-light ps-3 ps-lg-0">
                All material and intellectual rights of this website It belongs
                to Vikifa group
              </p>
            </div>
            <div className="col-4 col-sm-4 text-end ps-0">
              <p className="m-0 fs-8 align-self-center pe-lg-0 pe-2 pe-md-5 ">
                <Link className="" to="/">
                  <LazyLoadImage
                    // PlaceholderSrc={
                    //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                    // }
                    src={data.footerLogo.link}
                    alt={data.footerLogo.title}
                    title=""
                    height="25px"
                    className="mt-10 mb-2 footer-logo ms-1"
                  />
                </Link>
                <br></br>
                Copyright © 2022 Gishniz
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
