import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
// import GoogleLogin from "react-google-login";
import { MDBBtn } from "mdb-react-ui-kit";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
// import jwt_decode from "jwt-decode";
import axios from "axios";
import { DomainContext, LangIdContext, CookieContext } from "../../App";
import { useHistory } from "react-router-dom";
import google_login from "../../images/google-login.svg";
// config
import configData from "../../config/config.json";

import LoadingModal from "../../components/loading-modal/LoadingModal";

const Google = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);
  // cookie context
  const { cookieState, setCookieState } = useContext(CookieContext);

  const [dataLoad, setDataLoad] = useState(false);

  const [apiError, setApiError] = useState([]);

  const history = useHistory();

  //google api header
  const header = {};

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      GApi(codeResponse.access_token);
    },
  });

  const [googleText, setGT] = useState({});
  const [ApiText, setAT] = useState({});

  const GApi = (token) => {
    setApiError([]);
    setDataLoad(true);

    axios
      .get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`,
        { headers: header }
      )
      .then((response) => {
        var data = response.data;

        if (data.email_verified === true) {
          const dataPost = {
            email: data.email,
            username: null,
            password: null,
            firstname: data.given_name ? data.given_name : "",
            lastname: data.family_name ? data.family_name : "",
            photoPath: data.picture ? data.picture : "",
            oAuthUID: data.sub,
            oAuthProvider: "google",
            locale: data.locale ? data.locale : "",
          };

          axios
            .post(
              // domain + "/api/biz-provider-auth",
              configData.API_DOMAIN + "/api/v1/Members",
              dataPost,
              {
                headers: {
                  ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
                  LangId: getCookie("_gapp_LId"),
                },
              }
            )
            .then((response) => {
              setDataLoad(false);

              if (response.data.isSuccess) {
                setCookie("token", response.data.data.token.token, 1);
                setCookie("MemId", response.data.data.memberDetail.mId, 1);

                localStorage.setItem(
                  "MemberDetail",
                  JSON.stringify(response.data.data.memberDetail)
                ); //set local
                setCookieState(response.data.data.memberDetail.mId); // set context

                if (props.backUrl == null) {
                  history.push("/");
                } else {
                  history.push(props.backUrl);
                }
              } else {
                // isSuccess = false
                setApiError(response.data.message);
              }

              // if (response.data.msg == "done") {
              //   setCookie("OwnerId", response.data.OwnerDetail.OwnerId, 1);
              //   setCookie("BizId", response.data.OwnerDetail.BizId, 1);
              //   localStorage.setItem(
              //     "OwnerDetail",
              //     JSON.stringify(response.data.OwnerDetail)
              //   ); //set local
              //   // setcookie to use in dashboard
              //   setCookie("CatIds", response.data.OwnerDetail.CatIds, 1);

              //   history.push("/register-business-step1");
              //   // props.history.push({
              //   //   pathname: "/register-business-step1",
              //   //   // state: response.data.OwnerDetail.OwnerId, // your data array of objects
              //   // });
              // } else if (response.data.msg == "error") {
              //   console.log("error");
              //   // setServerErr(true);
              // } else if (response.data.msg == "null-data") {
              //   console.log("fill-all");
              //   // setFillErr(true);
              // }
            })
            .catch((error) => {
              // alert(error);
            });
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  //setCookie
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      <MDBBtn
        className="shadow-off account-login-btn text-start"
        id="logbtn"
        onClick={() => login()}
      >
        <img className="account-login-img" src={google_login} alt="" />
        Continue with Google
      </MDBBtn>
      {apiError.map((item) => (
        <span className="input-error">{item}</span>
      ))}
      {dataLoad ? <LoadingModal search={false} /> : ""}
    </>
  );
};

export default Google;
