import React from "react";
import { MDBCard } from "mdb-react-ui-kit";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./currency-card.css";

const CurrencyCard = (props) => {
  const commafy = (num) => {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    return str.join(".");
  };

  return (
    // <a href="" className="">
    <MDBCard className="continter mt-sm-3 mt-2 shadow-off currency-card color-101010 ">
      <div className="g-0 d-flex align-items-center justify-content-between">
        {!props.datacome ? (
          <div className="d-flex align-items-center justify-content-between px-3 w-100">
            <Skeleton
              variant="circular"
              width={32}
              height={30}
              animation="wave"
            >
              <Avatar animation="wave" />
            </Skeleton>
            <Skeleton className="mt-1 skeleton-currency" animation="wave" />
          </div>
        ) : (
          <>
            <div className=" text-end fs-xs-15">
              <LazyLoadImage
                // PlaceholderSrc={
                //   "https://cdn.gishniz.link/business/1/1820/Cover_202281510232610557514.png"
                // }
                className="curr-img ms-3 me-1 img-fluid"
                // src={}
                src={props.icon}
                alt={props.title}
              />
              {props.title}
            </div>
            <div className=" pe-3 text-start align-items-center fs-xs-14">
              <span>{commafy(props.price)}</span>
            </div>
          </>
        )}
      </div>
    </MDBCard>
    // </a>
  );
};

export default CurrencyCard;
