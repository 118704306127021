import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import "./NotFound.css";
import { DomainContext, LangIdContext } from "../../App";
// config
import configData from "../../config/config.json";

import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";

// images
import main from "../../images/NotFound/Artboard 1.svg";
import right from "../../images/NotFound/Artboard 2.svg";
import left from "../../images/NotFound/Artboard 3.svg";

const NotFound = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  useEffect(() => {
    if (langIdState !== "") {
      //api header
      const header = {
        ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
        LangId: langIdState,
      };
      // 1 header-other
      axios
        .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setheaderData(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              headerbiz: true,
            }));
          }
        })
        .catch((error) => {
          // alert(error);
        });
      // 2 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [langIdState]);

  return (
    <>
      <div className="d-none d-md-block">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res "></div>
      </div>

      <MDBContainer>
        <MDBRow>
          <MDBCol size="12">
            <h3 className="header_not_found mb-md-3">
              We could not find this page !
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" md="7" lg="5" order="md-2">
            <div className="mt-30notfound">
              <img src={main} alt="" className="img_notfound" />
              <img className="img_right_notfound" src={right} alt="" />
              <img className="img_left_notfound" src={left} alt="" />
            </div>
          </MDBCol>
          <MDBCol size="12" md="5" lg="7" order="md-1">
            {/* <h3 className="header_not_found2">برو به:</h3> */}

            <ul className="list_notfound">
              <div className="d-flex d-md-block justify-content-center">
                <li>
                  <Link to={"/"} className="button_not_found hov-shadow-btn">
                    Home
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/"} className="button_not_found hov-shadow-btn">
                    Blog
                  </Link>
                </li> */}
              </div>
              <div className="d-flex d-md-block justify-content-center">
                <li>
                  <Link to={"/"} className="button_not_found hov-shadow-btn">
                    Attoreny
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="button_not_found hov-shadow-btn">
                    Real state
                  </Link>
                </li>
              </div>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer data={footerData} dataCome={ApiDataCome.footer} />
    </>
  );
};

export default NotFound;
