import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  useMapEvent,
  Polygon,
  ZoomControl,
} from "react-leaflet";
import axios from "axios";
import L from "leaflet";

import "./draggable-map.css";
import ChangeView from "./ChangeView";
import { MDBBtn } from "mdb-react-ui-kit";

//  Animated panning
function SetViewOnClick({ animateRef }) {
  const map = useMapEvent("click", (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    });
  });

  return null;
}

function DraggableMap(props) {
  const animateRef = useRef(true);
  const { locationData, setLocationData, setSaveAdd } = props;

  let ttt = [];

  const [markerOrSearch, setMarkerOrSearch] = useState(false);

  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState([49.01095, -96.615234]);
  const [positionCome, setPositionCome] = useState(false);
  const markerRef = useRef(null);
  const mapRef = useRef(null);

  //red icon
  function GetIcon(_iconSize) {
    return L.icon({
      iconUrl: require("../../images/result/address red.png"),
      iconSize: [_iconSize],
      className: "map-icon-m",
    });
  }
  const [MarkerInfo, setMarkerInfo] = useState("");
  // for first red icon
  const [firstLoadLoc, setFirstLoadLoc] = useState(false);

  useEffect(() => {
    setPositionCome(false);
    if (
      locationData &&
      locationData.Lat &&
      locationData.Lat !== "" &&
      locationData.Lng &&
      locationData.Lng !== ""
    ) {
      // setPosition([locationData.Lat, locationData.Lng]);
      setMapZoom(16);
      // for first red icon
      setPosition([locationData.Lat - 0.00035, locationData.Lng]);
      setFirstLoadLoc(true);
    } else {
      setPosition([49.01095, -96.615234]);
      // for red icon
      setFirstLoadLoc(false);
    }
    setPositionCome(true);
  }, []);

  const submitHandler = () => {
    const map = mapRef.current;
    if (map != null) {
      if (MarkerInfo !== "") {
        map.removeLayer(MarkerInfo);
      }
      // for first red icon
      setFirstLoadLoc(false);

      setMarkerOrSearch(false);
      setPosition(map.getCenter());
      setLocationData({
        ...locationData,
        Lat: map.getCenter().lat,
        Lng: map.getCenter().lng,
      });
      setMapZoom(map.getZoom());
      // add marker
      var theMarker = new L.Marker([map.getCenter().lat, map.getCenter().lng], {
        icon: GetIcon(37),
      }).addTo(map);
      // console.log(theMarker);
      setMarkerInfo(theMarker);

      setPosition({
        ...position,
        lat: map.getCenter().lat - 0.00035,
        lng: map.getCenter().lng,
      });
    }
    // axios
    //   .get(
    //     `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${
    //       map.getCenter().lat
    //     }&lon=${map.getCenter().lng}`,
    //     {
    //       // headers: header,
    //     }
    //   )
    //   .then((response) => {
    //     // console.log(response.data);
    //   })
    //   .catch((error) => {
    //     // alert(error);
    //   });
    setSaveAdd(true);
  };

  const reverseFunction = () => {
    if (markerOrSearch) {
      try {
        ttt.push(data.map((item) => item.reverse()));
      } catch (e) {
        // console.log("error handled");
      }
    } else {
      try {
        ttt.push(data.map((item) => item));
      } catch (e) {
        // console.log("error handled");
      }
    }
  };

  const purpleOptions = { color: "#005589", fillColor: "transparent" };

  // search data
  const [searchLoc, setSearchLoc] = useState("");
  // Map Center and zoom
  const [mapCenter, setMapCenter] = useState([49.01095, -96.615234]);
  const [mapZoom, setMapZoom] = useState(3);

  const searchSubmit = () => {
    if (searchLoc.length > 3) {
      drawCountyBoundary(searchLoc);
    }
  };

  const [data, setData] = useState([]);
  function drawCountyBoundary(loc, city, country0, state, country) {
    let url = `https://nominatim.openstreetmap.org/search.php?q=${loc}&polygon_geojson=1&format=jsonv2`;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setMarkerOrSearch(true);

        if (json.length > 0) {
          console.log(json[0].geojson.coordinates[0]);
          setPosition([parseFloat(json[0].lat), parseFloat(json[0].lon)]);
          setMapCenter([parseFloat(json[0].lat), parseFloat(json[0].lon)]);
          setMapZoom(9);
          setData(
            json[0].geojson.coordinates[0] ? json[0].geojson.coordinates[0] : []
          );
        }
      });
  }

  return (
    <>
      {positionCome ? (
        <div className="map-div-modal">
          <MDBBtn className="map-save-btn" onClick={submitHandler}>
            Save
          </MDBBtn>
          {/* //   <input
       //     dir="ltr"
       //     type="text"
       //     name="email"
       //     value={searchLoc}
       //     onChange={(e) => {
       //       setSearchLoc(e.target.value);
       //     }}
       //     id="outlined-basic"
       //     label="ایمیل"
       //     variant="outlined"
       //     className="map-search-input"
       //   />
       //   <button onClick={searchSubmit}>done</button> */}
          <MapContainer
            ref={mapRef}
            center={position}
            zoom={mapZoom}
            scrollWheelZoom={true}
            zoomControl={false}
            className={
              props.loc == "step4" ? "map-leaflet-step4" : "map-leaflet-dash"
            }
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <div className="map-marker-centered"></div>

            {/* <ChangeView center={mapCenter} zoom={mapZoom} /> */}

            {/* Animated panning */}
            <SetViewOnClick animateRef={animateRef} />

            {/* Zome Control */}
            <ZoomControl position="bottomright" />

            {/* Polygon */}
            {reverseFunction()}
            <Polygon
              pathOptions={purpleOptions}
              positions={ttt[0] ? ttt[0] : []}
            />
            {locationData &&
            locationData.Lat &&
            locationData.Lng &&
            locationData.Lat !== "" &&
            locationData.Lng !== "" &&
            firstLoadLoc ? (
              <Marker
                position={[locationData.Lat, locationData.Lng]}
                icon={GetIcon(37)}
              ></Marker>
            ) : (
              ""
            )}
          </MapContainer>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DraggableMap;
