import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import { useLocation } from "react-router-dom";
// components
import SwitchFilter from "../components/switch-filter/SwitchFilter";
import ReqCard from "../components/rec-card/RecCard";
import Header2 from "../components/header/Header2";
import Headermobile from "../components/header-mobile/Headermobile";
import Footer from "../components/footer/FooterSearch";
import RTLAccordian from "../components/rtl-accordian/RTLAccordian";
import Pagination from "../components/Pagination/Pagination";
import ResultModal from "../components/result-modal/ResultModal";
import ResultModal2 from "../components/result-modal2/ResultModal2";
import ResultCard from "../components/result-card/ResultCard";
import MetaTagsSearchPage from "../components/meta-tags-seo/MetaTagsSearchPage";
import SearchMap from "../components/search-map/SearchMap";
import DropdownFilter from "../components/dropdown-filter/DropdownFilter";
import ResultFilterModal from "../components/result-filter-modal/ResultFilterModal";
import ResultBottomDrawer from "../components/result-bottom-drawer/ResultBottomDrawer";
import LoadingModal from "../components/loading-modal/LoadingModal";
// imgs
import presentation from "../images/home-body/presentation.svg";
import search from "../images/result/search.png";
//
import { DomainContext, LangIdContext } from "../App";
// config
import configData from "../config/config.json";

const Result = (props) => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const find_desc = queryParams.get("find_desc").split("-").join(" ");
  const find_loc = queryParams.get("find_loc");
  const cat_id = queryParams.get("cat_id");
  const find_cat = queryParams.get("find_cat");

  // .split(" ").join("+")

  // this 3 satae forpagination
  const [pageNumber, setPageNumber] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [pageChanged, setPageChanged] = useState(false);

  const [searchTitle, setSearchTitle] = useState("");

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  const [mapIsShowing, setMapIsShowing] = useState(true);

  const [bizList, setBizList] = useState([
    {
      bId: 1,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 2,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 3,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 4,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 5,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 6,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 7,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 8,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 9,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
    {
      bId: 10,
      title: "",
      catTitle: "",
      rate: "",
      photoPath: "",
      description: "",
      url: "",
      location: "",
      lat: 0,
      lng: 0,
    },
  ]);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
    category: false,
    option: false,
    peyment: false,
  });

  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });
  const [category, setCategory] = useState([]);
  const [option, setOption] = useState([]);
  const [peyment, setpeyment] = useState([]);

  const [paymentsFilter, setPaymentsFilter] = useState([]);
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const [biz7varFilter, setBiz7varFilter] = useState(false);
  const [biz24varFilter, setBiz24varFilter] = useState(false);
  const [bizOnlinevarFilter, setBizOnlinevarFilter] = useState(false);

  const [modalLoader, setModalLoader] = useState(false);
  // to prevent calling biz-search api for 3 times in useEffect
  const [isFirstRender, setIsFirstRender] = useState(true);

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    cat_id: cat_id,
    CatIds: cat_id !== "" ? cat_id : "0",
  };

  useEffect(() => {
    setPageNumber(1);
    setPageChanged(true);
    setMTagsCome(false);
    window.scrollTo(0, 0);

    setModalLoader(true);

    if (langIdState !== "") {
      setApiDataCome({
        headerbiz: false,
        footer: false,
        category: false,
        option: false,
        peyment: false,
      });

      var dataPost = {
        // find_desc: find_desc,
        // find_loc: find_loc,
        // find_cat: find_cat,
        // cat_id: cat_id,
        // page_no: 1,
        // biz7var: false,
        // biz24var: false,
        // bizOnlinevar: false,
        // bizPaymentsvar: "",
        // bizAmenitiesvar: "",
        // level: 0,

        langId: 2,
        text: find_desc,
        city:
          find_loc == ""
            ? ""
            : JSON.parse(localStorage.getItem("SearchLocation")) &&
              JSON.parse(localStorage.getItem("SearchLocation")).city,
        province:
          find_loc == ""
            ? ""
            : JSON.parse(localStorage.getItem("SearchLocation")) &&
              JSON.parse(localStorage.getItem("SearchLocation")).province,
        country:
          find_loc == ""
            ? ""
            : JSON.parse(localStorage.getItem("SearchLocation")) &&
              JSON.parse(localStorage.getItem("SearchLocation")).country,
        biz7: biz7varFilter ? biz7varFilter : null,
        biz24: biz24varFilter ? biz24varFilter : null,
        bizOnline: bizOnlinevarFilter ? bizOnlinevarFilter : null,
        bizPayments: "",
        bizAmenities: "",
        categoryId: cat_id !== "" ? cat_id : 0,
        page: pageNumber,
        level: 0,
      };

      axios
        .post(configData.API_DOMAIN + "/api/v1/Search", dataPost, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setModalLoader(false);
            setBizList(response.data.data.businesses);
            setTotalNumber(response.data.data.total);
            setMTagsCome(true);
            setMetaData(response.data.data.metaTag);
            // setSearchTitle(response.data.SearchTitle);
          } else {
            setBizList([]);
            setMTagsCome(false);
            setModalLoader(false);
          }
          setPageChanged(false);
        })
        .catch((error) => {
          // alert(error);
        });

      // 2 header-search
      var catId = cat_id !== "" ? cat_id : "0";
      axios
        //   .get(domain + "/api/header-search", { headers: header })
        .get(configData.API_DOMAIN + "/api/v1/Headers/search?catId=" + catId, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setheaderData(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              headerbiz: true,
            }));
          }
        })
        .catch((error) => {
          // alert(error);
        });

      // 3 footer
      axios
        .get(configData.API_DOMAIN + "/api/v1/Footers", {
          headers: header,
        })
        .then((response) => {
          setFooterData(response.data.data);
          setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
        })
        .catch((error) => {
          // alert(error);
        });

      // 4 category option
      axios
        .get(configData.API_DOMAIN + `/api/v1/Categories/category-options`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setOption(response.data.data);
            setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, option: true }));
          }
        })
        .catch((error) => {});

      {
        /* روش پرداخت فعلا حذف شود */
      }
      // 5 payments-type
      // axios
      //   .get(configData.API_DOMAIN + `/api/v1/PaymentTypes`, {
      //     headers: header,
      //   })
      //   .then((response) => {
      //     if (response.data.isSuccess) {
      //       setpeyment(response.data.data);
      //       setApiDataCome((ApiDataCome) => ({
      //         ...ApiDataCome,
      //         peyment: true,
      //       }));
      //     }
      //   })
      //   .catch((error) => {});

      // 6 category parent
      axios
        .get(configData.API_DOMAIN + `/api/v1/Categories/parent`, {
          headers: header,
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setCategory(response.data.data);
            setApiDataCome((ApiDataCome) => ({
              ...ApiDataCome,
              category: true,
            }));
          }
        })
        .catch((error) => {});
    }
  }, [find_desc, find_loc, cat_id, find_cat, langIdState]);
  // when page changed

  useEffect(() => {
    if (isFirstRender) {
      // Skip the first render
      setIsFirstRender(false);
    } else {
      setPageChanged(true);
      setMTagsCome(false);
      window.scrollTo(0, 0);

      if (langIdState !== "") {
        setApiDataCome({
          headerbiz: false,
          footer: false,
          category: false,
          option: false,
          peyment: false,
        });

        var bizPaymentsvar = "";
        for (let index = 0; index < paymentsFilter.length; index++) {
          const element = paymentsFilter[index];
          if (element) {
            if (index == 0) {
              bizPaymentsvar = element + "";
            } else {
              bizPaymentsvar = bizPaymentsvar + "," + element;
            }
          }
        }
        var bizAmenitiesvar = "";
        for (let index = 0; index < amenitiesFilter.length; index++) {
          const element = amenitiesFilter[index];
          if (element) {
            if (index == 0) {
              bizAmenitiesvar = element + "";
            } else {
              bizAmenitiesvar = bizAmenitiesvar + "," + element;
            }
          }
        }
        var dataPost = {
          // find_desc: find_desc,
          // find_loc: find_loc,
          // find_cat: find_cat,
          // cat_id: cat_id,
          // page_no: pageNumber,
          // biz7var: biz7varFilter,
          // biz24var: biz24varFilter,
          // bizOnlinevar: bizOnlinevarFilter,
          // bizPaymentsvar: bizPaymentsvar,
          // bizAmenitiesvar: bizAmenitiesvar,
          // level: 0,
          langId: 2,
          text: find_desc,
          city:
            find_loc == ""
              ? ""
              : JSON.parse(localStorage.getItem("SearchLocation")) &&
                JSON.parse(localStorage.getItem("SearchLocation")).city,
          province:
            find_loc == ""
              ? ""
              : JSON.parse(localStorage.getItem("SearchLocation")) &&
                JSON.parse(localStorage.getItem("SearchLocation")).province,
          country:
            find_loc == ""
              ? ""
              : JSON.parse(localStorage.getItem("SearchLocation")) &&
                JSON.parse(localStorage.getItem("SearchLocation")).country,
          biz7: biz7varFilter ? biz7varFilter : null,
          biz24: biz24varFilter ? biz24varFilter : null,
          bizOnline: bizOnlinevarFilter ? bizOnlinevarFilter : null,
          bizPayments: bizPaymentsvar,
          bizAmenities: bizAmenitiesvar,
          categoryId: cat_id !== "" ? cat_id : 0,
          page: pageNumber,
          level: 0,
        };

        axios
          .post(configData.API_DOMAIN + "/api/v1/Search", dataPost, {
            headers: header,
          })
          .then((response) => {
            // console.log("here 5555");

            if ((response.data.msg = "done")) {
              setModalLoader(false);
              setBizList(response.data.data.businesses);
              setTotalNumber(response.data.data.total);
              setMTagsCome(true);
              setMetaData(response.data.data.metaTag);
              // setSearchTitle(response.data.SearchTitle);
            } else {
              setBizList([]);
              setMTagsCome(false);
              setModalLoader(false);
            }
            setPageChanged(false);
          })
          .catch((error) => {
            // alert(error);
          });
      }
    }
  }, [pageNumber, langIdState]);

  useEffect(() => {
    if (isFirstRender) {
      // Skip the first render
      setIsFirstRender(false);
    } else {
      if (langIdState !== "") {
        setModalLoader(true);

        setApiDataCome({
          headerbiz: false,
          footer: false,
          category: false,
          option: false,
          peyment: false,
        });

        setPageChanged(true);
        setMTagsCome(false);
        window.scrollTo(0, 0);

        var bizPaymentsvar = "";
        for (let index = 0; index < paymentsFilter.length; index++) {
          const element = paymentsFilter[index];
          if (element) {
            if (index == 0) {
              bizPaymentsvar = element + "";
            } else {
              bizPaymentsvar = bizPaymentsvar + "," + element;
            }
          }
        }
        var bizAmenitiesvar = "";
        for (let index = 0; index < amenitiesFilter.length; index++) {
          const element = amenitiesFilter[index];
          if (element) {
            if (index == 0) {
              bizAmenitiesvar = element + "";
            } else {
              bizAmenitiesvar = bizAmenitiesvar + "," + element;
            }
          }
        }

        var dataPost = {
          // find_desc: find_desc,
          // find_loc: find_loc,
          // find_cat: find_cat,
          // cat_id: cat_id,
          // page_no: pageNumber,
          // biz7var: biz7varFilter,
          // biz24var: biz24varFilter,
          // bizOnlinevar: bizOnlinevarFilter,
          // bizPaymentsvar: bizPaymentsvar,
          // bizAmenitiesvar: bizAmenitiesvar,
          // level: 0,
          langId: 2,
          text: find_desc,
          city:
            find_loc == ""
              ? ""
              : JSON.parse(localStorage.getItem("SearchLocation")) &&
                JSON.parse(localStorage.getItem("SearchLocation")).city,
          province:
            find_loc == ""
              ? ""
              : JSON.parse(localStorage.getItem("SearchLocation")) &&
                JSON.parse(localStorage.getItem("SearchLocation")).province,
          country:
            find_loc == ""
              ? ""
              : JSON.parse(localStorage.getItem("SearchLocation")) &&
                JSON.parse(localStorage.getItem("SearchLocation")).country,
          biz7: biz7varFilter ? biz7varFilter : null,
          biz24: biz24varFilter ? biz24varFilter : null,
          bizOnline: bizOnlinevarFilter ? bizOnlinevarFilter : null,
          bizPayments: bizPaymentsvar,
          bizAmenities: bizAmenitiesvar,
          categoryId: cat_id !== "" ? cat_id : 0,
          page: pageNumber,
          level: 0,
        };

        axios
          .post(configData.API_DOMAIN + "/api/v1/Search", dataPost, {
            headers: header,
          })
          .then((response) => {
            if ((response.data.msg = "done")) {
              setModalLoader(false);
              setBizList(response.data.data.businesses);
              setTotalNumber(response.data.data.total);
              setMTagsCome(true);
              setMetaData(response.data.data.metaTag);
              // setSearchTitle(response.data.SearchTitle);
            } else {
              setBizList([]);
              setMTagsCome(false);
              setModalLoader(false);
            }
            setPageChanged(false);
          })
          .catch((error) => {
            // alert(error);
          });

        // 3 footer
        axios
          .get(configData.API_DOMAIN + "/api/v1/Footers", {
            headers: header,
          })
          .then((response) => {
            setFooterData(response.data.data);
            setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
          })
          .catch((error) => {
            // alert(error);
          });
      }
    }
  }, [
    paymentsFilter,
    amenitiesFilter,
    biz24varFilter,
    biz7varFilter,
    bizOnlinevarFilter,
    langIdState,
  ]);

  let windowWidth = "00";
  let windowHeigtht = "00";

  if (typeof window !== "undefined") {
    // Code that relies on the window object
    windowWidth = window.innerWidth;
    windowHeigtht = window.innerHeight;
    // console.log(windowWidth);
    // console.log(windowHeigtht);
  } else {
    windowWidth = "00";

    // console.log("Window object is not available.");
  }

  return (
    <>
      {MTagsCome ? <MetaTagsSearchPage data={MetaData} /> : ""}
      {modalLoader ? <LoadingModal search={true} /> : ""}

      <div className="d-none d-md-block">
        <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
      </div>
      <div className="d-block d-md-none">
        <div className="fixed-header">
          <Headermobile data={headerData} />
        </div>
        <div className="box-under-header-res"></div>
      </div>

      {/*top choose city  mobile*/}
      <div className="d-md-none result-city z1300">
        <ResultModal2 />
      </div>

      {/*top filters */}
      <div className="result-filter2">
        <div className="d-none d-md-block">
          <DropdownFilter
            category={category}
            dataComeCategory={ApiDataCome.category}
            option={option}
            dataComeOption={ApiDataCome.option}
            peyment={peyment}
            dataComePeyment={ApiDataCome.peyment}
            paymentsFilter={paymentsFilter}
            setPaymentsFilter={setPaymentsFilter}
            amenitiesFilter={amenitiesFilter}
            setAmenitiesFilter={setAmenitiesFilter}
          />
        </div>
        {/* mobile */}
        <div className="d-md-none">
          <ResultFilterModal
            category={category}
            dataComeCategory={ApiDataCome.category}
            option={option}
            dataComeOption={ApiDataCome.option}
            peyment={peyment}
            dataComePeyment={ApiDataCome.peyment}
            paymentsFilter={paymentsFilter}
            setPaymentsFilter={setPaymentsFilter}
            amenitiesFilter={amenitiesFilter}
            setAmenitiesFilter={setAmenitiesFilter}
          />
        </div>

        <button
          className={
            biz24varFilter ? "filter-dropdown-on" : "filter-dropdown-off"
          }
          onClick={() => {
            setBiz24varFilter((prevState) => !prevState);
          }}
        >
          24/7
        </button>
        <button
          className={
            biz7varFilter ? "filter-dropdown-on" : "filter-dropdown-off"
          }
          onClick={() => {
            setBiz7varFilter((prevState) => !prevState);
          }}
        >
          Open Holidays
        </button>
        <button
          className={
            bizOnlinevarFilter ? "filter-dropdown-on" : "filter-dropdown-off"
          }
          onClick={() => {
            setBizOnlinevarFilter((prevState) => !prevState);
          }}
        >
          Online
        </button>
        {/* <div className="result-filter-div2">
          کسب‌وکار آنلاین
          <SwitchFilter setVal={setBizOnlinevarFilter} />
        </div> */}
        {/* <div className="result-filter-div2">
          <span>شبانه‌روزی</span>
          <SwitchFilter setVal={setBiz24varFilter} />
        </div>
        <div className="result-filter-div2">
          <span>روزهای تعطیل هم باز است</span>
          <SwitchFilter setVal={setBiz7varFilter} />
        </div> */}
        {/* laptop */}
      </div>
      {bizList && bizList.length > 0 ? (
        <MDBContainer>
          <MDBRow className="result2-row">
            <MDBCol
              size="12"
              md="6"
              className={
                mapIsShowing
                  ? "centerr d-none d-md-block  col12-search-header"
                  : "centerr d-md-block mt-4 mt-md-0"
              }
            >
              <div dir="ltr">
                {/* <div className="result-title d-none d-md-block mt-3">
                  نتیجه {searchTitle}
                </div> */}
                <MDBRow className="pt-3">
                  <MDBCol size="12" md="12">
                    {bizList.map((item) => (
                      <div key={item.bId} className="mb-3 mx-4 mx-md-0">
                        <ResultCard
                          pageChanged={pageChanged}
                          id={item.bId}
                          title={item.title}
                          desc1={item.catTitle}
                          city={item.location}
                          // country={item.desc3}
                          rate={item.rate}
                          // cat={item.cat}
                          tozih={item.description}
                          img={item.photoPath}
                          url={item.url}
                          icon={item.flagPath}
                          // Tags={item.Tags}
                        />
                      </div>
                    ))}
                    {/* معرفی به گیشنیز سایز موبایل*/}
                    <div className="text-align-centerr d-block d-md-none moarefi-result-resp">
                      <div className="moarefi d-flex justify-content-between  align-items-center  ">
                        <div className=" align-self-center text-start text-white alert-m-text">
                          Know any Farsi-speaking businesses?
                          <br />
                          <span>Introduce them to Gishniz!</span>
                        </div>

                        <Link
                          className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                          to="/introduce-business"
                        >
                          Click here
                        </Link>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>

                {/* pagination */}
                <Pagination
                  pageChanged={pageChanged}
                  totalNumber={totalNumber}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  responsive={false}
                />
                {/* pagination */}

                <Footer
                  data={footerData}
                  dataCome={ApiDataCome.footer}
                  noResult={bizList && bizList.length > 0}
                />
              </div>
            </MDBCol>
            <MDBCol
              size="0"
              md="6"
              className={
                mapIsShowing
                  ? "pe-md-3 ps-0 pe-0 d-md-block"
                  : "pe-md-3 ps-0 pe-0 d-none d-md-block"
              }
            >
              {bizList && bizList.length > 0 ? (
                <SearchMap
                  bizList={bizList}
                  pageChanged={pageChanged}
                  windowWidth={windowWidth}
                />
              ) : (
                ""
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      ) : (
        <>
          <MDBContainer className="centerr">
            <>
              {/* .result-solution */}
              <div className="result-notfound">
                <img src={search} alt="..." className="result-img" />
                <p>Not found result!</p>
              </div>
              <div className="result-solution">
                <ul>
                  <li>Try another location</li>
                  <li>Check your spelling, or search for another keyword</li>
                  <li>
                    Try a more general search. For example, "pizza" instead of
                    "pepperoni."
                  </li>
                </ul>
              </div>

              {/*معرفی به گیشنیز*/}
              <div className="text-align-centerr d-none d-md-block">
                <div className="moarefi d-flex justify-content-around  align-items-center ">
                  <div className=" fs-17 fw-bold align-self-center ">
                    Know any Farsi-speaking businesses?
                    <br />
                    <span>Introduce them to Gishniz!</span>
                  </div>
                  <img
                    className="moarefi-icon"
                    src={presentation}
                    alt="moarefi icon"
                  />

                  <Link
                    className="moarefi-btn align-self-center shadow-off fs-18 color-242424"
                    to="/introduce-business"
                  >
                    Click here
                  </Link>
                </div>
              </div>
              {/* معرفی به گیشنیز سایز موبایل*/}
              <div className="text-align-centerr d-block d-md-none moarefi-result-resp">
                <div className="moarefi d-flex justify-content-between  align-items-center  ">
                  <div className=" align-self-center text-start text-white alert-m-text">
                    Know any Farsi-speaking businesses?
                    <br />
                    <span>Introduce them to Gishniz!</span>
                  </div>

                  <Link
                    className="align-self-center color-242424 alert-m-btn hov-shadow-btn shadow-off"
                    to="/introduce-business"
                  >
                    Click here
                  </Link>
                </div>
              </div>
            </>
          </MDBContainer>
          <Footer
            data={footerData}
            dataCome={ApiDataCome.footer}
            noResult={bizList && bizList.length > 0}
          />
        </>
      )}

      {bizList && bizList.length > 0 ? (
        <div className="d-md-none">
          {setMTagsCome ? (
            <ResultBottomDrawer
              pageChanged={pageChanged}
              bizList={bizList}
              mapIsShowing={mapIsShowing}
              setMapIsShowing={setMapIsShowing}
              totalNumber={totalNumber}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              windowWidth={windowWidth}
              windowHeigtht={windowHeigtht}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Result;
